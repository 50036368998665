import { isAfterTargetValidator } from 'utils';
import { ObjectGeneric } from 'types';

const incorrectDatesErrMsg = 'validation_error_incorrect_report_startdate_and_enddate';

// custom fields validations
export const validators = (values, props) => {
  const {
    insuranceDateFrom,
    insuranceDateTo,
    paymentDateFrom,
    paymentDateTo,
    validityDateFrom,
    validityDateTo,
  } = values;

  const errors = {
    _error: {} as ObjectGeneric, // custom validation errors object
  };

  // insuranceDate: startdate > endate
  if (insuranceDateFrom && insuranceDateTo) {
    const dateFromIsAfterDateTo = isAfterTargetValidator(
      insuranceDateFrom,
      insuranceDateTo,
      'insuranceDateTo',
      'day'
    );
    if (dateFromIsAfterDateTo) {
      errors._error.incorrectInsuranceDatesError = incorrectDatesErrMsg;
    }
  }

  // paymentDate: startdate > endate
  if (paymentDateFrom && paymentDateTo) {
    const dateFromIsAfterDateTo = isAfterTargetValidator(
      paymentDateFrom,
      paymentDateTo,
      'paymentDateFrom',
      'day'
    );
    if (dateFromIsAfterDateTo) {
      errors._error.incorrectPaymentDatesError = incorrectDatesErrMsg;
    }
  }

  // insuranceDate: startdate > endate
  if (validityDateFrom && validityDateTo) {
    const dateFromIsAfterDateTo = isAfterTargetValidator(
      validityDateFrom,
      validityDateTo,
      'validityDateFrom',
      'day'
    );
    if (dateFromIsAfterDateTo) {
      errors._error.incorrectValidityDatesError = incorrectDatesErrMsg;
    }
  }

  if (Object.keys(errors._error).length === 0) {
    // @ts-ignore
    delete errors._error;
  }

  return errors;
};
