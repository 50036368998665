export const isStorageAvailable = (): boolean => {
  try {
    const key = '__random_localStorage_availability_test_key__';
    window.localStorage.setItem(key, key);
    window.localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

export const saveToStorage = (key: string, value: any) =>
  isStorageAvailable() && window.localStorage.setItem(key, value);

export const removeFromStorage = (key: string) =>
  isStorageAvailable() && window.localStorage.removeItem(key);

export const getFromStorage = (key: string) =>
  isStorageAvailable() && window.localStorage.getItem(key);
