import {
  defaultGetSettings,
  defaultSetBodyClass,
  kerning,
  fontSize,
  images,
  color,
} from '@n3/react-vision-panel';
import translate from 'utils/translator';

export const accessibilityGroups = [
  {
    ...fontSize,
    label: translate('font'),
  },
  {
    ...kerning,
    label: translate('kerning_letter_space'),
  },
  color,
  {
    ...images,
    options: [
      {
        label: translate('show_capitalize'),
        value: 'on',
      },
      {
        label: translate('hide'),
        value: 'off',
      },
    ],
  },
];

export const initializeBodyClasses = () => {
  const settings = defaultGetSettings(); // settings are stored in cookies so they can be fetched
  Object.keys(settings).forEach(property => defaultSetBodyClass(property, settings[property]));
};

export const toggleOverlayOnBgImages = configValue => {
  const elsWithBgImgs: Element[] = getElementsWithBgImg();
  elsWithBgImgs.map(el =>
    configValue === 'off' ? el.classList.add('hide-bg-img') : el.classList.remove('hide-bg-img')
  );
};

// elements that have bg image and should be ignored when
// images should be hidden due to image's accessibility config turned on
// example: checkbox icon
const whiteListedElClasses = [
  'checkmark',
  'calendar',
  'arrow',
  'info',
  'success',
  'error',
  'date-picker',
];

const getElementsWithBgImg = () => {
  const allElements = document.querySelectorAll('*');
  const elsWithBgImgs: Element[] = [];
  for (let i = 0, len = allElements.length; i < len; i++) {
    const el = allElements[i];
    const bgImgProp = window.getComputedStyle(el, null).getPropertyValue('background-image');
    const isWhiteListedElementClass = whiteListedElClasses.some(
      item => el && el.classList && el.classList.contains(item)
    );
    if (bgImgProp && bgImgProp.indexOf('url') > -1 && !isWhiteListedElementClass) {
      elsWithBgImgs.push(el);
    }
  }
  return elsWithBgImgs;
};
