import { fontSizes, lineHeights } from 'styles/typography.styles';
import { colors } from 'styles/colors.styles';
import { buttonStyles } from 'styles/button.styles';
import { media, breakpoints } from 'styles/responsive.styles';
import { radii } from 'styles/radii.styles';
import { zIndices } from 'styles/zIndices.styles';
import { FONTS_FONT_FAMILY } from 'styles/fonts.styles';

const transitions = {
  default: '0.4s ease-in-out',
  easing: 'ease-in-out',
  duration: '0.4s',
};

const margin = {
  default: '10px',
  form: {
    inputTop: '10px',
    labelBottom: '5px',
    rowBottom: '15px',
  },
};

// rebass grid space settings
const space = [0, 6, 10, 12, 18, 20, 30, 50]; // p={7} => padding='50px', m={2} => margin='10px'

// https://github.com/system-ui/theme-specification#key-reference
export const theme = {
  breakpoints,
  buttons: buttonStyles,
  colors,
  fontSizes,
  lineHeights,
  margin,
  media,
  radii,
  space,
  transitions,
  zIndices,
  FONTS_FONT_FAMILY,
};
