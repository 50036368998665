import { IProduct } from 'types';

export interface ISalesHistoryListItem {
  cardOrderid: string;
  contractNumber: string;
  endDate: string;
  hcfbAgentId: string;
  id: number;
  insurancePeriod: string;
  orderStatus: string;
  paymentDate: string;
  personName: string;
  personPhone: string;
  premium: string;
  productName: string;
  row: number;
  rowDate: string;
  salesChannel: string;
  startDate: string;
}

export interface ISalesHistory {
  authType: number;
  list: ISalesHistoryListItem[];
  page: number;
  pageSize: number;
  sortColumn: string;
  sortOrder: string;
  totalPages: number;
  totalRecords: number;
}

export interface IStatus {
  value: number;
  label: string;
}

export interface IInformactionDoc {
  ui_name: string;
  rpn_name: string;
}

export type InformactionDocs = IInformactionDoc[];

export enum EAgentFormBlock {
  HIDDEN_OPTIONAL = 0,
  VISIBLE_OPTIONAL = 1,
  VISIBLE_REQUIRED = 2,
  CLIENT_INSURED_HIDDEN = 3,
}

export interface IFormConfAgent {
  block2: EAgentFormBlock;
  block3: EAgentFormBlock;
  block4: EAgentFormBlock;
  block5: EAgentFormBlock;
  block6: EAgentFormBlock;
  block7: EAgentFormBlock;
  block8: EAgentFormBlock;
  block9: EAgentFormBlock;
  block10: EAgentFormBlock;
  button3: EAgentFormBlock;
}

export interface IAgentProduct extends IProduct {
  formConfAgent?: IFormConfAgent;
  name_UI: string;
}

export interface IAgentPremiumCalculation {
  maxSumInsured: number;
  premium: number;
  tfrId: number;
}
