import { css } from 'styled-components';

import { FONTS_FONT_FAMILY } from 'styles/fonts.styles';

export const commonStyles = css`
  /* ----------> base CSS */
  body {
    color: #151d2b;
    font: 80%/1.7 ${FONTS_FONT_FAMILY};
    font-weight: 400;
    background: #ffffff;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: #151d2b;
    font-weight: 300;
    line-height: normal;
    letter-spacing: normal;
  }

  h1 {
    font-size: 250%;
    margin: 0;
    line-height: 1.44;
  }

  h2 {
    font-size: 250%;
    margin: 0 0 1em;
  }

  h3 {
    font-size: 220%;
    margin: 0 0 1em;
  }

  h4 {
    font-size: 150%;
    font-weight: 400;
    margin: 2em 0;
  }

  h5 {
    font-size: 130%;
    font-weight: 600;
    margin: 0 0 1em;
  }

  input,
  select,
  textarea {
    font: 100% 'Open Sans', sans-serif;
  }

  select {
    cursor: pointer;
  }

  /* Hide input number arrows */
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield !important;
  }

  a {
    color: #e7372c;
    outline: none;
    transition: 0.4s;
    cursor: pointer;
  }

  a:link {
    color: #e7372c;
  }

  a:hover {
    color: #151d2b;
    text-decoration: none;
  }

  p {
    margin: 1.5em 0;
    line-height: 1.7;
  }

  ul,
  ol {
    margin: 1.5em auto 1.5em;
    line-height: 1.7;
  }

  ul li,
  ol li {
    padding: 0.4em 0;
  }

  small {
    font-size: 90%;
  }

  em,
  i {
    font-style: italic;
  }

  strong,
  b {
    font-weight: 600;
  }

  sup {
    vertical-align: super;
  }

  sub {
    vertical-align: sub;
  }

  fieldset {
    min-width: inherit;
  }

  /* ----------> default CLASS */

  .tal {
    text-align: left !important;
  }

  .tac {
    text-align: center !important;
  }

  .tar {
    text-align: right !important;
  }

  .vat {
    vertical-align: top;
  }

  .vam {
    vertical-align: middle;
  }

  .fl {
    float: left;
  }

  .fr {
    float: right;
  }

  .hidden {
    display: none;
  }

  .small {
    font-size: 90%;
  }

  .big {
    font-size: 200%;
  }

  .cover {
    background-size: cover !important;
  }

  .td-none {
    text-decoration: none;
  }

  .no-padding {
    padding: 0 !important;
  }

  .text-through {
    text-decoration: line-through;
    opacity: 0.5;
  }

  /* ----------> project LAYOUT */

  .layout-wrapper {
    margin: 0 auto;
    max-width: 1280px;
    box-sizing: border-box;
    position: relative;

    ${({ theme }) => theme.media.minTablet`   
      padding: 0 40px;
    `}
  }

  .col {
    box-sizing: border-box;
  }

  .col-5 {
    width: calc(100% / 12 * 5);
  }

  .col-6 {
    width: calc(100% / 12 * 6);
  }

  .disabled {
    opacity: 0.7;
    cursor: default;
  }

  .disabled:hover {
    background: #e7372c;
  }

  .red {
    color: #e7372c;
  }

  span[title] {
    border-bottom: 1px dotted #151d2b;
  }

  .link {
    cursor: pointer;
  }
`;
