import moment from 'moment';
import { endsWith } from 'lodash-es';

import { isEmpty, reverseString, firstUpper } from './string.utils';
import translate from 'utils/translator';
import { dateFormat } from './date.utils';

export const formatContract = (str: string) =>
  str
    ? `${str.substr(0, 1)}-${str.substr(1, 3)}-${str.substr(4, 3)}-${str.substr(7, 2)}-${str.substr(
        9,
        2
      )}`
    : '';

export const formatCurrency = (num: string | number | undefined, space: string = ' ') => {
  if (num === null || num === undefined) return '';
  const work = '' + num;
  const match = reverseString(work).match(/\d{1,3}/g);
  return match ? reverseString(match.join(reverseString(space))) : '';
};

export const formatPassport = (passportnumber: string) =>
  !isEmpty(passportnumber) ? `${passportnumber.substr(0, 4)} ${passportnumber.substr(4, 10)}` : '';

export const formatDate = (
  datetime?: string | object,
  inputFormat = '',
  outputFormat = dateFormat
) => {
  if (datetime) {
    if (moment.isMoment(datetime)) return datetime.format(outputFormat);
    return moment(datetime, inputFormat).format(outputFormat);
  }
  return '';
};

export const formatSex = (sex?: string): string => {
  switch (sex) {
    case '1':
      return translate('male');
    case '2':
      return translate('female');
    default:
      return '';
  }
};

export const formatPhoneNumber = (phone: string | null) =>
  phone && !isEmpty(phone)
    ? `+7 (${phone.substr(0, 3)}) ${phone.substr(3, 3)} ${phone.substr(6, 2)} ${phone.substr(
        8,
        2
      )}`.trim()
    : '';

export const afterSetFirstUpper = (
  state: any,
  newValue: any,
  propsForm: object,
  validateField: Function,
  selfConfiguration: any
) => {
  const values = state.values;
  values[selfConfiguration.name] = firstUpper(newValue);
  return { ...state, values };
};

/**
 * Normalize email field
 * - gmail.ru suffix prohibited -> needs to be changed to "gmail.com"
 */
export const normalizeEmail = (value: string) => {
  if (endsWith(value, '@gmail.ru')) return value.replace('@gmail.ru', '@gmail.com');
  return value;
};
