import * as CONSTANTS from './accessibility-settings.constants';

export const toggleSettings = () => ({
  type: CONSTANTS.TOGGLE_OPEN,
});

export const setConfig = (config: any) => ({
  type: CONSTANTS.SET_CONFIG,
  data: { config },
});
