import { buttonStyles } from './forward.button.styles';
import { forwardColors } from './forward.colors.styles';
import { forwardRadiiStyles } from './forward.radii.styles';
import { FORWARD_FONTS_FONT_FAMILY } from './forward.styles';
import { fontSizes, lineHeights } from './forward.typography.styles';

// forward theme
// this will be merged
export const themeForward = {
  colors: forwardColors,
  buttons: buttonStyles,
  radii: forwardRadiiStyles,
  fontSizes,
  lineHeights,
  FONTS_FONT_FAMILY: FORWARD_FONTS_FONT_FAMILY,
};
