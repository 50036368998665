import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Flex, FlexProps } from '@rebass/grid';

import { Text, TTextProps } from 'components/atoms/Text';
import { Image } from 'components/atoms/Image';

import { FnClick } from 'types';

interface IFakeLinkProps extends TTextProps {
  icon?: string;
  iconWidth?: string;
  fontSize?: string | number;
  children?: React.ReactNode;
  onClick?: FnClick;
  isActive?: boolean;
  withHover?: boolean;
  [x: string]: string | number | undefined | React.ReactNode;
}

export const FakeLink: React.SFC<FlexProps & IFakeLinkProps> = ({
  icon,
  iconWidth,
  onClick,
  children,
  fontSize,
  isActive,
  ...rest
}) => (
  <TextLink onClick={onClick} size={fontSize} isActive={isActive} {...rest}>
    <Flex alignItems='center'>
      {icon && <Icon src={icon} width={iconWidth} />}
      {children}
    </Flex>
  </TextLink>
);

const Icon = styled(Image)<{ width?: string }>`
  display: inline-block;
  margin: 0 10px 0 0;
  width: ${({ width }) => (width ? width : 'auto')};
`;

const TextLink = styled(Text)<Partial<IFakeLinkProps>>`
  font-weight: ${({ isActive }) => (isActive ? 700 : 400)};

  &:hover {
    cursor: pointer;
    color: ${({ theme, color }) => (color ? theme.colors[color] || color : theme.colors.textHover)};
  }

  ${({ isActive }) => isActive && 'font-weight: 700;'};

  ${({ withHover }) =>
    withHover &&
    css`
      &:hover {
        text-decoration: underline;
      }
    `};
`;
