import React, { Fragment } from 'react';
import { WrappedFieldInputProps } from 'redux-form';

import { ErrorMessage } from 'components/atoms/ErrorMessage';
import * as S from './InputCheckbox.styles';

interface IInputCheckboxProps {
  meta: {
    touched: boolean;
    error: string | object;
  };
  className?: string;
  disabled?: boolean;
  input: WrappedFieldInputProps;
}

export const InputCheckbox: React.SFC<IInputCheckboxProps> = props => {
  const {
    meta: { touched, error },
    disabled,
  } = props;
  return (
    <Fragment>
      <S.InputWrap
        className={`${props.className || ''} checkbox ${
          props.input.value ? 'checkbox-checked' : ''
        } ${props.disabled ? 'checkbox-disabled' : ''}`}
      >
        <S.Input type='checkbox' {...props.input} disabled={disabled} />
        {props.children}
        <S.InputCheckboxMark
          className={`checkmark ${
            !props.input.value ? 'checkmark-notchecked' : 'checkmark-checked'
          } ${props.disabled ? 'checkmark-disabled' : ''}`}
        />
      </S.InputWrap>
      {touched && error && <ErrorMessage>{error}</ErrorMessage>}
    </Fragment>
  );
};
