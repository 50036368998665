import React from 'react';

import { Column, IColumnProps } from 'components/atoms/Column';

interface IFieldBoxProps extends IColumnProps {
  children: React.ReactNode;
}

export const FieldBox: React.FC<IFieldBoxProps> = ({ children, ...props }) => (
  <Column px={[0, 0, '10px']} w={1} {...props}>
    {children}
  </Column>
);
