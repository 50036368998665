import React from 'react';
import styled from 'styled-components';
import { imgSpinnerSvg } from 'utils/images';

export const Spinner = props => {
  const style = {} as any;
  style.top = props.top ? +props.top : 0;
  const id = props.id ? props.id : 'spinner';
  return <Container id={id} style={style} />;
};

const Container = styled.div`
  &#spinner {
    background: url('${imgSpinnerSvg}') no-repeat;
    width: 85px;
    height: 85px;
    display: block;
    margin: 0 auto;
    position: relative;
  }

  &#spinner-small {
    background: url('${imgSpinnerSvg}') no-repeat;
    background-size: contain;
    width: 40px;
    height: 40px;
    display: block;
    margin: 0 auto;
    position: relative;
  }
`;
