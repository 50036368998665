import React from 'react';

import { Row, TRowProps } from 'components/atoms/Row';

interface IFormRowProps extends TRowProps {
  children: React.ReactNode;
}

export const FormRow: React.FC<IFormRowProps> = ({ children, ...props }) => (
  <Row mb='30px' position='relative' mx={[0, 0, '-10px']} flexWrap='wrap' width={1} {...props}>
    {children}
  </Row>
);
