// regexp patterns
export const REGEX_CYRILIC_PATTERN = '[\u0400-\u04FF ]';
export const REGEX_CYRILIC_PATTERN_AND_NUMBERS = '^[\u0400-\u04FF0-9 ]*$';
export const REGEX_PHONE_PATTERN = '^9[0-9]{9}$';
export const REGEX_DOC_PATTERN = '^[0-9]{10}$';
export const REGEX_TAX_NUM_PATTERN = '^[0-9]{11}$';
export const REGEX_TWO_AND_MORE_WORDS_PATTERN = '/[a-zA-Z]+s+[a-zA-Z]+/g';
export const REGEX_CARD_ORDER_ID_PATTERN = '/^[0-9]{11}$';
export const REGEX_CONTRACT_NUMBER_PATTERN = '^([a-zA-Z0-9]){10}$';
// forbidden chars: <>[]{}|^%&`/$:;=
// disable special chars in string (used for phname, phusername)
export const REGEX_FORBIDDEN_SPECIAL_CHARS_PATTERN = '^[^ <>[\\]{}|\\^%&`\\/$:;=]*$';
// disallow special chars (used for phmiddleman)
export const REGEX_FORBIDDEN_SPECIAL_CHARS_SPACE_ALLOWED_PATTERN = '^[^<>[\\]{}|\\^%&`\\/$:;=]*$';
export const REGEX_SERVER_DATE_FORMAT = '/^(d{4})-(d{2})-(d{2})$/g';
export const REGEX_DOCGIVECODE_PATTERN = '^[0-9]{3}-[0-9]{3}$';
export const REGEX_AGENT_PHDOCGIVE_PATTERN = '^[0-9а-яА-ЯЁё,№\\.\\- „“"«»“]{1,200}$';
