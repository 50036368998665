import styled from 'styled-components';

export const RedBlock = styled.div`
  align-items: center;
  background-color: ${props => props.theme.colors.primary};
  box-shadow: 0 1px 16px 0 rgba(146, 149, 183, 0.3);
  display: flex;
  flex-flow: row;
  height: 152px;
  justify-content: center;
  margin: -60px auto 0 auto;
  max-width: 93.6%;
  width: 1160px;
  z-index: 1;

  ${props => props.theme.media.phone`
    height: 99px;
  `}
`;
