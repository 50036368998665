import React from 'react';
import Dropzone, { DropzoneProps } from 'react-dropzone';
import { Flex } from '@rebass/grid';
import { WrappedFieldInputProps } from 'redux-form';

import { DropzoneInput } from './components/DropzoneInput';
import { UploadedFiles } from './components/UploadedFiles';
import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { uniqByKey } from 'utils/array.utils';

import { FnWithArgs } from 'types';

interface IFileInputProps extends DropzoneProps {
  disabled?: boolean;
  dropzoneText?: string;
  error?: string | object;
  input?: WrappedFieldInputProps;
  maxFiles?: number;
  name: string;
  touched?: boolean;
  value: string;
  onChange?: FnWithArgs;
  uploadedFilesTextColor?: string;
  uploadedFilesFontSize?: string;
  getComponentRef?: (FileInput) => void;
}

interface IState {
  customError: string;
  files: any[];
}

export class FileInput extends React.Component<IFileInputProps, IState> {
  static defaultProps = {
    dropzoneText: '',
    disabled: false,
  };

  state = {
    files: [],
    customError: '',
  };

  componentDidMount() {
    const { value } = this.props;
    if (value && value.length) {
      this.setFiles(value);
    }
    this.props.getComponentRef?.(this);
  }

  onChange = (acceptedFiles, rejectedFiles) => this.setFiles(acceptedFiles, rejectedFiles);

  onChangeHelper = files => {
    if (this.props.input) {
      const {
        input: { onChange },
      } = this.props;
      onChange(this.state.files);
    }

    if (this.props.onChange) {
      this.props.onChange(this.state.files);
    }
  };

  removeFile = removedFileIdx => {
    const filesWithoutRemovedFile = this.state.files.filter((file, idx) => idx !== removedFileIdx);
    this.setState(
      {
        files: filesWithoutRemovedFile,
        customError: '',
      },
      () => this.onChangeHelper(this.state.files)
    );
  };

  private checkMaxSizeLimit = (files, maxSize) => {
    const hasMaxSizeError = files?.some(file => file?.size > maxSize);
    if (hasMaxSizeError) {
      this.setState({ customError: 'max_size_limit_exceeded' });
      return true;
    }
    return false;
  };

  private checkMaxFilesLimit = (files, maxFiles) => {
    const isMaxFilesExceededError = maxFiles && files.length > maxFiles;
    if (isMaxFilesExceededError) {
      this.setState({ customError: 'max_files_limit_exceeded' });
      return true;
    }
    return false;
  };

  setFiles = (acceptedFiles, rejectedFiles?) => {
    const { maxSize, maxFiles } = this.props;
    const concatenatedFiles = uniqByKey(this.state.files.concat(acceptedFiles), 'name');
    this.setState({ customError: '' });
    const maxFilesError = this.checkMaxFilesLimit(concatenatedFiles, maxFiles);
    const maxSizeError = this.checkMaxSizeLimit(rejectedFiles, maxSize);
    if (maxFilesError || maxSizeError) return;
    this.setState({ files: concatenatedFiles }, () => this.onChangeHelper(this.state.files));
  };

  clearFiles = () => this.setState({ files: [] });

  render() {
    const {
      touched,
      error,
      maxFiles,
      dropzoneText,
      disabled,
      uploadedFilesTextColor,
      uploadedFilesFontSize,
      ...props
    } = this.props;
    return (
      <Flex flexDirection='column' data-cy={`dropzone-${props.name}`}>
        <Dropzone disabled={disabled} {...props} onDrop={this.onChange}>
          {({ getRootProps, getInputProps, rejectedFiles, ...props }) => {
            return (
              <DropzoneInput
                getRootProps={getRootProps}
                getInputProps={getInputProps}
                dropzoneText={dropzoneText}
                disabled={disabled}
              />
            );
          }}
        </Dropzone>
        <UploadedFiles
          files={this.state.files}
          onRemoveFile={disabled ? () => {} : this.removeFile}
          dropzoneName={props.name}
          textColor={uploadedFilesTextColor}
          fontSize={uploadedFilesFontSize}
          removeDisabled={disabled}
        />
        {touched && error && <ErrorMessage>{error}</ErrorMessage>}
        {this.state.customError && (
          <ErrorMessage data-cy={`error-msg-${props.name}`}>{this.state.customError}</ErrorMessage>
        )}
      </Flex>
    );
  }
}
