import React from 'react';
import ReactTooltip from 'react-tooltip';
import styled from 'styled-components';

export const Tooltip: React.FC<ReactTooltip.Props> = ({ children, ...props }) => (
  <StyledReactTooltip {...props}>{children}</StyledReactTooltip>
);

const StyledReactTooltip = styled(ReactTooltip)`
  &.__react_component_tooltip {
    font-size: 1rem;
    line-height: 1.5;
    padding: 16px;
    text-align: center;
  }

  &.__react_component_tooltip.type-dark,
  &.__react_component_tooltip.type-dark.place-right {
    border-style: solid;
    border-width: 1px;
    border-color: #222222;
  }

  &.__react_component_tooltip.show.type-dark {
    color: ${props => props.theme.colors.white};
  }

  &.__react_component_tooltip.show.type-light {
    color: ${props => props.theme.colors.textPrimary};
  }
`;
