import * as CONSTANTS from './schema.constants';
import { getSchema, TSchemaType } from 'rest/api.schema';
import { toISO } from 'utils/date.utils';
import { log } from 'utils';

export const actionSetSchema = schema => ({
  type: CONSTANTS.INSURANCE_SCHEMA_SET,
  schema: schema,
});

export const actionFetchSchemaStart = () => ({
  type: CONSTANTS.INSURANCE_SCHEMA_FETCH_START,
});

export const actionFetchSchemaFailed = e => ({
  type: CONSTANTS.INSURANCE_SCHEMA_FETCH_FAILED,
  error: e,
});

export const setServerDate = (date: Date | string | null) => ({
  type: CONSTANTS.INSURANCE_SCHEMA_SET_SERVER_DATE,
  data: date,
});

export const actionGetSchema = (
  type: TSchemaType | null,
  tfrId?: number | null,
  channelId?: number | null,
  tcpNum?: number | null
) => async dispatch => {
  try {
    dispatch(actionFetchSchemaStart());
    const response = await getSchema(type, tfrId, channelId, tcpNum);
    const serverDate = response?.headers?.date;
    dispatch(setServerDate(toISO(serverDate)));
    const schema = response.data;
    dispatch(actionSetSchema(schema));
    return schema;
  } catch (error) {
    log.error(error.response);
    dispatch(actionFetchSchemaFailed(error));
    return Promise.reject(error);
  }
};
