import * as rdd from 'react-device-detect';
import { pick } from 'lodash-es';

export const getDeviceInfo = (otherAttrs: string[] = []) =>
  pick(rdd, [
    'browserName',
    'browserVersion',
    'deviceType',
    'mobileModel',
    'mobileVendor',
    'osName',
    'osVersion',
    ...otherAttrs,
  ]);
