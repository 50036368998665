import { css } from 'styled-components/macro';

export const baseInputStyle = css<any>`
  line-height: ${({ theme }) => theme.lineHeights.input};
  padding: 0 ${({ withIcon }) => (withIcon ? '40px 0 15px' : '10px')};
  &:hover {
    cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'initial')};
  }

  &:focus,
  &:active {
    line-height: 1.4;
    background: ${({ theme }) => theme.colors.bgInput} !important;
    border: 1px solid
      ${({ theme, error, touched }) => {
        if (touched && error) return theme.colors.borderError;
        return theme.colors.borderTouched;
      }};
  }

  ${({ disabled }) =>
    disabled &&
    css`
      -webkit-text-fill-color: ${({ theme }) => theme.colors.textDisabled};
      opacity: 1;
    `};
`;

export const baseStyle = css<any>`
  -webkit-appearance: none;
  appearance: none;

  background: ${({ theme, disabled, value, focused }) => {
    if (disabled || focused) return theme.colors.white;
    if (!value) return theme.colors.bgInputUntouched;
    return theme.colors.bgInput;
  }} !important;
  border: 1px solid
    ${({ theme: { colors }, value, error, touched, disabled, focused }) => {
      if (disabled) return colors.borderDisabled;
      if (touched && error) return colors.borderError;
      if (focused) return colors.borderTouched;
      if (!touched && !value) return colors.borderUntouched;
      return colors.borderTouched;
    }} !important;
  color: ${({ theme: { colors }, touched, error, disabled, value }) => {
    if (!value) return colors.textPrimary;
    if (disabled) return colors.textDisabled;
    if (value && touched && error) return colors.textError;
    return colors.textPrimary;
  }};
`;
