import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ThemeProvider } from 'styled-components';
import { withRouter } from 'react-router-dom';
import { RouteComponentProps } from 'react-router';

import { AppContainer } from 'components/atoms/AppContainer';
import { AppRouter } from 'routes';
import { CookiePolicy } from 'components/molecules/CookiePolicy';
import { FlashMessages } from 'components/molecules/FlashMessages';
import { Footer } from 'components/molecules/Footer';
import { Header } from 'components/molecules/Header';
import { Preloader } from 'components/molecules/Preloader';
import { PreloaderSmall } from 'components/molecules/Preloader';

import { actionGetProducts } from 'components/organisms/insurance/redux/products.actions';
import {
  actionAddFlashMessage,
  actionRemoveFlashMessage,
} from 'components/molecules/FlashMessages';

import { getProp } from 'utils/object.utils';
import translator from 'utils/translator';
import { captureException } from 'utils/sentry';
import { rest, restFias } from 'rest/rest';
import { getAddressSuggestions } from 'rest/api.fias';
import { imgLogo } from 'utils/images';
import { log } from 'utils';

import { theme, GlobalStyle } from 'styles';
import { sendLog } from 'rest/api';

interface IStateDispatchProps {
  actionGetProducts: () => void;
  actionRemoveFlashMessage: (key: string) => void;
  actionAddFlashMessage: (
    text: string,
    type: string,
    id: string | null | undefined,
    delay?: number | null | undefined,
    closeable?: boolean
  ) => void;
}

type IProps = IStateDispatchProps & RouteComponentProps;

class App extends Component<IProps> {
  notifyOnline = () => {
    this.props.actionRemoveFlashMessage('you_are_offline');
    this.props.actionAddFlashMessage(
      translator('you_are_back_online'),
      'success',
      'you_are_back_online',
      5000
    );
  };
  notifyOffline = () => {
    this.props.actionAddFlashMessage(
      translator('you_are_offline'),
      'info',
      'you_are_offline',
      undefined,
      false
    );
  };

  handleServerAvailability = (error, errorMessage, backOnline, serverOnlineCheck) => {
    sendLog('App', {
      function: 'handleServerAvailability',
      data: {
        message: 'errorMessage',
        err: error?.response || error?.data || error,
      },
    });
    const errorCode = error.code || getProp(error, 'response.status');
    if (errorCode === 401 || errorCode === 400) return Promise.reject(error);

    // when any error happens
    captureException(error, 'handleServerAvailability');
    // timeout = ECONNABORTED
    log.error('Error code: ' + errorCode);

    //when there is no response show server error
    if (navigator.onLine && error.response === undefined) {
      this.props.actionRemoveFlashMessage(backOnline);
      this.props.actionAddFlashMessage(
        translator(errorMessage),
        'info',
        errorMessage,
        undefined,
        false
      );
      this.onlineCheckInterval && clearInterval(this.onlineCheckInterval);
      this.onlineCheckInterval = window.setInterval(() => {
        serverOnlineCheck(() => {
          this.props.actionAddFlashMessage(
            translator(backOnline),
            'success',
            backOnline,
            5000,
            true
          );
          this.props.actionRemoveFlashMessage(errorMessage);
          this.onlineCheckInterval && clearInterval(this.onlineCheckInterval);
        });
      }, 10000);
    }
    return Promise.reject(error);
  };

  onlineCheckInterval: number = 0;
  interceptor;

  componentDidMount() {
    window.addEventListener('online', this.notifyOnline);
    window.addEventListener('offline', this.notifyOffline);

    this.interceptor = rest.interceptors.response.use(
      response => response,
      error => {
        return this.handleServerAvailability(
          error,
          'server_is_not_available_try_later',
          'server_is_back_online',
          this.ifServerOnline
        );
      }
    );

    this.interceptor = restFias.interceptors.response.use(
      response => response,
      error => {
        return this.handleServerAvailability(
          error,
          'fias_server_is_not_available_try_later',
          'fias_server_is_back_online',
          this.ifFiasOnline
        );
      }
    );
  }

  ifFiasOnline(ifOnline, ifOffline) {
    getAddressSuggestions('г. Санкт-Петербург, наб. Обводного канала')
      .then(a => {
        if (a === undefined) {
          ifOffline && ifOffline();
        } else {
          ifOnline && ifOnline();
        }
      })
      .catch(a => {
        ifOffline && ifOffline();
      });
  }

  ifServerOnline(ifOnline, ifOffline) {
    const imgEl = document.createElement('img');
    var img = document.body.appendChild(imgEl);
    img.onload = function () {
      imgEl && imgEl.parentNode && imgEl.parentNode.removeChild(imgEl);
      ifOnline && typeof ifOnline === 'function' && ifOnline();
    };
    img.onerror = function () {
      imgEl && imgEl.parentNode && imgEl.parentNode.removeChild(imgEl);
      ifOffline && typeof ifOffline === 'function' && ifOffline();
    };
    img.src = imgLogo;
  }

  componentWillUnmount() {
    window.removeEventListener('online', this.notifyOnline);
    window.removeEventListener('offline', this.notifyOffline);
    this.interceptor && rest.interceptors.request.eject(this.interceptor);
  }

  UNSAFE_componentWillMount() {
    this.props.actionGetProducts();
  }

  hasFooterEnabled() {
    const {
      location: { pathname },
    } = this.props;
    const routesWithDisabledFooter = ['/forward', '/version'];
    const isRouteWithoutFooter = routesWithDisabledFooter.indexOf(pathname) > -1;
    if (isRouteWithoutFooter) return false;
    return true;
  }
  hasHeaderEnabled() {
    const {
      location: { pathname },
    } = this.props;
    const routesWithDisabledFooter = ['/agent', '/forward', '/agent/', '/forward/'];
    const isRouteWithoutHeader = routesWithDisabledFooter.indexOf(pathname) > -1;
    if (isRouteWithoutHeader) return false;
    return true;
  }

  render() {
    return (
      <ThemeProvider theme={theme}>
        <AppContainer>
          <GlobalStyle />
          <FlashMessages />
          <Preloader />
          <PreloaderSmall />
          {this.hasHeaderEnabled() && <Header />}
          <CookiePolicy />
          <AppRouter />
          {this.hasFooterEnabled() && <Footer />}
        </AppContainer>
      </ThemeProvider>
    );
  }
}

export default withRouter(
  connect<null, IStateDispatchProps, {}>(null, {
    actionGetProducts,
    actionRemoveFlashMessage,
    actionAddFlashMessage,
  })(App)
);
