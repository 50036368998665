import { IProduct, IProductOption } from 'types';
import translate from 'utils/translator';

const getPrice = (tfrId, { data }) => {
  let flat: any[] = [];
  data.forEach(item => (flat = flat.concat(item.options)));
  const option = flat.find(item => item.tfrId === tfrId);
  return option.premium;
};

interface IActiveRisks {
  risks?: string[];
  optionsFiltered?: IProduct['options'];
}

export const getActiveRisks = (
  product?: IProduct | null,
  optionsInput?: IProductOption[]
): IActiveRisks => {
  const filteredIndexes: number[] = [];
  const risks = product?.risks?.filter((risk, index) => {
    if (/#.*/.test(risk)) return true;
    const currentSum =
      optionsInput &&
      optionsInput.find(item => {
        return item.riskSumsInsured[index] !== null && item.riskSumsInsured[index] !== undefined;
      });
    if (currentSum) return true;
    filteredIndexes.push(index);
    return false;
  });
  const optionsFiltered = optionsInput?.map(item => ({
    ...item,
    riskSumsInsured: item.riskSumsInsured?.filter((item, index) =>
      filteredIndexes.find(item => item === index) ? false : true
    ),
  }));

  if (risks) return { risks: risks.map(item => item.replace('#', '')), optionsFiltered };
  return { risks, optionsFiltered };
};

export const gaPushFormSubmit = (tfrId, products: any) => {
  if ((global as any).dataLayer) {
    (global as any).dataLayer.push({
      event: 'formSubmited',
      value: `${getPrice(tfrId, products)} ₽`,
    });
  }
};

export const petTypeOptions = [
  { value: translate('cat'), label: translate('cat') },
  { value: translate('dog'), label: translate('dog') },
];

export const petAgeOptions = [
  { value: translate('from_6_months_to_1_year'), label: translate('from_6_months_to_1_year') },
  { value: `2 ${translate('years')}`, label: `2 ${translate('years')}` },
  { value: `3 ${translate('years')}`, label: `3 ${translate('years')}` },
  { value: `4 ${translate('years')}`, label: `4 ${translate('years')}` },
  { value: `5 ${translate('years_1')}`, label: `5 ${translate('years_1')}` },
  { value: `6 ${translate('years_1')}`, label: `6 ${translate('years_1')}` },
  {
    value: `${translate('from_7_to_9_years')}`,
    label: `${translate('from_7_to_9_years')}`,
  },
];
