import { rest } from './rest';
import { promiseSuccess } from './rest.utils';
import { objectToQuery } from 'utils/url.utils';
import { log } from 'utils';

// mocks
import json_schema from 'rest/mocks/schema.json';
import json_schema_checkout from 'rest/mocks/schema.json';
import json_schema_agent from 'rest/mocks/schema.json';

const MOCKS_ENABLED = false;

export type TSchemaType = 'product' | 'getpolicy' | 'forward' | 'zabolel' | 'prolong';

export const getSchema = (
  type: TSchemaType | null = 'product',
  tfrId?: number | null,
  channelId?: number | null,
  tcpNum?: number | null
) => {
  if (!type) type = 'product';
  if (channelId && tfrId) return getSchemaAgent(channelId, tfrId);
  if (!channelId && tfrId) return getSchemaCheckout(tfrId);
  return getSchemaGeneric(type);
};

// GET /schema/getpolicy || GET /schema/forward ||
// GET /schema/zabolel || GET /schema/prolong
export const getSchemaGeneric = type => {
  if (MOCKS_ENABLED) {
    log.debug(`REST: /schema/${type}`);
    log.debug({ type });
    return promiseSuccess(json_schema);
  }
  return rest.get(`/schema/${type}`);
};

// GET /schema/product?tfr_id=533
export const getSchemaCheckout = tfrId => {
  if (MOCKS_ENABLED) {
    log.debug(`REST: /schema/product?tfr_id=${tfrId}`);
    return promiseSuccess(json_schema_checkout);
  }
  return rest.get(`/schema/product?tfr_id=${tfrId}`);
};

// GET /schema/product?tcp_num=1&tcl_id=1
export const getSchemaAgent = (channelId, tfrId) => {
  const params = objectToQuery({
    tfr_id: tfrId,
    tcl_id: channelId,
  });
  if (MOCKS_ENABLED) {
    log.debug(`REST: /schema/product?${params}`);
    return promiseSuccess(json_schema_agent);
  }
  return rest.get(`/schema/product?${params}`);
};
