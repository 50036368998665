import { css } from 'styled-components';

const PUBLIC_URL = process.env.PUBLIC_URL;

export const FONTS_DEFAULT_FONT_FAMILY = 'Open Sans';
export const FONTS_FALLBACK_FONT_FAMILY = `Arial, '-apple-system', 'Helvetica Neue', Helvetica, sans-serif`;
export const FONTS_FONT_FAMILY = `${FONTS_DEFAULT_FONT_FAMILY}, ${FONTS_FALLBACK_FONT_FAMILY}`;

export const fontsStyles = css`
  // cyrillic-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(${PUBLIC_URL}/fonts/OpenSans-Italic.ttf) format('truetype');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  // cyrillic
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(${PUBLIC_URL}/fonts/OpenSans-Italic.ttf) format('truetype');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  // greek-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(${PUBLIC_URL}/fonts/OpenSans-Italic.ttf) format('truetype');
    unicode-range: U+1F00-1FFF;
  }

  // greek
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(${PUBLIC_URL}/fonts/OpenSans-Italic.ttf) format('truetype');
    unicode-range: U+0370-03FF;
  }

  // vietnamese
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(${PUBLIC_URL}/fonts/OpenSans-Italic.ttf) format('truetype');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  }

  // latin-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(${PUBLIC_URL}/fonts/OpenSans-Italic.ttf) format('truetype');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  }

  // latin
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 400;
    src: local('Open Sans Italic'), local('OpenSans-Italic'), url(${PUBLIC_URL}/fonts/OpenSans-Italic.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
  }

  // cyrillic-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBoldItalic.ttf) format('truetype');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  // cyrillic
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBoldItalic.ttf) format('truetype');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  // greek-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBoldItalic.ttf) format('truetype');
    unicode-range: U+1F00-1FFF;
  }

  // greek
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBoldItalic.ttf) format('truetype');
    unicode-range: U+0370-03FF;
  }

  // vietnamese
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBoldItalic.ttf) format('truetype');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  }

  // latin-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBoldItalic.ttf) format('truetype');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  }

  // latin
  @font-face {
    font-family: 'Open Sans';
    font-style: italic;
    font-weight: 600;
    src: local('Open Sans SemiBold Italic'), local('OpenSans-SemiBoldItalic'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBoldItalic.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
  }

  // cyrillic-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(${PUBLIC_URL}/fonts/OpenSans-Light.ttf) format('truetype');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  // cyrillic
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(${PUBLIC_URL}/fonts/OpenSans-Light.ttf) format('truetype');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  // greek-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(${PUBLIC_URL}/fonts/OpenSans-Light.ttf) format('truetype');
    unicode-range: U+1F00-1FFF;
  }

  // greek
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(${PUBLIC_URL}/fonts/OpenSans-Light.ttf) format('truetype');
    unicode-range: U+0370-03FF;
  }

  // vietnamese
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(${PUBLIC_URL}/fonts/OpenSans-Light.ttf) format('truetype');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  }

  // latin-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(${PUBLIC_URL}/fonts/OpenSans-Light.ttf) format('truetype');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  }

  // latin
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local('Open Sans Light'), local('OpenSans-Light'), url(${PUBLIC_URL}/fonts/OpenSans-Light.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
  }

  // cyrillic-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(${PUBLIC_URL}/fonts/OpenSans-Regular.ttf) format('truetype');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  // cyrillic
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(${PUBLIC_URL}/fonts/OpenSans-Regular.ttf) format('truetype');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  // greek-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(${PUBLIC_URL}/fonts/OpenSans-Regular.ttf) format('truetype');
    unicode-range: U+1F00-1FFF;
  }

  // greek
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(${PUBLIC_URL}/fonts/OpenSans-Regular.ttf) format('truetype');
    unicode-range: U+0370-03FF;
  }

  // vietnamese
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(${PUBLIC_URL}/fonts/OpenSans-Regular.ttf) format('truetype');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  }

  // latin-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(${PUBLIC_URL}/fonts/OpenSans-Regular.ttf) format('truetype');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  }

  // latin
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local('Open Sans Regular'), local('OpenSans-Regular'), url(${PUBLIC_URL}/fonts/OpenSans-Regular.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
  }

  // cyrillic-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBold.ttf) format('truetype');
    unicode-range: U+0460-052F, U+1C80-1C88, U+20B4, U+2DE0-2DFF, U+A640-A69F, U+FE2E-FE2F;
  }

  // cyrillic
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBold.ttf) format('truetype');
    unicode-range: U+0400-045F, U+0490-0491, U+04B0-04B1, U+2116;
  }

  // greek-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBold.ttf) format('truetype');
    unicode-range: U+1F00-1FFF;
  }

  // greek
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBold.ttf) format('truetype');
    unicode-range: U+0370-03FF;
  }

  // vietnamese
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBold.ttf) format('truetype');
    unicode-range: U+0102-0103, U+0110-0111, U+1EA0-1EF9, U+20AB;
  }

  // latin-ext
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBold.ttf) format('truetype');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+20A0-20AB, U+20AD-20CF, U+2C60-2C7F, U+A720-A7FF;
  }

  // latin
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'), url(${PUBLIC_URL}/fonts/OpenSans-SemiBold.ttf) format('truetype');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2212, U+2215;
  }

  // latin + ext, greek, cyrilic
  @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local('Open Sans Bold'), local('OpenSans-Bold'), url('${PUBLIC_URL}/fonts/opensans-bold-webfont.ttf') format('truetype');
  }

  // latin + ext, greek, cyrilic
  @font-face {
    font-family: 'open_sansbold_italic';
    font-weight: 700;
    font-style: italic;
    src: local('Open Sans Bold Italic'), local('OpenSans-BoldItalic'), url('${PUBLIC_URL}/fonts/opensans-bolditalic-webfont.ttf') format('truetype');
  }

  // latin + ext, greek, cyrilic
  @font-face {
    font-family: 'open_sans_extraboldregular';
    font-weight: 800;
    font-style: normal;
    src: local('Open Sans Extra Bold'), local('OpenSans-ExtraBold'), url('${PUBLIC_URL}/fonts/opensans-extrabold-webfont.ttf') format('truetype');
  }

  // latin + ext, greek, cyrilic
  @font-face {
    font-family: 'open_sans_extrabolditalic';
    font-weight: 800;
    font-style: italic;
    src: local('Open Sans Extra Bold Italic'), local('OpenSans-ExtraBoldItalic'), url('${PUBLIC_URL}/fonts/opensans-extrabolditalic-webfont.ttf') format('truetype');
  }
`;

export const fontsStylesProximaNova = css`
  @font-face {
    font-family: 'Proxima Nova Rg';
    font-weight: normal;
    font-style: normal;
    src: local('Proxima Nova Regular'), url('${PUBLIC_URL}/fonts/ProximaNova-Regular.ttf') format('truetype'), url('${PUBLIC_URL}/fonts/ProximaNova-Regular.eot') format('embedded-opentype'), url('${PUBLIC_URL}/fonts/ProximaNova-Regular.woff') format('woff');
  }

  @font-face {
    font-family: 'Proxima Nova Rg';
    font-weight: semi-bold;
    font-style: normal;
    src: local('Proxima Nova Medium'), url('${PUBLIC_URL}/fonts/ProximaNova-Medium.ttf') format('truetype'), url('${PUBLIC_URL}/fonts/ProximaNova-Medium.eot') format('embedded-opentype'), url('${PUBLIC_URL}/fonts/ProximaNova-Medium.woff') format('woff');
  }

  @font-face {
    font-family: 'Proxima Nova Rg';
    font-weight: bold;
    font-style: normal;
    src: local('Proxima Nova Bold'), url('${PUBLIC_URL}/fonts/ProximaNova-Bold.ttf') format('truetype'), url('${PUBLIC_URL}/fonts/ProximaNova-Bold.eot') format('embedded-opentype'), url('${PUBLIC_URL}/fonts/ProximaNova-Bold.woff') format('woff');
  }
`;

export const fontsStylesGolos = css`
  /* GOLOS FONT FAMILY */
  @font-face {
    font-family: 'GolosRegular';
    src: url('${PUBLIC_URL}/fonts/golos-text_regular.woff2') format('woff2'), url('${PUBLIC_URL}/fonts/golos-text_regular.woff') format('woff'), url(${PUBLIC_URL}/fonts/golos-text_regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'GolosBlack';
    src: url('${PUBLIC_URL}/fonts/golos-text_black.woff2') format('woff2'), url('${PUBLIC_URL}/fonts/golos-text_black.woff') format('woff'), url(${PUBLIC_URL}/fonts/golos-text_black.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'GolosBold';
    src: url('${PUBLIC_URL}/fonts/golos-text_bold.woff2') format('woff2'), url('${PUBLIC_URL}/fonts/golos-text_bold.woff') format('woff'), url(${PUBLIC_URL}/fonts/golos-text_bold.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'GolosDemiBold';
    src: url('${PUBLIC_URL}/fonts/golos-text_demibold.woff2') format('woff2'), url('${PUBLIC_URL}/fonts/golos-text_demibold.woff') format('woff'), url(${PUBLIC_URL}/fonts/golos-text_demibold.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }

  @font-face {
    font-family: 'GolosMedium';
    src: url('${PUBLIC_URL}/fonts/golos-text_medium.woff2') format('woff2'), url('${PUBLIC_URL}/fonts/golos-text_medium.woff') format('woff'), url(${PUBLIC_URL}/fonts/golos-text_medium.ttf) format('truetype');
    font-weight: 400;
    font-style: normal;
  }

  @font-face {
    font-family: 'GolosVF';
    src: url('${PUBLIC_URL}/fonts/golos-text_vf.woff2') format('woff2'), url('${PUBLIC_URL}/fonts/golos-text_vf.woff') format('woff'), url(${PUBLIC_URL}/fonts/golos-text_vf.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
  }
`;
