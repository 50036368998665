import React from 'react';
import styled from 'styled-components';

interface IListContainerProps {
  children: React.ReactNode;
}

export const ListContainer: React.SFC<IListContainerProps> = ({ children, ...props }) => (
  <Container {...props}>{children}</Container>
);

const Container = styled.ul`
  margin: 0;
  line-height: 1.7;
`;
