export const isNumber = number => typeof number === 'number';

export const round = (value: number, decimals: number) => {
  if (isNaN(value)) return 0;
  const fractionSize = 10 ** decimals;
  return Math.round(value * fractionSize) / fractionSize;
};

export const roundUp = (value: number, decimals: number) => {
  const fractionSize = 10 ** decimals;
  return Math.ceil(value * fractionSize) / fractionSize;
};

export const toPercent = (value: number, decimals: number = 2) =>
  round(value * 100, decimals) + ' %';

// 1234567 => 1 234 567
export const withSpaces = (value: number | string | undefined | null): string =>
  value?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, '\u00A0') || '';

export const toRublePrice = (value: number | string | undefined | null): string =>
  (value && `${withSpaces(value)} ₽`) || '';
