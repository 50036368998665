import React from 'react';
import styled from 'styled-components/macro';
import { Radio as ReactRadio } from 'react-radio-group';

import translate from 'utils/translator';

export interface IRadioProps {
  label: string;
  value?: string;
  name?: string;
  disabled?: boolean;
  fontColor?: string;
  fontSize?: string;
}

export const Radio: React.SFC<IRadioProps> = ({
  value,
  name,
  label,
  disabled = false,
  fontColor,
  fontSize,
  ...props
}) => (
  <Label disabled={disabled} fontColor={fontColor} fontSize={fontSize}>
    <ReactRadio value={value} name={name} label={label} disabled={disabled} />
    {translate(label)}
    <Checkmark disabled={disabled} />
  </Label>
);

const Checkmark = styled.span<Partial<IRadioProps>>`
  position: absolute;
  left: 0;
  top: 4px;
  width: 16px;
  height: 16px;
  border: 1px solid ${({ disabled }) => (disabled ? 'rgb(185, 185, 185)' : '#9b9b9b')};
  border-radius: 50%;
  background: #ffffff;
  transition: 0.2s;
  font-family: inherit;
`;

const Label = styled.label<Partial<IRadioProps>>`
  font-size: ${({ theme, fontSize }) =>
    (fontSize && theme.fontSizes[fontSize]) || fontSize || '125%'};
  display: inline-block;
  font-family: inherit;
  cursor: pointer;
  position: relative;
  margin-right: 20px;
  padding-left: 28px;
  user-select: none;
  color: ${({ theme, fontColor }) =>
    (fontColor && theme.colors[fontColor]) || theme.colors.textPrimary};

  &:hover ${Checkmark} {
    border: 1px solid ${({ disabled }) => (disabled ? 'rgb(185, 185, 185)' : '#7ed321')};
  }

  input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 50px;
    cursor: pointer;
    font-family: inherit;

    &:checked + ${Checkmark} {
      border: 4px solid ${({ disabled }) => (disabled ? 'rgb(185, 185, 185)' : '#7ed321')};
      width: 10px;
      height: 10px;
    }
  }
`;
