export const FLAT = 'flat';
export const HOME = 'home';
export const FAMILY = 'family';
export const OUR_KIDZ = 'ourkidz';
export const CARDS = 'cards';
export const FAIRINS = 'fairins';
export const COVID = 'covid';
export const GOODS = 'goods';

export const products = [FLAT, HOME, FAMILY, OUR_KIDZ, CARDS, FAIRINS, COVID, GOODS];

export const checkProductExists = product => products.includes(product);
