import axios from 'axios';

import { getApiBaseUrl, getClientPortalDadataApiBaseUrl } from './rest.utils';

export const rest = axios.create({
  baseURL: getApiBaseUrl(),
  timeout: 25000,
});

export const restFias = axios.create({
  // baseURL: 'https://suggestions.dadata.ru',
  baseURL: getClientPortalDadataApiBaseUrl(),
  timeout: 25000,
});

// getApiDadata
