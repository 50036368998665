import { INSURANCE_SET_DISCOUNT, INSURANCE_SET_DISCOUNT_AMOUNT } from './promo.constants';
import * as CONSTANTS from './order.constants';

import { IInsuranceOrderReducer } from 'types';

const initialState: IInsuranceOrderReducer = {
  discount: 0,
  promo_code: null,
  values: {},
  productDuration: null,
  discountAmount: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.INSURANCE_ORDER_START:
      return {
        ...state,
        product: action.product,
        discount: 0,
        promo_code: null,
        values: {},
      };
    case INSURANCE_SET_DISCOUNT:
      return {
        ...state,
        discount: action.discount,
        promo_code: action.code,
        strikePremium: action.strikethroughPremium,
        strikeSum: action.strikethroughSumInsured,
        discountAmount: action.discountAmount,
      };
    case CONSTANTS.INSURANCE_SET_PRODUCT_ID:
      return {
        ...state,
        tfrId: action.id,
      };
    case CONSTANTS.INSURANCE_SEV_VALUES:
      return {
        ...state,
        values: action.values,
      };
    case CONSTANTS.INSURANCE_ORDER_SET_ORDER_ID:
      return {
        ...state,
        orderId: action.orderId,
      };
    case CONSTANTS.INSURANCE_ORDER_SET_PRODUCT:
      return {
        ...state,
        product: action.product,
      };
    case CONSTANTS.INSURANCE_ORDER_SET_PRODUCT_DURATION:
      return {
        ...state,
        productDuration: action.duration,
      };
    case INSURANCE_SET_DISCOUNT_AMOUNT:
      return {
        ...state,
        discountAmount: action.amount,
      };
    default:
      return state;
  }
};
