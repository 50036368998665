import React, { useState } from 'react';
import { useDispatch } from 'react-redux';

import { Navigation } from 'components/atoms/Navigation';
import { NavigationItem } from 'components/atoms/NavigationItem';
import { Subnav } from '../Subnav';

import { actionSubnavSetKeepOpen, actionSubnavSetItems } from 'store/actions/header.actions';
import {
  submenuAboutUs,
  submenuCustomerSupport,
} from 'components/molecules/Header/Header.submenus';
import translate from 'utils/translator';

export const NavigationDesktop: React.FC = () => {
  const [activeItem, setActiveItem] = useState<number>(0);
  const dispatch = useDispatch();
  const reduxSubnavSetKeepOpen = keepOpen => dispatch(actionSubnavSetKeepOpen(keepOpen));
  const reduxSubnavSetItems = submenuItems => dispatch(actionSubnavSetItems(submenuItems));

  const handleMouseEvent = active => {
    setTimeout(() => reduxSubnavSetKeepOpen(!!active));
    setActiveItem(active);
    const submenuItems = active === 1 ? submenuAboutUs : submenuCustomerSupport;
    reduxSubnavSetItems(submenuItems);
  };

  return (
    <Navigation>
      <NavigationItem>
        <a href='https://www.homeins.ru/customer-support/had-an-accident/'>
          {translate('had_an_accident')}
        </a>
      </NavigationItem>
      <NavigationItem hasSubmenu={true} isActive={activeItem === 1}>
        <div onMouseEnter={() => handleMouseEvent(1)} onMouseLeave={() => handleMouseEvent(0)}>
          <a
            href='https://www.homeins.ru/about/membership/'
            className={`nav-item__submenu${activeItem === 1 ? ' nav-item__submenu--active' : ''}`}
          >
            {translate('about_us')}
          </a>
          {activeItem === 1 && <Subnav />}
        </div>
      </NavigationItem>
      <NavigationItem hasSubmenu={true} isActive={activeItem === 2}>
        <div onMouseEnter={() => handleMouseEvent(2)} onMouseLeave={() => handleMouseEvent(0)}>
          <a
            href='https://www.homeins.ru/customer-support/feedback/form/'
            className={`nav-item__submenu${activeItem === 2 ? ' nav-item__submenu--active' : ''}`}
          >
            {translate('customer_support')}
          </a>
          {activeItem === 2 && <Subnav />}
        </div>
      </NavigationItem>
    </Navigation>
  );
};
