export const SALES_HISTORY = 'SALES_HISTORY';
export const SALES_HISTORY_SUCCESS = 'SALES_HISTORY_SUCCESS';
export const SALES_HISTORY_FAIL = 'SALES_HISTORY_FAIL';
export const RESET_SALES_HISTORY = 'RESET_SALES_HISTORY';
export const ORDER_STATUSES = 'ORDER_STATUSES';
export const ORDER_STATUSES_SUCCESS = 'ORDER_STATUSES_SUCCESS';
export const ORDER_STATUSES_FAIL = 'SALES_HISTORY_FAIL';
export const SET_AUTH_TYPE = 'SET_AUTH_TYPE';
export const SET_AGENT_ID_INPUT_DISABLED = 'SET_AGENT_ID_INPUT_DISABLED';
export const SET_AGENT_ID_CONFIRMED = 'SET_AGENT_ID_CONFIRMED';
export const SET_FILTER_COUNTDOWN_RESTART_KEY = 'SET_FILTER_COUNTDOWN_RESTART_KEY';
export const SET_FILTER_COUNTDOWN_START_DATE = 'SET_FILTER_COUNTDOWN_START_DATE';
export const INFORMATION_DOCS = 'INFORMATION_DOCS';
export const INFORMATION_DOCS_SUCCESS = 'INFORMATION_DOCS_SUCCESS';
export const INFORMATION_DOCS_FAIL = 'INFORMATION_DOCS_FAIL';
export const INFORMATION_DOCS_RESET = 'INFORMATION_DOCS_RESET';
export const SET_SALES_HISTORY_TABLE_STATE = 'SET_SALES_HISTORY_TABLE_STATE';
export const RESET_SALES_HISTORY_TABLE_STATE = 'RESET_SALES_HISTORY_TABLE_STATE';

export const RESET_FORM_VALUES = 'RESET_FORM_VALUES';
export const SET_ACTIVE_SCREEN = 'SET_ACTIVE_SCREEN';
export const SET_AGENT_ID = 'SET_AGENT_ID';
export const SET_CHANNEL_ID = 'SET_CHANNEL_ID';
export const SET_CONTRACT_NUMBER = 'SET_CONTRACT_NUMBER';
export const SET_FORM_VALUES = 'SET_FORM_VALUES';
export const SET_LOGIN = 'SET_LOGIN';
export const SET_ORDER_ID = 'SET_ORDER_ID';
export const SET_PAGE_CONTENT_TYPE = 'SET_PAGE_CONTENT_TYPE';
export const SET_PAYMENT_ID = 'SET_PAYMENT_ID';
export const SET_PAYMENT_URL = 'SET_PAYMENT_URL';
export const SET_PRODUCT_DURATION = 'SET_PRODUCT_DURATION';
export const SET_PRODUCT_OPTION = 'SET_PRODUCT_OPTION';
export const SET_PRODUCT_OPTION_INDEX = 'SET_PRODUCT_OPTION_INDEX';
export const SET_PRODUCT_TYPE = 'SET_PRODUCT_TYPE';
export const SET_PRODUCTS = 'SET_PRODUCTS';
export const SET_TOKEN = 'SET_TOKEN';
export const SET_PREMIUM_CALCULATION = 'SET_PREMIUM_CALCULATION';
export const SET_SALES_HISTORY_LIMITS = 'SET_SALES_HISTORY_LIMITS';
export const SET_PAYMENT_METHOD = 'SET_PAYMENT_METHOD';
