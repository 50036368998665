export interface ISchema {
  start?: boolean;
  schema: {
    $schema: string;
    type: string;
    title: string;
    description: string;
    properties: {
      [param: string]: {
        description?: string;
        type?: string | string[];
        pattern?: string;
        format?: string;
        maxLength?: number;
        enum: any[];
      };
    };
    required: string[];
  };
  serverDate?: string;
}

export enum EProducts {
  FLAT = 'flat',
  HOME = 'home',
  FAMILY = 'family',
  OUR_KIDZ = 'ourkidz',
  CARDS = 'cards',
  FAIRINS = 'fairins',
  COVID = 'covid',
  GOODS = 'goods',
}
