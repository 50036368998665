import React from 'react';
import styled from 'styled-components/macro';

import { Text } from 'components/atoms/Text';
import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { FnWithArgs } from 'types';

interface ICheckboxProps {
  value: string;
  disabled?: boolean;
  label: string;
  touched: boolean;
  error: string | object;
  name: string;
  fontSize?: string;
  fontColor?: string;
  defaultValue?: string;
  wrapperClassname?: string;
  onChange: FnWithArgs;
  onBlur: FnWithArgs;
  onValueChange: FnWithArgs;
}

export const Checkbox: React.SFC<ICheckboxProps> = ({
  value,
  disabled,
  label,
  touched,
  error,
  onChange,
  name,
  defaultValue = false,
  wrapperClassname,
  fontSize,
  fontColor,
  onValueChange,
  ...props
}) => {
  const handleOnChange = e => {
    onChange?.(e, name);
    onValueChange?.(e?.currentTarget?.checked);
  };

  const val = typeof value === 'boolean' ? value : defaultValue;
  const classes = ['checkbox'];
  if (error) classes.push('error');
  if (wrapperClassname) classes.push(wrapperClassname);
  if (val) classes.push('checkbox-checked');
  if (disabled) classes.push('checkbox-disabled');

  return (
    <>
      <label className={classes.join(' ')}>
        <input
          name={name}
          onChange={handleOnChange}
          value={String(val)}
          type='checkbox'
          disabled={disabled}
          checked={!!val}
          {...props}
        />
        {React.isValidElement(label) ? (
          <StyledText size={fontSize ? fontSize : 'base'} color={fontColor}>
            {label}
          </StyledText>
        ) : (
          <StyledText size={fontSize ? fontSize : 'base'} color={fontColor} text={label} />
        )}

        <span
          className={
            disabled
              ? val
                ? 'checkmark checkmark-disabled checkmark-checked'
                : 'checkmark checkmark-disabled checkmark-notchecked'
              : val
              ? 'checkmark checkmark-checked'
              : 'checkmark checkmark-notchecked'
          }
        />
        <br />
      </label>
      {error && (
        <ErrorMessage data-cy={`error-msg-${name}`} ml='24px'>
          {error}
        </ErrorMessage>
      )}
    </>
  );
};

const StyledText = styled(Text)`
  @media only screen and (max-width: 26.625em) {
    line-height: 1.5;
    font-size: 105%;
  }
`;
