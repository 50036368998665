import React, { useCallback, ChangeEvent } from 'react';

import { Input, IInputProps } from 'components/atoms/form/Input';

interface IInputNumberProps extends IInputProps {}

export const InputNumber: React.FC<IInputNumberProps> = ({ onChange, ...props }) => {
  const checkIsValidChar = useCallback((keyVal: string): boolean => {
    const INVALID_CHARS = ['+', 'e', '-', '.'];
    const isValidChar = !INVALID_CHARS.includes(keyVal);
    return isValidChar;
  }, []);

  const checkIsOverMaxLen = useCallback((event: ChangeEvent<HTMLInputElement>): boolean => {
    const maxLength = event?.target?.maxLength;
    if (maxLength === -1) return false;
    const inputValue = event?.target?.value;
    const inputValueLength = inputValue?.length;
    const overMaxLength = typeof maxLength === 'number' && inputValueLength > maxLength;
    return overMaxLength;
  }, []);

  // allow only numbers
  const onKeyDown = useCallback(
    event => {
      const pressedKey = event.key;
      const allowedKey = checkIsValidChar(pressedKey);
      const overMaxLength = checkIsOverMaxLen(event);
      const maxLength = event?.target?.maxLength;
      if (overMaxLength) event.target.value = event.target.value.slice(0, maxLength).trim();
      if (!allowedKey) event.preventDefault();
    },
    [checkIsValidChar, checkIsOverMaxLen]
  );

  const handleOnChange = useCallback(
    event => {
      const inputValue = event?.target?.value;
      const isNum = /^\d+$/.test(inputValue);
      const overMaxLength = checkIsOverMaxLen(event);
      if ((inputValue && !isNum) || overMaxLength) {
        event.preventDefault();
        return;
      }
      onChange?.(event);
    },
    [onChange, checkIsOverMaxLen]
  );

  const handlePaste = useCallback(
    event => {
      const clipboardData = event?.clipboardData || (window as any).clipboardData;
      const pastedData = clipboardData.getData('Text');
      if (isNaN(pastedData)) {
        event.preventDefault();
        return;
      }
      onChange?.(event);
    },
    [onChange]
  );

  return (
    <Input
      min='0'
      onKeyDown={onKeyDown}
      onPaste={handlePaste}
      pattern='[0-9]*'
      step='1'
      {...props}
      onChange={handleOnChange}
      type='number'
    />
  );
};
