import moment from 'moment';
import { FormErrors } from 'redux-form';

import { FLAT, FAIRINS } from 'components/organisms/insurance/products';

import { INameSuggestion } from 'types';
import { log } from 'utils';

export const getParamsFromQueryString = (queryString /*: string*/) => {
  const formattedQueryString = queryString
    .replace(/^\?/, '')
    .split('&')
    .map(queryParam => queryParam.split('='))
    .map(queryParam => {
      const [key, value] = queryParam;
      return [key, decodeURIComponent(value)].join('":"');
    })
    .join('","');

  return JSON.parse(`{"${formattedQueryString}"}`);
};

type GetFormValuesArguments = {
  queryString: string;
  initialValues: {
    [key: string]: string | any;
  };
};

export const getFormValues = (args: GetFormValuesArguments, dateFormat?: string) => {
  const { initialValues, queryString } = args;

  const params = getParamsFromQueryString(queryString);

  const getValue = (key: string) => {
    // initial values have higher priority
    if (initialValues[key] === undefined || initialValues[key] === '') {
      return params[key] === undefined ? '' : params[key];
    }
    return initialValues[key];
  };

  const inputnames = {} as any;

  if (getValue('phsurname') || getValue('phname') || getValue('phmiddlename')) {
    // join names into one string (ignore null values)
    inputnames.inputphname = [getValue('phsurname'), getValue('phname'), getValue('phmiddlename')]
      .filter(Boolean)
      .join(' ')
      .trim();
  }

  if (getValue('asurname') || getValue('aname') || getValue('amiddlename')) {
    // join names into one string (ignore null values)
    inputnames.inputaname = [getValue('asurname'), getValue('aname'), getValue('amiddlename')]
      .filter(Boolean)
      .join(' ')
      .trim();
  }

  const formValues = {
    ...initialValues,
    phcellphone: getValue('phcellphone'),
    phbirthdate: getValue('phbirthdate')
      ? dateFormat
        ? moment(getValue('phbirthdate')).format(dateFormat)
        : moment(getValue('phbirthdate'))
      : '',
    abirthdate: getValue('abirthdate')
      ? dateFormat
        ? moment(getValue('abirthdate')).format(dateFormat)
        : moment(getValue('abirthdate'))
      : '',
    phdocnum: getValue('phdocnum'),
    phemail: getValue('phemail'),
    inputphname: params?.inputphname,
    ...inputnames,
  };

  return formValues;
};

type IsBlockOpenArguments = {
  blockNumber: number;
  values: {
    [key: string]: string;
  };
  errors: FormErrors<any, string>;
};

export const isBlockOpen = (args: IsBlockOpenArguments) => {
  const { blockNumber, values, errors } = args;
  if (!values) return true;

  // if one of the block fields value is empty string or incorrect value then block is open

  switch (blockNumber) {
    case 1: {
      const isIncomplete = !values?.inputphname || values?.phbirthdate === '';

      const hasError =
        errors.inputphname !== undefined ||
        errors.phemail !== undefined ||
        errors.phcellphone !== undefined ||
        errors.phdocnum !== undefined ||
        errors.phbirthdate !== undefined;

      return isIncomplete || hasError;
    }

    case 2: {
      const isIncomplete = values?.phcellphone === '' || values?.phemail === '';
      const hasError = errors.phcellphone !== undefined || errors.phemail !== undefined;
      return isIncomplete || hasError;
    }

    case 3: {
      const isIncomplete =
        values?.phdocnum === '' ||
        values?.phdocgive === '' ||
        values?.phdocgivecode === '' ||
        values?.phdocgivedate === '';

      const hasError =
        errors.phdocnum !== undefined ||
        errors.phdocgive !== undefined ||
        errors.phdocgivecode !== undefined ||
        errors.phdocgivedate !== undefined;

      return isIncomplete || hasError;
    }

    default:
      return true;
  }
};

export const canRender = (props /*: object*/) /*: boolean*/ => {
  const order = props.order;
  const product =
    props.products &&
    props.products.data &&
    props.products.data.find(product => product.name === props.order.product);

  if (product === undefined) {
    log.debug(
      `product not found products: ${JSON.stringify(props.products.data)} order: ${JSON.stringify(
        props.order.product
      )}`
    );
    return false;
  }
  const option = product && product.options.find(opt => opt.tfrId === props.order.tfrId);

  if (option === undefined) {
    log.debug(
      `tfrId ${JSON.stringify(props.order.tfrId)} not found in product options ${JSON.stringify(
        product.options
      )}`
    );
    return false;
  }
  return (
    (order.product === FLAT || order.product === FAIRINS) &&
    order.tfrId !== undefined &&
    option !== undefined
  );
};

const fiasKeys = [
  'housefiasid',
  'fiascode',
  'region',
  'district',
  'city',
  'point',
  'street',
  'entrance',
  'corpus',
  'postindex',
  'fulladdress',
];

export const addressFill = (source, targetType, sourceType?) => {
  const result = {};
  const sourceTypeWorking = sourceType ? sourceType : targetType === 'ob' ? 'ph' : 'ob';
  fiasKeys.forEach(item => {
    source[`${targetType}${item}`] = source[`${sourceTypeWorking}${item}`];
    result[`${targetType}${item}`] = source[`${sourceTypeWorking}${item}`];
  });
  return result;
};

export const emptyFiasAddress = (values /*: object*/, type) => {
  fiasKeys.forEach(item => {
    values[`${type}${item}`] = '';
  });
};

export const sexOptions = [
  { label: 'male', value: '1' },
  { label: 'female', value: '2' },
];

export const mapNameSuggestion = (suggestion: INameSuggestion, type: 'a' | 'ph' = 'ph') => {
  if (!suggestion || !suggestion.data)
    return {
      [`input${type}name`]: '',
      [`${type}surname`]: '',
      [`${type}name`]: '',
      [`${type}middlename`]: '',
      [`${type}sex`]: '',
    };

  return {
    [`input${type}name`]: suggestion.value,
    [`${type}surname`]: suggestion.data.surname,
    [`${type}name`]: suggestion.data.name,
    [`${type}middlename`]: suggestion.data.patronymic,
    [`${type}sex`]:
      suggestion.data.gender === 'MALE' || suggestion.data.gender === 'UNKNOWN' ? 1 : 2,
  };
};

export const createNameSuggestionFromInput = (value: string): INameSuggestion[] => {
  if (!value || typeof value !== 'string') return [];

  const splittedValue = value.split(' ');
  return [
    {
      value: value,
      unrestricted_value: value,
      data: {
        surname: splittedValue[0] || null,
        name: splittedValue[1] || null,
        patronymic: splittedValue[2] || null,
        gender: 'MALE',
        source: null,
        qc: '1',
      },
    },
  ];
};
