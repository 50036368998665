import React from 'react';
import styled from 'styled-components';
import { iconNavOpen, iconNavClose, iconNavCloseWhite } from 'utils/images';

interface IOwnProps {
  children: React.ReactNode;
  hasSubmenu?: boolean;
  isActive?: boolean;
  onMouseEnter?: (e: React.MouseEvent<HTMLDivElement>) => any;
  onMouseLeave?: (e: React.MouseEvent<HTMLDivElement>) => any;
}

export const NavigationItem: React.FC<IOwnProps> = ({ children, hasSubmenu, isActive }) => (
  <Wrapper hasSubmenu={hasSubmenu} isActive={isActive}>
    {children}
  </Wrapper>
);

const Wrapper = styled.div<Pick<IOwnProps, 'hasSubmenu' | 'isActive'>>`
  a {
    align-items: center;
    color: ${({ theme }) => theme.colors.black};
    display: flex;
    flex-shrink: 0;
    font-size: 0.875rem;
    font-family: 'GolosRegular', sans-serif;
    height: 105px;
    justify-content: center;
    margin: 0 30px;
    text-decoration: none;
    transition: all 200ms ease-in-out;

    &:hover {
      color: ${({ theme }) => theme.colors.redPrimary};
    }

    &.nav-item__submenu--active {
      color: ${({ theme }) => theme.colors.redPrimary};
    }

    &.nav-item__submenu {
      position: relative;

      &:after {
        background: url(${({ isActive }) => (isActive ? iconNavClose : iconNavOpen)}) center center
          no-repeat;
        background-size: contain;
        content: '';
        display: block;
        height: 100%;
        margin-left: 20px;
        position: absolute;
        right: -17px;
        top: 0;
        transition: transform 0.3s;
        width: 10px;

        ${({ theme }) => theme.media.maxPhoneHorizontal`
          background: url(${({ isActive }) =>
            isActive ? iconNavCloseWhite : iconNavOpen}) center center
          no-repeat;
        `}
      }
    }
  }
`;
