import styled from 'styled-components';

export const CarouselControls = styled.div`
  &.carousel-controls {
    margin-top: 20px;
  }

  &.carousel-controls a {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    font-size: 0;
    margin: 0 4px;
    text-decoration: none;
    background-color: #ebebeb;
  }

  &.carousel-controls a:hover {
    background: #cdcdcd;
  }

  &.carousel-controls .active {
    background: #9b9b9b;
  }

  .insuranceType_swiper &.carousel-controls {
    float: left;
    margin-top: 28px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
  }

  @media only screen and (max-width: 768px) {
    &.carousel-controls {
      margin-top: 0;
    }
  }
`;
