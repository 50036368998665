import * as React from 'react';
import ScrollUpButtonLib from 'react-scroll-up-button';
import styled from 'styled-components';

import { iconScrollToTop } from 'utils/images';

interface IScrollUpButtonProps {
  ShowAtPosition: number;
  AnimationDuration?: number;
  rightPosition?: string;
  bottomPosition?: string;
}

export const ScrollUpButton: React.SFC<IScrollUpButtonProps> = ({
  ShowAtPosition,
  AnimationDuration,
  rightPosition,
  bottomPosition,
  ...props
}) => {
  return (
    <Container>
      <ScrollUpButtonLib
        ShowAtPosition={ShowAtPosition}
        AnimationDuration={AnimationDuration}
        ContainerClassName='scroll-to-up-container'
        TransitionClassName='scroll-to-up-transition'
        {...props}
      >
        <Icon
          src={iconScrollToTop}
          rightPosition={rightPosition}
          bottomPosition={bottomPosition}
          alt='scroll to top'
        />
      </ScrollUpButtonLib>
    </Container>
  );
};

ScrollUpButton.defaultProps = {
  ShowAtPosition: 500,
  AnimationDuration: 500,
};

const Container = styled.div`
  .scroll-to-up-container {
    display: none;
    opacity: 0;
  }

  .scroll-to-up-transition {
    display: block;
    opacity: 1;
    transition: all 0.5s;
  }
`;

const Icon = styled.img<Partial<IScrollUpButtonProps>>`
  position: fixed;
  z-index: 2000;
  background: white;
  border-radius: 50%;
  right: ${({ rightPosition }) => rightPosition || '20%'};
  bottom: ${({ bottomPosition }) => bottomPosition || '50px'};

  &:hover {
    cursor: pointer;
  }
`;
