import 'utils/polyfill.utils';

import React from 'react';
import ReactDOM from 'react-dom';

import { Router } from 'react-router-dom';
import { Provider } from 'react-redux';

import App from './App';
import { initSentry } from 'utils/sentry';
import { store } from 'store';
import { history } from 'routes';
import { checkBrowserFeatures } from 'utils';

initSentry(); // logging

checkBrowserFeatures();

const app = (
  <Provider store={store as any}>
    <Router history={history}>
      <App />
    </Router>
  </Provider>
);

ReactDOM.render(app, document.getElementById('root'));
