import React from 'react';

interface IOwnProps {
  index: number;
  handleClick: Function;
  current: number;
}

/**
 * Clickable point in slide
 */
export const Point: React.FC<IOwnProps> = ({ handleClick, index, current }) => (
  <a
    href='/'
    className={current === index ? 'active' : ''}
    onClick={e => {
      e.preventDefault();
      handleClick(index);
    }}
  >
    {index + 1}
  </a>
);
