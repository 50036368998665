import React from 'react';
import {
  unmaskTel,
  formatPhoneNumber,
  REGEX_PHONE_PATTERN,
  REGEX_CONTRACT_NUMBER_PATTERN,
  REGEX_FORBIDDEN_SPECIAL_CHARS_PATTERN,
  regexValidator,
  lengthValidator,
  maxLengthValidator,
  minLengthValidator,
  isBeforeDateValidator,
} from 'utils';
import translate from 'utils/translator';
import { DatePicker, MaskedInput, Select, Input } from 'components/atoms/form/';
import { capitalizeFirstLetter } from 'utils/string.utils';
import { toMomentDate, dateFormatServer } from 'utils/date.utils';

const insurancePeriodOptions = [
  { value: '', label: capitalizeFirstLetter(translate('all')) },
  { value: 1, label: capitalizeFirstLetter(translate('agent_product_selection_duration_1')) },
  { value: 12, label: capitalizeFirstLetter(translate('agent_product_selection_duration_12')) },
  { value: 36, label: capitalizeFirstLetter(translate('agent_product_selection_duration_36')) },
];

export const autoupdatePeriodOptions = [
  { value: '', label: capitalizeFirstLetter(translate('do_not_use_auto_update')) },
  { value: 1, label: `1 ${translate('minute')}` },
  { value: 2, label: `2 ${translate('minutes')}` },
  { value: 10, label: `10 ${translate('minutes_10')}` },
];

export const formLabels = {
  insuranceDate: translate('insurance_date'),
  paymentDate: translate('payment_date'),
  validityDate: translate('validity_date'),
  insurancePeriod: translate('term_of_insurance'),
  orderStatus: translate('order_status'),
  cellphone: translate('phone_number'),
  cardOrderId: translate('card_order_id'),
  contractNumber: translate('contract_number'),
  agentId: translate('personnel_number'),
  autoupdatePeriod: translate('set_filter_auto_update'),
};

const formConfig = {
  insuranceDateFrom: {
    component: ({ input, meta, ...props }) => <DatePicker {...input} {...meta} {...props} />,
    props: (minDateThresholdDay, insuranceDateTo) => ({
      name: 'insuranceDateFrom',
      placeholder: `${translate('insurance_date')} ${translate('from')}`,
      showMonthDropdown: true,
      showYearDropdown: true,
      dropdownMode: 'select',
      minDate: toMomentDate(minDateThresholdDay, dateFormatServer),
      maxDate: toMomentDate(insuranceDateTo),
    }),
    validator: [
      (value, allValues, props) =>
        isBeforeDateValidator(
          toMomentDate(value),
          toMomentDate(props.minDateThresholdDay, dateFormatServer).subtract(1, 'day'),
          'insuranceDateFrom'
        ),
    ],
  },
  insuranceDateTo: {
    component: ({ input, meta, ...props }) => <DatePicker {...input} {...meta} {...props} />,
    props: insuranceDateFrom => ({
      name: 'insuranceDateTo',
      placeholder: `${translate('insurance_date')} ${translate('to')}`,
      showMonthDropdown: true,
      showYearDropdown: true,
      dropdownMode: 'select',
      minDate: toMomentDate(insuranceDateFrom),
    }),
    validator: [
      (value, allValues, props) =>
        isBeforeDateValidator(
          toMomentDate(value),
          toMomentDate(props.formValues.insuranceDateFrom, dateFormatServer).subtract(1, 'day'),
          'insuranceDateTo'
        ),
    ],
  },
  paymentDateFrom: {
    component: ({ input, meta, ...props }) => <DatePicker {...input} {...meta} {...props} />,
    props: {
      name: 'paymentDateFrom',
      placeholder: `${translate('payment_date')} ${translate('from')}`,
      value: null,
      showMonthDropdown: true,
      showYearDropdown: true,
      dropdownMode: 'select',
    },
  },
  paymentDateTo: {
    component: ({ input, meta, ...props }) => <DatePicker {...input} {...meta} {...props} />,
    props: {
      name: 'paymentDateTo',
      placeholder: `${translate('payment_date')} ${translate('to')}`,
      value: null,
      showMonthDropdown: true,
      showYearDropdown: true,
      dropdownMode: 'select',
    },
  },
  validityDateFrom: {
    component: ({ input, meta, ...props }) => <DatePicker {...input} {...meta} {...props} />,
    props: {
      name: 'validityDateFrom',
      placeholder: `${translate('validity_date')} ${translate('from')}`,
      value: null,
      showMonthDropdown: true,
      showYearDropdown: true,
      dropdownMode: 'select',
    },
  },
  validityDateTo: {
    component: ({ input, meta, ...props }) => <DatePicker {...input} {...meta} {...props} />,
    props: {
      name: 'validityDateTo',
      placeholder: `${translate('validity_date')} ${translate('to')}`,
      value: null,
      showMonthDropdown: true,
      showYearDropdown: true,
      dropdownMode: 'select',
    },
  },
  insurancePeriod: {
    component: ({ input, meta, ...props }) => (
      <Select {...input} {...meta} options={insurancePeriodOptions} {...props} />
    ),
    props: {
      name: 'insurancePeriod',
      type: 'select',
      layout: 'vertical',
      placeholder: translate('term_of_insurance'),
      defaultValue: insurancePeriodOptions[0],
      isSearchable: false,
      fontSize: '125%',
    },
  },
  orderStatus: {
    component: ({ input, meta, ...props }) => <Select {...input} {...meta} {...props} />,
    props: {
      name: 'orderStatus',
      type: 'select',
      placeholder: translate('order_status'),
      isSearchable: false,
      layout: 'vertical',
      isMulti: true,
      fontSize: '125%',
    },
  },
  phcellphone: {
    component: ({ input, meta: { touched, error }, ...props }) => (
      <MaskedInput {...input} touched={touched} error={error} {...props} />
    ),
    props: {
      unmask: unmaskTel,
      name: 'phcellphone',
      type: 'inputMask',
      placeholder: translate('mobile_phone'),
      value: ' ',
      mask: '+7 (999) 999-99-99',
      formatter: formatPhoneNumber,
    },
    validator: [value => value && regexValidator(REGEX_PHONE_PATTERN, value, 'phcellphone')],
  },
  cardOrderId: {
    component: ({ input, meta, ...props }) => <Input {...input} {...meta} {...props} />,
    props: {
      name: 'cardOrderId',
      type: 'number',
      placeholder: translate('card_order_id'),
      value: '',
    },
    validator: [
      value => value && minLengthValidator(value, 1, 'cardOrderId'),
      value => value && maxLengthValidator(value, 11, 'cardOrderId'),
    ],
  },
  contractNumber: {
    component: ({ input, meta, ...props }) => <Input {...input} {...meta} {...props} />,
    props: {
      name: 'contractNumber',
      type: 'number',
      placeholder: translate('contract_number'),
      value: '',
      minLength: 10,
      maxLength: 10,
    },
    validator: [
      value => value && regexValidator(REGEX_CONTRACT_NUMBER_PATTERN, value, 'contractNumber'),
      value => value && lengthValidator(value, 10, 'contractNumber'),
    ],
  },
  agentId: {
    component: ({ input, meta, ...props }) => <Input {...input} {...meta} {...props} />,
    props: {
      name: 'agentId',
      type: 'text',
      placeholder: translate('agent_id'),
      value: '',
    },
    validator: [
      value => maxLengthValidator(value, 20, 'agentId'),
      value =>
        regexValidator(
          REGEX_FORBIDDEN_SPECIAL_CHARS_PATTERN,
          value,
          'forbidden_special_characters',
          'gi'
        ),
    ],
  },
  autoupdatePeriod: {
    component: ({ input, meta, ...props }) => (
      <Select {...input} {...meta} options={autoupdatePeriodOptions} {...props} />
    ),
    props: {
      defaultValue: autoupdatePeriodOptions[2],
      id: 'sales-filter-auto-update',
      isSearchable: false,
      layout: 'vertical',
      name: 'autoupdatePeriod',
      placeholder: translate('do_not_use_auto_update'),
      type: 'select',
      fontSize: '125%',
    },
  },
};

export { formConfig };
