import { createLogger } from 'redux-logger';
import { isLocalOrDevEnv } from 'utils/env';

export const logger = createLogger({
  collapsed: true,
  duration: true,
  predicate: (getState, action) => {
    if (!(window as any).Cypress && isLocalOrDevEnv) {
      if (action.type.includes('@@redux-form')) return false;
      return true;
    }
    return false;
  },
});
