import React from 'react';
import styled from 'styled-components';

type Props = {
  children: React.ReactNode;
};

export const Navigation = ({ children }: Props) => <Container>{children}</Container>;

/**
 * Styled components
 */

const Container = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  @media (max-width: 1399px) {
    display: none;
  }

  /* ${({ theme }) => theme.media.maxTabletHorizontal`
    display: none;
  `} */
`;
