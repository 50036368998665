import React from 'react';
import { connect } from 'react-redux';
import moment from 'moment';
import { Flex, Box } from '@rebass/grid';
import { Field, Form, reduxForm, getFormMeta, InjectedFormProps } from 'redux-form';
import { ErrorMessage } from 'components/atoms/ErrorMessage';

import { formConfig, formLabels } from './SalesFilterForm.config';
import { validators } from './SalesFilterForm.validators';
import translate from 'utils/translator';
import { noop } from 'utils/common.utils';
import { AUTH_TYPE } from 'components/organisms/agent/constants';
import { resetSalesFilterCountdown } from 'components/organisms/agent/redux/agent.actions';
import { dateFormat } from 'utils';
import { ShowIf } from 'components/atoms/ShowIf';

import * as S from './SalesFilterForm.styles';

import { IAgentReducer, ObjectGeneric } from 'types';

interface IOwnProps {}

interface IStateDispatchProps {
  resetSalesFilterCountdown: typeof resetSalesFilterCountdown;
}

interface IInjectedProps {
  error: ObjectGeneric;
}

interface IStateProps {
  agentId: IAgentReducer['agentId'];
  authType: IAgentReducer['authType'];
  orderStatuses: IAgentReducer['orderStatuses'];
  pastMonthsAllowed: IAgentReducer['pastMonthsAllowed'];
  minDateThresholdDay: IAgentReducer['minDateThresholdDay'];
  formFields: any;
  formValues: any;
}

type IProps = InjectedFormProps<any> &
  IInjectedProps &
  IStateProps &
  IStateDispatchProps &
  IOwnProps;

class SalesFilterFormComponent extends React.PureComponent<IProps> {
  render() {
    const {
      error,
      formFields,
      authType,
      agentId,
      orderStatuses,
      minDateThresholdDay,
      formValues,
    } = this.props;

    return (
      <Flex flexDirection='column' width={1}>
        <Form onSubmit={noop}>
          {/* insuranceDate */}
          <S.FormRow>
            <Box width='30%'>
              <S.Label
                htmlFor={
                  formConfig.insuranceDateFrom.props(
                    minDateThresholdDay,
                    formValues?.insuranceDateTo
                  ).name
                }
              >
                {translate(formLabels.insuranceDate)}
              </S.Label>
            </Box>
            <Box width='5%'>
              <S.Label
                htmlFor={
                  formConfig.insuranceDateFrom.props(
                    minDateThresholdDay,
                    formValues?.insuranceDateTo
                  ).name
                }
                textAlign='center'
              >
                {translate('from')}
              </S.Label>
            </Box>
            <Box width='30%'>
              <Field
                {...formConfig.insuranceDateFrom.props(
                  minDateThresholdDay,
                  formValues?.insuranceDateTo
                )}
                component={formConfig.insuranceDateFrom.component}
                validate={formConfig.insuranceDateFrom.validator}
                disabled={!agentId}
              />
            </Box>
            <Box width='5%'>
              <S.Label
                htmlFor={formConfig.insuranceDateTo.props(formValues?.insuranceDateFrom).name}
                textAlign='center'
              >
                {translate('to')}
              </S.Label>
            </Box>
            <Box width='30%'>
              <Field
                {...formConfig.insuranceDateTo.props(formValues?.insuranceDateFrom)}
                component={formConfig.insuranceDateTo.component}
                disabled={!agentId}
              />
            </Box>
          </S.FormRow>

          <ShowIf
            condition={
              ((formFields?.insuranceDateFrom && formFields?.insuranceDateFrom.touched) ||
                (formFields?.insuranceDateTo && formFields?.insuranceDateTo.touched)) &&
              error?.incorrectInsuranceDatesError
            }
          >
            <Flex width={1} justifyContent='flex-end'>
              <ErrorMessage width='65%'>{error?.incorrectInsuranceDatesError}</ErrorMessage>
            </Flex>
          </ShowIf>

          {/* paymentDate */}
          <S.FormRow>
            <Box width='30%'>
              <S.Label htmlFor={formConfig.paymentDateFrom.props.name}>
                {translate(formLabels.paymentDate)}
              </S.Label>
            </Box>
            <Box width='5%'>
              <S.Label htmlFor={formConfig.paymentDateFrom.props.name} textAlign='center'>
                {translate('from')}
              </S.Label>
            </Box>
            <Box width='30%'>
              <Field
                {...formConfig.paymentDateFrom.props}
                component={formConfig.paymentDateFrom.component}
                disabled={!agentId}
              />
            </Box>
            <Box width='5%'>
              <S.Label htmlFor={formConfig.paymentDateTo.props.name} textAlign='center'>
                {translate('to')}
              </S.Label>
            </Box>
            <Box width='30%'>
              <Field
                {...formConfig.paymentDateTo.props}
                component={formConfig.paymentDateTo.component}
                disabled={!agentId}
              />
            </Box>
          </S.FormRow>

          <ShowIf
            condition={
              ((formFields?.paymentDateFrom && formFields?.paymentDateFrom.touched) ||
                (formFields?.paymentDateTo && formFields?.paymentDateTo.touched)) &&
              error?.incorrectPaymentDatesError
            }
          >
            <Flex width={1} justifyContent='flex-end'>
              <ErrorMessage width='65%'>{error?.incorrectPaymentDatesError}</ErrorMessage>
            </Flex>
          </ShowIf>

          {/* validityDate */}
          <S.FormRow>
            <Box width='30%'>
              <S.Label htmlFor={formConfig.validityDateFrom.props.name}>
                {translate(formLabels.validityDate)}
              </S.Label>
            </Box>

            <Box width='5%'>
              <S.Label htmlFor={formConfig.validityDateFrom.props.name} textAlign='center'>
                {translate('from')}
              </S.Label>
            </Box>

            <Box width='30%'>
              <Field
                {...formConfig.validityDateFrom.props}
                component={formConfig.validityDateFrom.component}
                disabled={!agentId}
              />
            </Box>

            <Box width='5%'>
              <S.Label htmlFor={formConfig.validityDateTo.props.name} textAlign='center'>
                {translate('to')}
              </S.Label>
            </Box>

            <Box width='30%'>
              <Field
                {...formConfig.validityDateTo.props}
                component={formConfig.validityDateTo.component}
                disabled={!agentId}
              />
            </Box>
          </S.FormRow>

          <ShowIf
            condition={
              ((formFields?.validityDateFrom && formFields?.validityDateFrom.touched) ||
                (formFields?.validityDateTo && formFields?.validityDateTo.touched)) &&
              error?.incorrectValidityDatesError
            }
          >
            <Flex width={1} justifyContent='flex-end'>
              <ErrorMessage width='65%'>{error?.incorrectValidityDatesError}</ErrorMessage>
            </Flex>
          </ShowIf>

          <S.FormRow>
            {/* insurancePeriod */}
            <Flex width='30%' flexDirection='column'>
              <Field
                {...formConfig.insurancePeriod.props}
                label={formLabels.insurancePeriod}
                component={formConfig.insurancePeriod.component}
                isDisabled={!agentId}
              />
            </Flex>

            {/* Gap */}
            <Box width='5%' />

            {/* orderStatus */}
            <Box width='30%'>
              <Field
                {...formConfig.orderStatus.props}
                label={formLabels.orderStatus}
                component={formConfig.orderStatus.component}
                options={orderStatuses || []}
                isDisabled={!agentId}
              />
            </Box>

            {/* Gap */}
            <Box width='5%' />

            {/* phcellphone */}
            <Box width='30%'>
              <Field
                {...formConfig.phcellphone.props}
                label={formLabels.cellphone}
                component={formConfig.phcellphone.component}
                validate={formConfig.phcellphone.validator}
                disabled={!agentId}
              />
            </Box>
          </S.FormRow>

          <S.FormRow>
            {/* cardOrderId */}
            <Box width='30%'>
              <Field
                {...formConfig.cardOrderId.props}
                label={formLabels.cardOrderId}
                component={formConfig.cardOrderId.component}
                validate={formConfig.cardOrderId.validator}
                disabled={!agentId}
              />
            </Box>

            {/* Gap */}
            <Box width='5%' />

            {/* contractNumber */}
            <Box width='30%'>
              <Field
                {...formConfig.contractNumber.props}
                label={formLabels.contractNumber}
                component={formConfig.contractNumber.component}
                validate={formConfig.contractNumber.validator}
                disabled={!agentId}
              />
            </Box>

            {/* Gap */}
            <Box width='5%' />

            {/* agentId */}
            <Box width='30%'>
              <Field
                {...formConfig.agentId.props}
                label={formLabels.agentId}
                component={formConfig.agentId.component}
                validate={formConfig.agentId.validator}
                disabled={authType === AUTH_TYPE.AGENT || !agentId}
              />
            </Box>
          </S.FormRow>

          <S.FormRow>
            {/* autoupdatePeriod */}
            <Flex width='30%' flexDirection='column'>
              <Field
                onChange={() => this.props.resetSalesFilterCountdown()}
                {...formConfig.autoupdatePeriod.props}
                label={formLabels.autoupdatePeriod}
                component={formConfig.autoupdatePeriod.component}
                isDisabled={!agentId}
              />
            </Flex>
          </S.FormRow>
        </Form>
      </Flex>
    );
  }
}

const mapStateToProps = state => {
  return {
    agentId: state.agent.agentId,
    authType: state.agent.authType,
    orderStatuses: state.agent.orderStatuses,
    pastMonthsAllowed: state.agent.pastMonthsAllowed,
    minDateThresholdDay: state.agent.minDateThresholdDay,
    formFields: getFormMeta('agentSalesFilterForm')(state),
    formValues: state.form.agentSalesFilterForm && state.form.agentSalesFilterForm.values,
  };
};

const now = moment();
const yesterday = now.clone().subtract(1, 'days').startOf('day');

export const SalesFilterFormContainer = connect<IStateProps, IStateDispatchProps, IOwnProps>(
  mapStateToProps,
  { resetSalesFilterCountdown }
)(
  reduxForm({
    form: 'agentSalesFilterForm',
    validate: validators,
    enableReinitialize: true,
    initialValues: {
      autoupdatePeriod: formConfig.autoupdatePeriod.props.defaultValue,
      insuranceDateFrom: yesterday.format(dateFormat),
      insuranceDateTo: now.format(dateFormat),
    },
  })(SalesFilterFormComponent)
);
