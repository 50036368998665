import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { theme } from 'styles';
import translate from 'utils/translator';
import { GetInputPropsFn, GetRootPropsFn } from 'react-dropzone';

interface IDropzoneInputProps {
  getRootProps: GetRootPropsFn;
  getInputProps: GetInputPropsFn;
  dropzoneText?: string;
  disabled?: boolean;
}

export const DropzoneInput: React.SFC<IDropzoneInputProps> = ({
  getRootProps,
  getInputProps,
  dropzoneText,
  disabled,
  ...props
}) => (
  <div {...getRootProps()} {...props}>
    <Container alignItems='center' {...getRootProps()} disabled={disabled}>
      <input {...getInputProps()} />
      <Box>
        {dropzoneText ? (
          dropzoneText
        ) : (
          <DropzoneText>
            {translate('drag_and_drop_file_here_or_click_to_select_files')}
          </DropzoneText>
        )}
      </Box>
    </Container>
  </div>
);

const Container = styled(Flex)`
  background: ${({ disabled }) => (disabled ? '#b9b9b9' : '#f0f4f8')};
  border-radius: ${({ theme }) => theme.radii.box};
  border: 1px solid #f0f4f8;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.textPrimary};
  cursor: ${({ disabled }) => (disabled ? 'no-drop' : 'pointer')};
  font-size: 125%;
  margin: 10px 0 0;
  padding: 10px;
  transition: 0.4s;
  width: 100%;
`;

const DropzoneText = styled.span`
  color: ${theme.colors.textPrimary};
`;
