import styled from 'styled-components';
import { imgProlongIco } from 'utils/images';

interface IICoProps {
  size?: string;
  src?: string;
  srcWebp?: string;
}

export const Ico = styled.div<IICoProps>`
  background-size: cover;
  height: ${({ size }) => (size ? size : '57px')};
  width: ${({ size }) => (size ? size : '57px')};
  background-image: url(${({ srcWebp, theme }) => (srcWebp ? srcWebp : imgProlongIco)});
  ${props => props.theme.media.phone`
    height: 57px;
    width: 57px;
  `}
  html.no-webp & {
    background-image: url(${({ src, theme }) => (src ? src : imgProlongIco)});
  }
`;
