import React from 'react';
import styled, { css } from 'styled-components';

import { FnClick } from 'types';

interface IListLinkProps {
  href?: string;
  children: React.ReactNode;
  active?: boolean;
  size?: string;
  onClick?: FnClick;
}

export const ListLink: React.SFC<IListLinkProps> = ({ children, active, href, size, ...props }) => {
  const LinkComponent: React.ElementType = href ? NormalLink : FakeLink;
  return (
    <LinkComponent active={active} size={size} {...props}>
      {children}
    </LinkComponent>
  );
};

ListLink.defaultProps = {
  size: 'normal',
};

const linkStyles = css<IListLinkProps>`
  box-sizing: border-box;
  border-bottom: 1px solid ${({ active }) => (active ? '#151d2b' : 'transparent')};
  color: ${({ active }) => (active ? '#151d2b' : '#858585')};
  cursor: pointer;
  display: block;
  font-weight: ${({ active }) => (active ? 'bold' : 'normal')};
  height: ${({ size }) => (size === 'big' ? '90px' : 'auto')};
  line-height: ${({ size }) => (size === 'big' ? '90px' : 1.4)};
  outline: none;
  position: relative;
  text-decoration: none;
  text-transform: uppercase;
  top: 1px;
  transition: ${props => props.theme.transitions.duration};
  z-index: 1;

  &:hover {
    color: #151d2b;
  }
`;

const FakeLink = styled.span`
  ${linkStyles}
`;

const NormalLink = styled.a`
  ${linkStyles}
`;
