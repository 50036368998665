import { head } from 'lodash-es';
import { sendPromo } from 'rest/api';
import translate from 'utils/translator';
import { getOptionDurationsByTplId } from 'components/organisms/insurance/utils/order.helpers';

// ensure correct selected product duration for disctounts that are only for 12 months durations
// #400
const setProductDuration = (
  selectedProduct,
  products,
  tplId,
  actionSetProductDuration,
  selectedProductDuration
) => {
  const optionDurations = getOptionDurationsByTplId(selectedProduct, products, tplId);
  const minAvailableDuration = head(optionDurations);
  if (minAvailableDuration !== selectedProductDuration) {
    actionSetProductDuration(minAvailableDuration);
  }
};

export const handleClickSendPromoCode = (c, customType = null) => {
  c.props.actionShowPreloader();
  const type = customType ? customType : c.state.type;
  return sendPromo(c.state.promoCode, type)
    .then(response => {
      c.props.actionSetDiscount(
        response.data.tpl_id,
        c.state.promoCode,
        response.data.tpl_strikethrough_sum_insured,
        response.data.tpl_strikethrough_premium,
        response.data.tpc_label
      );
      c.setState({
        promoCodeError: null,
      });
      setProductDuration(
        customType,
        c.props.products?.data,
        response?.data?.tpl_id,
        c.props.actionSetProductDuration,
        c.props.productDuration
      );
      c.props.actionHidePreloader();
    })
    .catch(error => {
      c.props.actionSetDiscount(0);
      c.props.actionHidePreloader();
      if (error.response && error.response.status === 400) {
        c.setState({
          promoCodeError: translate(
            'error_promocode_' +
              (error.response.data.error ? error.response.data.error.message : 'emptyMessage')
          ),
        });
      } else {
        throw error;
      }
    });
};
