import { restFias } from './rest';
import { createNameSuggestionFromInput } from 'components/organisms/insurance/utils/helpers';
import { getProp } from 'utils/object.utils';
import { isNumber } from 'utils/number.utils';
import { log } from 'utils';

import { IOrder } from 'types';

export const getAddressSuggestions = async (input: string) => {
  try {
    const out = await restFias.post(
      '/address',
      {
        query: input,
        count: 15,
      },
      {}
    );
    const dataOut = [] as Partial<IOrder>[];
    out?.data?.data?.suggestions?.map(dataOuter => {
      const data = dataOuter.data;
      if (data.flat != null) {
        log.debug('getAddressSuggestions:: data: ', data);
        return undefined;
      }
      dataOut.push({
        phhousefiasid: data.house_fias_id,
        phfiascode: data.fias_id,
        phregion: data.region_with_type,
        phdistrict: data.city_district_with_type,
        phcity: data.city_with_type,
        phpoint: data.settlement_with_type,
        phstreet: data.street_with_type,
        phentrance: data.house,
        phcorpus: data.block_type && data.block && `${data.block_type} ${data.block}`,
        phpostindex: data.postal_code,
        phfulladdress: dataOuter.value,
      });
      return undefined;
    });
    return dataOut;
  } catch (err) {
    log.error('getAddressSuggestions:: error: ', err);
  }
};

export const getNameSuggestions = async (name: string) => {
  try {
    const query = { query: name, count: 15 };
    const reqConfig = {};
    const response = await restFias.post('/fio', query, reqConfig);
    const result = (response && response.data.data && response.data.data.suggestions) || [];
    return result;
  } catch (err) {
    // in case of API unavailability parse input value
    // and return it as a suggestion
    const apiUnavailableError = err && !err.response;
    const statusCode = getProp(err, 'response.status');
    const errorCodes = [400, 401, 403, 405, 429, 500, 502, 503]; // https://dadata.ru/api/suggest/name/#return
    const isApiError = statusCode && isNumber(statusCode) && errorCodes.indexOf(statusCode) > -1;
    if (apiUnavailableError || isApiError) {
      const suggestion = createNameSuggestionFromInput(name);
      return Promise.resolve(suggestion);
    }
    log.error('getNameSuggestions:: error: ', err);
    return err;
  }
};

export const getEmailSuggestions = async (email: string) => {
  try {
    const query = { query: email, count: 15 };
    const reqConfig = {};
    const response = await restFias.post('/email', query, reqConfig);
    const result = (response && response.data.data && response.data.data.suggestions) || [];
    return result;
  } catch (err) {
    log.error('getEmailSuggestions:: error: ', err);
    return err;
  }
};

export const getPartSuggestions = async (name: string) => {
  try {
    const query = { query: name, count: 15 };
    const reqConfig = {};
    const response = await restFias.post('/party', query, reqConfig);
    const result = (response && response.data.data && response.data.data.suggestions) || [];

    return result;
  } catch (err) {
    log.error('getPartSuggestions:: error: ', err);
  }
};

export default {
  getAddressSuggestions,
  getNameSuggestions,
  getPartSuggestions,
  getEmailSuggestions,
  restFias,
};
