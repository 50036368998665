import thunk from 'redux-thunk';
import { createStore, applyMiddleware, compose } from 'redux';

import { logger } from 'store/middlewares';
import { rootReducer } from './reducers/root.reducer';

export const configureStore = () => {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const loggerMiddleware = logger;

  const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk, loggerMiddleware))
  );

  return store;
};

export const store = configureStore();
