import { css } from 'styled-components';

export const forwardPlaceholderStyles = css`
  *::-webkit-input-placeholder {
    font-size: 1rem !important;
  }

  *:-moz-placeholder {
    font-size: 1rem !important;
  }

  *::-moz-placeholder {
    font-size: 1rem !important;
  }

  *:-ms-input-placeholder {
    font-size: 1rem !important;
  }

  *::-ms-input-placeholder {
    font-size: 1rem !important;
  }

  *::placeholder {
    font-size: 1rem !important;
  }
`;
