import { HamburgerMenuButton } from 'components/atoms/HamburgerMenuButton';
import React, { useRef } from 'react';
import styled, { css } from 'styled-components';
import { theme } from 'styles';
import { useHandleClickOutside } from 'utils/hooks/use-handle-click-outside';

import { FnWithArgs } from 'types';

interface INavigationMenuProps {
  children: React.ReactNode;
  toggleMenuAction: FnWithArgs;
  closeMenuAction: FnWithArgs;
  isOpen: boolean;
  enableBreakpoints?: boolean; // no breakpoints logic needed in agent
}

export const NavigationMenu: React.FC<INavigationMenuProps> = ({
  children,
  isOpen,
  toggleMenuAction,
  closeMenuAction,
  enableBreakpoints = true,
}) => {
  const menuContainerRef = useRef();
  const menuButtonRef = useRef();

  useHandleClickOutside({
    refs: [menuContainerRef, menuButtonRef],
    handler: closeMenuAction,
  });

  return (
    <>
      <ButtonWrapper enableBreakpoints={enableBreakpoints}>
        <HamburgerMenuButton isOpen={isOpen} onClick={toggleMenuAction} ref={menuButtonRef} />
      </ButtonWrapper>
      {isOpen && (
        <MenuContainer ref={menuContainerRef as any} enableBreakpoints={enableBreakpoints}>
          {children}
        </MenuContainer>
      )}
    </>
  );
};

const ButtonWrapper = styled.div<Partial<INavigationMenuProps>>`
  display: ${({ enableBreakpoints }) => (enableBreakpoints ? 'none' : 'flex')};
  position: relative;
  justify-content: center;
  align-items: center;

  ${({ theme }) => theme.media.maxTabletHorizontal`
    display: flex;
    height: 100%;
  `}

  ${({ enableBreakpoints }) =>
    enableBreakpoints &&
    css`
      ${({ theme }) => theme.media.maxTabletHorizontal`
        display: flex;
        height: 100%;
      `}
    `};
`;

const MenuContainer = styled.nav<Partial<INavigationMenuProps>>`
  display: ${({ enableBreakpoints }) => (enableBreakpoints ? 'none' : 'flex')};
  position: absolute;
  flex-direction: column;
  bottom: 0;
  left: 0;
  width: 100%;
  background-color: ${theme.colors.white};
  box-sizing: border-box;
  transform: translateY(100%);
  z-index: 2150;
  border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};

  ${({ theme }) => theme.media.onlyDesktop`
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  `}

  ${({ enableBreakpoints }) =>
    enableBreakpoints &&
    css`
      ${({ theme }) => theme.media.maxTabletHorizontal`
        display: flex;
      `}
    `};
`;
