import React from 'react';

import { Heading, THeadingProps } from 'components/atoms/Heading';
import { Column, IColumnProps } from 'components/atoms/Column';
import { Row } from 'components/atoms/Row';

import translate from 'utils/translator';

interface IFormSectionProps extends IColumnProps {
  title?: string;
  headingProps?: THeadingProps;
  children: React.ReactNode;
  headingComponent?: React.ReactNode | null;
}

export const FormSection: React.FC<IFormSectionProps> = ({
  children,
  title,
  headingProps,
  headingComponent,
  ...props
}) => (
  <Column mb={5} {...props}>
    {title && (
      <Row alignItems='center'>
        <Heading level={4} margin='0 0 5px 0' {...headingProps}>
          {translate(title)}
        </Heading>
        {headingComponent && (
          <Column mx={2} alignItems='center'>
            {headingComponent}
          </Column>
        )}
      </Row>
    )}
    {children}
  </Column>
);
