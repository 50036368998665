import React from 'react';
import styled from 'styled-components/macro';

import { Row } from 'components/atoms/Row';
import { LinkProlong } from '../LinkProlong';
import { LinkClientPortal } from '../LinkClientPortal';

interface ISecondaryNavProps {
  search: string;
}

export const SecondaryNav: React.FC<ISecondaryNavProps> = ({ search }) => (
  <Container alignItems='center'>
    <LinkProlong />
    <LinkClientPortal />
  </Container>
);

const Container = styled(Row)`
  display: none;

  ${({ theme }) => theme.media.onlyDesktop`
    display: flex;
  `}
`;
