import { css } from 'styled-components';

export const autosuggestStyles = css<{ touched: boolean; error: string }>`
  & .react-autosuggest__suggestion:hover {
    cursor: pointer;
  }

  & .react-autosuggest__suggestion {
    padding-left: 10px;
  }

  & .react-autosuggest__suggestion--highlighted {
    background-color: #f0f4f8;
  }

  & .react-autosuggest__suggestions-container--open {
    border: 1px solid #f0f4f8;
  }

  ${({ error, touched }) =>
    error &&
    touched &&
    css`
      & .react-autosuggest__input {
        border: 1px solid #e7372c !important;
      }
    `};

  & .react-autosuggest__suggestions-list {
    margin: 0;
  }

  & .react-autosuggest__container {
    position: relative;
    z-index: 1;
  }
`;
