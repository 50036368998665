import * as CONSTANTS from './zabolel.constants';
import { getProp } from 'utils/object.utils';
import { IZabolelReducer } from 'types';

const initialState: IZabolelReducer = {
  createAnnouncement: null,
  createAnnouncementIsLoading: false,
  createAnnouncementError: null,
  verifySmsCode: null,
  verifySmsCodeIsLoading: false,
  verifySmsCodeError: null,
  verifySmsCodeAttempsCount: 0,
  suggestionFullName: null,
  suggestionEmployee: null,
  isPersonalDataLinkClicked: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.CREATE_ANNOUNCEMENT:
      return {
        ...state,
        createAnnouncementIsLoading: true,
      };
    case CONSTANTS.CREATE_ANNOUNCEMENT_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        createAnnouncement: data,
        createAnnouncementIsLoading: false,
        createAnnouncementError: null,
      };
    }
    case CONSTANTS.CREATE_ANNOUNCEMENT_FAIL: {
      const errorMsg =
        getProp(action, 'data.response.data.error.message', '') ||
        getProp(action, 'data.response.data.status', '');
      return {
        ...state,
        createAnnouncement: initialState.createAnnouncement,
        createAnnouncementIsLoading: false,
        createAnnouncementError: errorMsg,
      };
    }
    case CONSTANTS.VERIFY_SMS_CODE:
      return {
        ...state,
        verifySmsCodeIsLoading: true,
      };
    case CONSTANTS.VERIFY_SMS_CODE_SUCCESS: {
      const data = action.data;
      return {
        ...state,
        verifySmsCode: data,
        verifySmsCodeIsLoading: false,
        verifySmsCodeError: null,
      };
    }
    case CONSTANTS.VERIFY_SMS_CODE_FAIL: {
      const errorMsg =
        getProp(action, 'data.response.data.error.message', '') ||
        getProp(action, 'data.response.data.status', '');
      return {
        ...state,
        verifySmsCode: initialState.verifySmsCode,
        verifySmsCodeIsLoading: false,
        verifySmsCodeError: errorMsg,
      };
    }
    case CONSTANTS.VERIFY_SMS_CODE_ATTEMPTS_COUNT:
      return {
        ...state,
        verifySmsCodeAttempsCount: ++state.verifySmsCodeAttempsCount,
      };
    case CONSTANTS.SET_FULLNAME_SUGGESTION: {
      const { data } = action;
      return {
        ...state,
        suggestionFullName: data,
      };
    }
    case CONSTANTS.SET_EMPLOYEE_SUGGESTION: {
      const { data } = action;
      return {
        ...state,
        suggestionEmployee: data,
      };
    }
    case CONSTANTS.SET_IS_PERSONAL_DATA_LINK_CLICKED: {
      const { data } = action;
      return {
        ...state,
        isPersonalDataLinkClicked: data,
      };
    }

    default:
      return state;
  }
};
