import * as CONSTANTS from './products.constants';
import { IInsuranceProductsReducer } from 'types';

const initialState: IInsuranceProductsReducer = {
  data: null,
};

export const insuranceProducts = (state = initialState, action) => {
  if (action.type === CONSTANTS.INSURANCE_PRODUCTS_SET) {
    return {
      data: action.products,
    };
  } else if (action.type === CONSTANTS.INSURANCE_PRODUCTS_FETCH_FAILED) {
    return {
      error: action.error ? action.error : 'error',
    };
  } else if (action.type === CONSTANTS.INSURANCE_PRODUCTS_FETCH_START) {
    return { ...state, start: true };
  }
  return state;
};
