import { createGlobalStyle } from 'styled-components';
import { resetStyles } from 'styles/reset.styles';
import { commonStylesLegacy } from './common-legacy.styles';
import { commonStyles } from 'styles/common.styles';
import { accessibilityStyles } from 'styles/accessibility.styles';
import { fontsStyles, fontsStylesGolos } from 'styles/fonts.styles';
import { datepickerStyles } from 'styles/datepicker.styles';
import { responsiveLegacyStyles } from 'styles/responsive-legacy.styles';
import { placeholderStyles } from './placeholder.styles';

export const GlobalStyle = createGlobalStyle`
  ${fontsStyles};
  ${fontsStylesGolos};
  ${resetStyles};
  ${commonStyles};
  ${commonStylesLegacy};
  ${placeholderStyles};
  ${datepickerStyles};
  ${responsiveLegacyStyles};
  ${accessibilityStyles};
`;
