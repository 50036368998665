import React, { useState } from 'react';
import styled from 'styled-components';

import { iconNavOpen, iconNavCloseWhite } from 'utils/images';

interface IOwnProps {
  children: React.ReactNode;
  name: string;
  isSubmenuItem?: boolean;
  level?: 1 | 2;
}

export const NavigationSubMenu: React.FC<IOwnProps> = ({
  children,
  name,
  isSubmenuItem = false,
  level = 2,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Container isOpen={isOpen}>
      <ToggleButton onClick={() => setIsOpen(state => !state)} level={level} isOpen={isOpen}>
        <ToggleButtonText isOpen={isOpen} isSubmenuItem={isSubmenuItem} level={level}>
          {name}
        </ToggleButtonText>
      </ToggleButton>
      {isOpen && <SubmenuContainer isOpen={isOpen}>{children}</SubmenuContainer>}
    </Container>
  );
};

/**
 * Styled components
 */

const Container = styled.div<{ isOpen: boolean }>`
  position: relative;
  width: 100%;
`;

const SubmenuContainer = styled.div<{ isOpen: boolean }>`
  margin: ${({ isOpen }) => (isOpen ? '0 0 15px 0' : 0)};

  ${({ theme }) => theme.media.maxTabletHorizontal`
    margin: 0;
  `}
`;

const ToggleButton = styled.button<{ level: IOwnProps['level']; isOpen: boolean }>`
  background: ${({ isOpen, theme }) => (isOpen ? theme.colors.redPrimary : 'transparent')};
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  display: inline-block;
  line-height: 77px;
  margin: 0;
  padding: 0 20px 0 0;
  position: relative;
  text-align: left;
  width: 100%;
`;

const ToggleButtonText = styled.span<{
  isOpen: boolean;
  isSubmenuItem?: boolean;
  level?: IOwnProps['level'];
}>`
  color: ${({ isOpen, theme }) => (isOpen ? theme.colors.whiteAlt : theme.colors.black)};
  text-decoration: none;
  font-size: 1.125rem;
  width: 100%;
  font-family: ${({ level }) => (level === 1 ? 'GolosMedium' : 'GolosRegular')};
  line-height: inherit;
  padding-left: 15px;
  position: relative;

  @media only screen and (max-width: 768px) {
    display: inline;
    position: relative;
  }

  &:after {
    background: url(${({ isOpen }) => (isOpen ? iconNavCloseWhite : iconNavOpen)}) center center
      no-repeat;
    background-size: contain;
    content: '';
    display: block;
    height: 100%;
    margin-left: 0;
    position: absolute;
    right: -38px;
    top: 1px;
    transition: transform 0.3s;
    width: 16px;
  }

  ${({ theme }) => theme.media.minPhone`
    padding-left: 20px;
  `}
`;
