import { AxiosResponse } from 'axios';

import { ENV as Environments, getEnv } from 'utils/env';
import { log } from 'utils/logger';

const ENV = process.env.NODE_ENV;
const TEST = 'test';
const DELAY = ENV === TEST ? 0 : 1000;

export const getApiBaseUrl = () => {
  if (process.env.REACT_APP_API_BASE_URL) return process.env.REACT_APP_API_BASE_URL;
  const environment = getEnv();
  switch (environment) {
    case Environments.local:
      return 'http://10.20.1.140:8080/backend/rest';
    default:
      return '/backend/rest';
  }
};

export const getClientPortalDadataApiBaseUrl = () => {
  const environment = getEnv();
  switch (environment) {
    case Environments.local:
      return 'http://10.20.1.140/clientportal/portal/rest/dadata';
    case Environments.test:
      return 'http://10.20.1.140:8083/portal/rest/dadata';
    default:
      return '/clientportal/portal/rest/dadata';
  }
};
// /clientportal/portal/rest/dadata/fio

export const promiseSuccess = (data?, statusCode = 200): Promise<Partial<AxiosResponse>> => {
  return new Promise((resolve, reject) => {
    if (DELAY === 0) {
      resolve({
        status: statusCode,
        data: data,
      });
    } else {
      setTimeout(() => {
        resolve({
          status: statusCode,
          data: data,
        });
      }, DELAY);
    }
  });
};

export const promiseError = (data, statusCode = 400): any => {
  return new Promise((resolve, reject) => {
    if (DELAY === 0) {
      reject({
        response: {
          status: statusCode,
          data: data,
        },
      });
    } else {
      setTimeout(() => {
        reject({
          response: {
            status: statusCode,
            data: data,
          },
        });
      }, DELAY);
    }
  });
};

export const validateAddressfixDate = data => ({
  ...data,
  phbirthdate: data.phbirthdate === '' ? null : data.phbirthdate,
  phdocgivedate: data.phdocgivedate === '' ? null : data.phdocgivedate,
  abirthdate: data.abirthdate === '' ? null : data.abirthdate,
});

export const logFormData = (formData: FormData) => {
  try {
    const result = {
      data: [] as any[],
      files: [] as any[],
    };

    Array.from(formData).forEach(input => {
      const attr = input[0];
      const val = input[1];

      if (typeof val === 'string')
        result.data.push({
          attr: attr,
          val: val,
        });

      if (typeof val === 'object')
        result.files.push({
          name: val?.name,
          lastModified: val?.lastModified,
          size: val?.size,
          sizeKb: Math.round(val?.size / 1024),
          type: val?.type,
        });
    });

    log.debug('logFormData :: data :: ', result);
    return result;
  } catch (err) {
    log.debug('logFormData :: err :: ', err);
  }
};

// SMS Error status code
export const SMS_ENQUEUED = 'ENQUEUED';
export const SMS_SENT = 'SENT';
export const SMS_DELIVERED = 'DELIVERED';
export const SMS_UNDELIVERED = 'UNDELIVERED';
export const SMS_FAILED = 'FAILED';
export const SMS_CANCELLED = 'CANCELLED';
export const SMS_UNKNOWN = 'UNKNOWN';
