import React from 'react';
import { Flex, FlexProps } from '@rebass/grid';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { Logo } from 'components/molecules/Logo';
import { MegafonLogo } from 'components/molecules/MegafonLogo';
import { actionSubnavSetKeepOpen } from 'store/actions/header.actions';
import { actionSetProductDuration } from 'components/organisms/insurance/redux/order.actions';
import { getProp, queryToObject } from 'utils';

export const HeaderLogo: React.FC<FlexProps> = props => {
  const location = useLocation();
  const dispatch = useDispatch();
  const reduxSubnavSetKeepOpen = keepOpen => dispatch(actionSubnavSetKeepOpen(keepOpen));
  const reduxSetProductDuration = duration => dispatch(actionSetProductDuration(duration));

  const hideSubmenu = () => reduxSubnavSetKeepOpen(false);

  const isMegafonLogoVisible = () => {
    const locationQuery = queryToObject(location.search.substring(1));
    const discountSource = getProp(locationQuery, 'utm_source', '');
    // visible only on flat/home/family products, also on checkout pages
    const isAllowedPath =
      /^\/insurance\/(flat|home|family)$/.test(location.pathname) ||
      /^\/insurance\/(flat|home|family)\/checkout\/?$/.test(location.pathname);
    if (discountSource === 'megafon' && isAllowedPath) return true;
    return false;
  };

  const handleLogoClick = () => {
    hideSubmenu();
    reduxSetProductDuration(12); // reset product duration
  };

  return (
    <Flex alignItems='center' mr='70px' {...props}>
      <Logo onClick={handleLogoClick} />
      {isMegafonLogoVisible() && <MegafonLogo />}
    </Flex>
  );
};
