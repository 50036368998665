import { AxiosResponse } from 'axios';
import { getProp } from 'utils/object.utils';
import { isWebpSupported } from 'react-image-webp/dist/utils';

export const scrollToElement = selector => {
  if (!selector || typeof selector !== 'string') return;
  const el = document.querySelector(selector);
  el && el.scrollIntoView({ behavior: 'smooth' });
};

export const checkWebpSupported = () =>
  isWebpSupported()
    ? document.querySelector('html')?.classList?.add('webp')
    : document.querySelector('html')?.classList?.add('no-webp');

// check if features are supported in browser
export const checkBrowserFeatures = () => checkWebpSupported();

export const openFile = async (
  response: AxiosResponse | Partial<AxiosResponse>,
  fileName: string = 'download',
  fileType = 'pdf'
) => {
  const data = getProp(response, 'payload.data', null) || getProp(response, 'data', null);
  if (!(data instanceof Blob)) return;

  const blob = new Blob([data], { type: 'application/pdf;charset=utf-8' });
  const fileUrl = URL.createObjectURL(blob);

  // IE
  if ((window as any).navigator && (window as any).navigator.msSaveOrOpenBlob) {
    (window as any).navigator.msSaveOrOpenBlob(blob, `${fileName}.${fileType}`);
    return;
  }

  // Chrome, FF
  if (/chrome/i.test(navigator.userAgent) || /firefox/i.test(navigator.userAgent)) {
    const w = window.open(fileUrl, '_blank');
    w && w.focus();
  } else {
    // Safari & Opera iOS
    window.location.href = fileUrl;
  }
};
