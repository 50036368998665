import React from 'react';
import styled from 'styled-components';

interface IListItemContainerProps {
  children: React.ReactNode;
}

export const ListItemContainer: React.SFC<IListItemContainerProps> = ({ children, ...props }) => (
  <Container {...props}>{children}</Container>
);

const Container = styled.li`
  display: inline-block;
  margin: 0 20px;
  padding: 0;
`;
