import * as CONSTANTS from 'store/constants/common.constants';

export const actionShowPreloaderSmall = () => {
  return {
    type: CONSTANTS.SHOW_PRELOADER_SMALL,
  };
};

export const actionStoreParams = payload => {
  return {
    type: CONSTANTS.STORE_PARAMS,
    payload,
  };
};

export const actionHidePreloaderSmall = () => {
  return {
    type: CONSTANTS.HIDE_PRELOADER_SMALL,
  };
};

export const actionSetFormFieldIsWorking = (value: any, name: string) => {
  return {
    type: CONSTANTS.FORM_FIELD_IS_WORKING,
    value,
    name,
  };
};

export const actionShowPreloader = () => {
  return {
    type: CONSTANTS.SHOW_PRELOADER,
  };
};

export const actionHidePreloader = () => {
  return {
    type: CONSTANTS.HIDE_PRELOADER,
  };
};

export const actionInsTypeSwipeRunning = (isRunning: boolean) => {
  return {
    type: CONSTANTS.INSURANCETYPE_SWIPE_RUNNING,
    isRunning,
  };
};

export const toggleCookiePolicyAction = (isVisible: boolean) => {
  return {
    type: CONSTANTS.TOGGLE_COOKIE_POLICY,
    isVisible,
  };
};
