import { Field, GenericField } from 'redux-form';

import { FnWithArgs } from 'types';

interface IReduxFormFieldProps extends Partial<GenericField<any>> {
  disabled?: boolean;
  onValueChange?: FnWithArgs;
  onValueSelected?: FnWithArgs;
  onFieldBlur?: FnWithArgs;
  componentRef?: (ref: HTMLDivElement | null) => void;
  getComponentRef?: (ref: any) => void;
}

export const ReduxFormField = Field as new () => GenericField<IReduxFormFieldProps>;
