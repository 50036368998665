import React from 'react';
import '@n3/react-vision-panel/dist/vision-panel.css';
import {
  SettingsModal,
  defaultGetSettings,
  defaultSetBodyClass,
  defaultSetCookie,
} from '@n3/react-vision-panel';
import {
  toggleOverlayOnBgImages,
  accessibilityGroups,
} from 'components/organisms/accessibility-settings/utils';
import translate from 'utils/translator';

interface IAccessibilityModalProps {
  isOpen: boolean;
  onHide: (settings: any) => void;
  onClose: (settings: any) => void;
  onSettingChange: (settings: any) => void;
}

export class AccessibilityModal extends React.Component<IAccessibilityModalProps> {
  applySetting = (property, value) => {
    const { onSettingChange } = this.props;
    const imageConfigChanged = property === 'images';
    if (imageConfigChanged) {
      toggleOverlayOnBgImages(value);
    }
    defaultSetBodyClass(property, value);
    defaultSetCookie(property, value);
    if (onSettingChange) {
      onSettingChange(defaultGetSettings());
    }
  };

  render() {
    const { isOpen, onHide, onClose } = this.props;
    return (
      <SettingsModal
        applySetting={this.applySetting}
        groups={accessibilityGroups}
        handleClose={() => onClose(defaultGetSettings())}
        onHide={() => onHide(defaultGetSettings())}
        restoreButtonText={translate('return_to_classic_version')}
        show={isOpen}
      />
    );
  }
}
