import moment, { Moment } from 'moment';
import { isUndefined } from 'lodash-es';

import { IValidatorResult, IGenericDate } from 'types';
import { isEmpty } from './string.utils';
import {
  dateFormat,
  isValidMoment,
  isBeforeNow,
  is20thCentury,
  isBetweenNowAndTarget,
  isAfterToday,
  isAfterTarget,
  isOutOfDaysRange,
  isAdult,
  isOlderThanX,
  isAfterMoscowDate,
} from './date.utils';
import { getProp } from './object.utils';
import {
  REGEX_FORBIDDEN_SPECIAL_CHARS_SPACE_ALLOWED_PATTERN,
  REGEX_FORBIDDEN_SPECIAL_CHARS_PATTERN,
  REGEX_CYRILIC_PATTERN,
  REGEX_AGENT_PHDOCGIVE_PATTERN,
  REGEX_CYRILIC_PATTERN_AND_NUMBERS,
} from './regex';
import { isRequiredField, getFieldRegex, getRequiredFields } from 'utils/schema.utils';
import translate from 'utils/translator';
import { log } from 'utils';

export const validateAll = (
  values: object,
  schema: any,
  inputs: Array<any>,
  args: any,
  cb: Function,
  props: object
) => {
  const errors: Error[] = [];
  const errorsPromise: Promise<any>[] = [];
  for (let i = 0; i < inputs.length; i++) {
    let name = inputs[i].name;
    let validator = inputs[i].validator;
    let validatorAsync = inputs[i].validatorAsync;
    errorsPromise.push(
      validate(
        values[name],
        schema.properties[name],
        name,
        schema.required.indexOf(name) !== -1,
        validator,
        args,
        validatorAsync,
        props
      )
        .then(e => {
          if (e) {
            errors.push(e);
          }
        })
        .catch(e => {
          log.error('validateAll:: error: ', e);
        })
    );
  }
  Promise.all(errorsPromise).then(() => {
    cb(errors);
  });
};

export const validateSync = function (
  value: any,
  config: object,
  name: string,
  isRequired: boolean,
  validator: Function | Function[],
  args: any,
  validatorAsync: Function,
  cb: Function,
  props: object
) {
  validate(value, config, name, isRequired, validator, args, validatorAsync, props)
    .then(result => {
      cb(result);
    })
    .catch(e => {
      cb(undefined);
      log.error('validateSync:: error: ', e);
    });
};

/**
 * Return null if validation is OK
 *
 * edit: Changed return type to any as it can be boolean or object or custom validation return value.
 *
 * @param value
 * @param config
 * @param name
 * @returns {any}
 */
export const validate = async function (
  value: any,
  config: any,
  name: string,
  isRequired: boolean,
  validator: Function | Function[],
  args: any,
  validatorAsync: Function,
  props: object
): Promise<any> {
  if (isRequired && !value) {
    return {
      type: 'required',
      message: 'error_' + name + '_required',
      name,
    };
  }

  // is required
  if (!value && !isRequired) return false;

  // support for array of custom validation functions
  if (validator && Array.isArray(validator)) {
    const errors = validator
      .map(singleValidator => singleValidator(value, args, props))
      .filter(err => !!err);
    if (errors && errors.length) return errors[0]; // return first error
  }

  // custom validation function
  if (typeof validator === 'function') {
    let e = validator(value, args, props);
    if (e) return e;
  }

  // pattern
  if (config && config.pattern) {
    const regExp = new RegExp(config.pattern);
    if (!regExp.test(value)) {
      return {
        type: 'pattern',
        message: 'error_' + name + '_pattern',
        name,
      };
    }
  }

  // type email
  if (config && config.format && config.format === 'email') {
    if (!isEmail(value)) {
      return {
        type: 'format',
        message: 'error_' + name + '_format',
        format: 'email',
        name,
      };
    }
  }

  //asycn validations as last
  if (validatorAsync) {
    const e = await validatorAsync(value, args, props);
    if (e && e.data) return e.data;
  }
  return false;
};

export const isEmail = (email: string): boolean => {
  if (hasEmailOnlyAllowedCharaters(email)) {
    // const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    // const re = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;
    // eslint-disable-next-line no-useless-escape
    const re = /^(([^<>()[\]\\,!?"$<>{}$#+^='*%&\/;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  } else {
    return false;
  }
};

export const isLonger = length => val => {
  return val && val.length > length;
};

export const isLesser = maxVal => val => {
  return val < maxVal;
};

export const validateNotSame = (value: string) => {
  if (value.length > 2) {
    return value.match(/^(.)\1*$/) === null;
  } else {
    return true;
  }
};

export const validatorBirth = (
  name: string,
  withAdultControl = true
): ((value: IGenericDate, state?: any) => IValidatorResult | undefined) => {
  return (value: IGenericDate, state?: object): IValidatorResult | undefined => {
    if (isEmpty(value as string)) {
      return;
    }
    if (!isValidMoment(value)) {
      return {
        type: 'validator',
        message: 'date_not_valid_format',
        name: name,
      };
    }
    if (!isBeforeNow(value)) {
      return {
        type: 'validator',
        message: 'error_date_must_be_from_past',
        name: name,
      };
    }
    if (withAdultControl && !isAdult(value)) {
      return {
        type: 'validator',
        message: 'phbirthday_user_must_be_adult',
        name: name,
      };
    }
    if (!is20thCentury(value)) {
      return {
        type: 'validator',
        message: `field_${name}_validation_date_must_be_atleast_20th_century`,
        name: name,
      };
    }
    if (isOlderThanX(value, 100)) {
      return {
        type: 'validator',
        message: 'error_phbirthdate_required',
        name: name,
      };
    }
  };
};

export const validatorIsOlderThanX = (
  fieldName: string,
  value: IGenericDate,
  age: number
): IValidatorResult | undefined => {
  if (isOlderThanX(value, age)) {
    return {
      type: 'validator',
      message: translate(`error_${fieldName}_is_older_than_X`, age),
      name: fieldName,
    };
  }
};

export const validatorPassportGive = (
  name: string,
  value: Moment,
  birthDate: Moment
): IValidatorResult | undefined => {
  if (isEmpty(value)) return;
  if (!isValidMoment(value))
    return {
      type: 'validator',
      message: 'date_not_valid_format',
      name: name,
    };

  if (!isBetweenNowAndTarget(value, birthDate))
    return {
      type: 'validator',
      message: 'date_must_be_between_birth_and_now',
      name: name,
    };

  if (!is20thCentury(value))
    return {
      type: 'validator',
      message: `field_${name}_validation_date_must_be_atleast_20th_century`,
      name: name,
    };
};

export const isAfterTodayValidator = (
  value: any,
  fieldName: string
): IValidatorResult | undefined => {
  if (!isAfterToday(moment(value, dateFormat))) {
    return {
      type: 'validator',
      message: 'validation_error_date_is_in_the_future',
      name: fieldName,
    };
  }
};

export const isBeforeDateValidator = (
  value: any,
  dateTreshold: Moment,
  fieldName: string
): IValidatorResult | undefined => {
  if (isEmpty(value)) return;
  if (!isValidMoment(value))
    return {
      type: 'validator',
      message: 'date_not_valid_format',
      name: fieldName,
    };

  if (!isBetweenNowAndTarget(value, dateTreshold))
    return {
      type: 'validator',
      message: `error_${fieldName}_before_date_validator`,
      name: fieldName,
    };
};

export const isAfterTargetValidator = (
  value,
  target,
  fieldName,
  granularity?,
  customMessageKey?
): IValidatorResult | undefined => {
  if (typeof value === 'undefined' || typeof target === 'undefined') {
    return undefined; // do not validate if any of both dates are not provided
  }

  const refValue = moment(value, typeof value === 'string' ? dateFormat : '');
  const targetValue = moment(target, typeof target === 'string' ? dateFormat : '');

  if (isAfterTarget(refValue, targetValue, granularity)) {
    return {
      type: 'validator',
      message: customMessageKey
        ? customMessageKey
        : 'validation_error_incorrect_startdate_and_enddate',
      name: fieldName,
    };
  }
};

export const lengthValidator = (
  value: string,
  targetLength: number,
  fieldName: string,
  customErrorMessage: string = 'validation_error_length_error'
): IValidatorResult | undefined => {
  if (typeof value === 'undefined' || typeof targetLength === 'undefined') return undefined;

  if (value.length !== targetLength) {
    return {
      type: 'validator',
      message: translate(customErrorMessage, targetLength),
      name: fieldName,
    };
  }
};

export const maxLengthValidator = (
  value: string,
  maxLength: number,
  fieldName: string
): IValidatorResult | undefined => {
  if (typeof value === 'undefined' || typeof maxLength === 'undefined') return undefined;

  if (value.length > maxLength) {
    return {
      type: 'validator',
      message: translate('validation_error_max_length_exceeded', maxLength),
      name: fieldName,
    };
  }
};

export const minLengthValidator = (
  value: string,
  minLength: number,
  fieldName: string,
  customErrorMessage: string = 'validation_error_min_length'
): IValidatorResult | undefined => {
  if (typeof value === 'undefined' || typeof minLength === 'undefined') {
    return undefined; // do not validate if any of both dates are not provided
  }

  if (value.length < minLength) {
    return {
      type: 'validator',
      message: translate(customErrorMessage, minLength),
      name: fieldName,
    };
  }
};

export const betweenLengthValidator = (
  value: string,
  minLength: number,
  maxLength: number,
  fieldName: string
): IValidatorResult | undefined => {
  if (
    typeof value === 'undefined' ||
    typeof minLength === 'undefined' ||
    typeof minLength === 'undefined'
  )
    return undefined; // do not validate if any of both dates are not provided

  const valLength = value.length;
  if (valLength < minLength || valLength > maxLength) {
    return {
      type: 'validator',
      message: translate(`error_${fieldName}_between_length`, minLength, maxLength),
      name: fieldName,
    };
  }
};

export const hasSuggestionSelectedValidator = (suggestion, fieldName) => {
  if (!suggestion) {
    return {
      type: 'validator',
      message: translate('error_you_are_probably_mistaken'),
      name: fieldName,
    };
  }
};

export const hasNameAndSurnameValidator = (suggestion, fieldName) => {
  const name = getProp(suggestion, 'data.name');
  const surname = getProp(suggestion, 'data.surname');
  if (suggestion && !(name && surname)) {
    return {
      type: 'validator',
      message: translate('error_you_are_probably_mistaken'),
      name: fieldName,
    };
  }
};

export const minWordsCountValidator = (
  value: string,
  minWordsLength: number,
  fieldName: string
): IValidatorResult | undefined => {
  if (typeof value === 'undefined' || typeof minWordsLength === 'undefined') {
    return undefined; // do not validate if any of both dates are not provided
  }

  const values = value
    .trim()
    .split(' ')
    .filter(v => v !== '');

  if (values && values.length <= 1) {
    return {
      type: 'validator',
      message: translate('validation_error_min_words_length', minWordsLength),
      name: fieldName,
    };
  }
};

export const isOutOfDaysRangeValidator = (
  value: object,
  target: object,
  daysRange: number,
  fieldName: string = ''
): IValidatorResult | undefined => {
  if (typeof value === 'undefined' || typeof target === 'undefined') {
    return undefined; // do not validate if any of both dates are not provided
  }

  if (isOutOfDaysRange(moment(value, dateFormat), moment(target, dateFormat), daysRange)) {
    return {
      type: 'validator',
      message: translate('validation_error_period_of_disability_exceeded_X_days', daysRange),
      name: fieldName,
    };
  }
};

export const regexValidator = (
  pattern,
  value,
  fieldName,
  regexOpts = ''
): IValidatorResult | undefined => {
  if (!pattern) return undefined;
  const regExp = new RegExp(pattern, regexOpts);
  if (!regExp.test(value)) {
    return {
      type: 'pattern',
      message: 'error_' + fieldName + '_pattern',
      name: fieldName,
    };
  }
};

export const regexValidatorSchema = (
  schema,
  value,
  fieldName,
  regexOpts = ''
): IValidatorResult | undefined => {
  const schemaPattern = getFieldRegex(schema, fieldName);
  if (!schemaPattern) return undefined;
  const regExp = new RegExp(schemaPattern, regexOpts);
  if (!regExp.test(value)) {
    return {
      type: 'pattern',
      message: 'error_' + fieldName + '_pattern',
      name: fieldName,
    };
  }
};

export const requiredValidator = (value, fieldName): IValidatorResult | undefined => {
  if (!value) {
    return {
      type: 'required',
      message: 'error_' + fieldName + '_required',
      name: fieldName,
    };
  }
};

export const requiredValidatorSchema = (
  schema,
  formValues,
  value,
  fieldName,
  errorMsg?
): IValidatorResult | undefined => {
  const requiredFields = getRequiredFields(schema, formValues);
  if (!isRequiredField(requiredFields, fieldName)) return;
  if (!value)
    return {
      type: 'required',
      message: errorMsg ? errorMsg : 'error_' + fieldName + '_required',
      name: fieldName,
    };
};

export const validateNotSameValidator = (value, fieldName): IValidatorResult | undefined => {
  if (value && !validateNotSame(value)) {
    return {
      type: 'validator',
      message: fieldName + '_has_all_numbers_same',
      name: fieldName,
    };
  }
};

export const emailValidator = (value, fieldName): IValidatorResult | undefined => {
  if (!isEmail(value)) {
    return {
      type: 'validator',
      message: 'validation_error_email',
      name: fieldName,
    };
  }
};

export const validatorSpecialCharacters = (
  value,
  fieldName,
  spaceAllowed = false
): IValidatorResult | undefined => {
  const errMsgType = spaceAllowed
    ? 'forbidden_special_characters_space_allowed'
    : 'forbidden_special_characters';
  const REGEXP = spaceAllowed
    ? REGEX_FORBIDDEN_SPECIAL_CHARS_SPACE_ALLOWED_PATTERN
    : REGEX_FORBIDDEN_SPECIAL_CHARS_PATTERN;
  const specialCharsError = regexValidator(REGEXP, value, fieldName, 'gi');
  if (specialCharsError) {
    return {
      type: 'validator',
      message: `error_${errMsgType}_pattern`,
      name: fieldName,
    };
  }
};

/**
 * Returns false when there is not allowed character
 *
 * @param email
 * @returns {boolean}
 */
export function hasEmailOnlyAllowedCharaters(email: string): boolean {
  if (isEmpty(email)) {
    //must return true, required is different validation
    return true;
  }
  const emailTested = email.match(/[a-zA-Z0-9@!#$%&'*+\-/=?^_`{|}~."(),:;<>[\]]+/);
  return emailTested !== undefined && emailTested !== null ? emailTested[0] === email : false;
}

export function atLeastOneCyrilicLetter(val): boolean {
  if (isEmpty(val)) {
    return true;
  }
  const result = val.match(/.*[ЁёА-я]+.*/u) !== null;
  return result;
}

export function isBeforeNowValidator(
  value: string,
  inputDateFormat = dateFormat,
  fieldName: string = ''
): IValidatorResult | undefined {
  if (isEmpty(value as string)) return;
  const val = moment(value, inputDateFormat);
  if (!isBeforeNow(val)) {
    return {
      type: 'validator',
      message: `error_${fieldName ? fieldName + '_' : ''}date_must_be_from_past`,
      name: fieldName,
    };
  }
}

export function isBeforeMoscowNowValidator(
  value: string,
  moscowDate: Moment | null,
  inputDateFormat = dateFormat,
  fieldName: string = ''
): IValidatorResult | undefined {
  if (!moscowDate) return;
  if (isEmpty(value as string)) return;
  const val = moment(value, inputDateFormat);
  if (isAfterMoscowDate(val, moscowDate)) {
    return {
      type: 'validator',
      message: `error_${fieldName ? fieldName + '_' : ''}date_must_be_from_past`,
      name: fieldName,
    };
  }
}

export const isCyrilicValidator = (value, name) =>
  value && regexValidator(REGEX_CYRILIC_PATTERN, value, name);

export const isCyrilicWithNumbersValidator = (value, name) =>
  value && regexValidator(REGEX_CYRILIC_PATTERN_AND_NUMBERS, value, name);

export const phdocgiveValidator = (value, fieldName) => {
  const hasError = regexValidator(REGEX_AGENT_PHDOCGIVE_PATTERN, value, fieldName);
  if (hasError) {
    return {
      type: 'validator',
      message: 'error_agent_phdocgive_pattern',
      name: fieldName,
    };
  }
};

export const atLeastOneFileRequired = (value, fieldName): IValidatorResult | undefined => {
  if (isEmpty(value as string)) return;
  const hasSomeFile = value && Array.isArray(value) && value.length;
  if (!hasSomeFile) {
    return {
      type: 'validator',
      message: `error_${fieldName}_file_required`,
      name: fieldName,
    };
  }
};

export const minValueValidator = (
  value: string,
  minValue: number,
  formattedMinValue: number | string,
  fieldName: string
): IValidatorResult | undefined => {
  if (
    typeof value === 'undefined' ||
    typeof minValue !== 'number' ||
    typeof Number(value) !== 'number'
  )
    return undefined;

  if (Number(value) < minValue) {
    return {
      type: 'validator',
      message: translate(`error_${fieldName}_min_value_required`, formattedMinValue),
      name: fieldName,
    };
  }
};

export const alphanumericValidator = (
  value: string,
  fieldName: string
): IValidatorResult | undefined => {
  if (isUndefined(value)) return value;
  const isAlphanumeric = /^([a-zA-Z0-9]+)$/i.test(value);
  if (!isAlphanumeric) {
    return {
      type: 'validator',
      message: `error_${fieldName}_alphanumeric`,
      name: fieldName,
    };
  }
};

// prettier-ignore
export const validators = {
  email: (val, a, b, c) => isEmail(val) ? undefined : translate('validation_error_email', translate(`field_${b.form}_${c}`), val),
  required: (val, a, b, c) => val === undefined || val === null || val === '' || val === false ? translate('validation_error_required', translate(`field_${b.form}_${c}`), val) : undefined,
  requiredFemale: (val, a, b, c) => val === undefined || val === null || val === '' || val === false ? translate('validation_error_required_female', translate(`field_${b.form}_${c}`), val) : undefined,
  requiredAgree: (val, a, b, c) => val === undefined || val === null || val === '' || val === false ? translate('validation_error_agree', translate(`field_${b.form}_${c}`), val) : undefined,
  contractnumber: (val, a, b, c) => val && val.length === 10 ? undefined : translate('validation_error_contractnumber', translate(`field_${b.form}_${c}`), val),
  decimal: (val, a, b, c) => val && /.*\.+.*/.test(val) ? translate('validation_error_noFragments', translate(`field_${b.form}_${c}`), val) : undefined,
  maxLength5: (val, a, b, c) => isLonger(5)(val) ? translate('validator_error_length5', translate(`field_${b.form}_${c}`), val) : undefined,
  leserEq4000: (val, a, b, c) => isLesser(40001)(val) ? undefined : translate('validator_error_number_lesserthan', translate(`field_${b.form}_${c}`), val, 40000),
}
