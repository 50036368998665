export enum EPaymentMethod {
  BANK_CARD = 'BANK_CARD',
  CELLPHONE = 'CELLPHONE',
}

export enum EPaymentMethodId {
  BANK_CARD = 7,
  CELLPHONE = 9,
}

export type TPaymentMethod = EPaymentMethod.BANK_CARD | EPaymentMethod.CELLPHONE;
