import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import styled from 'styled-components/macro';

import { Row } from 'components/atoms/Row';
import { NavigationMenu } from 'components/atoms/NavigationMenu';
import { NavigationMenuItem } from 'components/atoms/NavigationMenuItem';
import { NavigationSubMenu } from 'components/atoms/NavigationSubMenu';
import { useTypedSelector } from 'store';
import { LinkProlong } from '../LinkProlong';

import { closeHamburger, createActionHamburgerMenu } from 'store/actions/header.actions';

import translate from 'utils/translator';

export const NavigationMobile: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const reduxCreateActionHamburgerMenu = () => dispatch(createActionHamburgerMenu());
  const reduxCloseHamburger = () => dispatch(closeHamburger());
  const hamburgerMenuOpen = useTypedSelector(state => state.header.hamburgerMenuOpen);

  return (
    <Container alignItems='center'>
      <ContainerProlong>
        <LinkProlong mr='40px' />
      </ContainerProlong>
      <NavigationMenu
        isOpen={hamburgerMenuOpen}
        toggleMenuAction={reduxCreateActionHamburgerMenu}
        closeMenuAction={reduxCloseHamburger}
      >
        <NavigationMenuItem level={1}>
          <a href='/clientportal' onClick={reduxCloseHamburger}>
            {translate('login')}
          </a>
        </NavigationMenuItem>
        <NavigationMenuItem level={1}>
          <a
            href='https://www.homeins.ru/customer-support/had-an-accident/'
            onClick={reduxCloseHamburger}
          >
            {translate('had_an_accident')}
          </a>
        </NavigationMenuItem>
        <NavigationSubMenu name={translate('about_us')} level={1}>
          <NavigationMenuItem isSubmenuItem={true}>
            <a href='https://www.homeins.ru/about/info/' onClick={reduxCloseHamburger}>
              Общая информация
            </a>
          </NavigationMenuItem>
          <NavigationMenuItem isSubmenuItem={true}>
            <a href='https://www.homeins.ru/about/controls/' onClick={reduxCloseHamburger}>
              Руководство и участники
            </a>
          </NavigationMenuItem>
          <NavigationMenuItem isSubmenuItem={true}>
            <a href='https://www.homeins.ru/about/membership/' onClick={reduxCloseHamburger}>
              Членство в союзах
            </a>
          </NavigationMenuItem>
          <NavigationMenuItem isSubmenuItem={true} withBorder={false}>
            <NavigationSubMenu name={translate('Раскрытие информации')} isSubmenuItem={true}>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/info/constituent/'
                  onClick={reduxCloseHamburger}
                >
                  Документы и лицензии
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/normative-document/statements/'
                  onClick={reduxCloseHamburger}
                >
                  Финансовая отчетность
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/normative-document/politika-pers-data/'
                  onClick={reduxCloseHamburger}
                >
                  Политика в области обработки персональных данных
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/normative-document/rekomend-def-app/'
                  onClick={reduxCloseHamburger}
                >
                  Политика безопасности
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/normative-document/reestr-agent/'
                  onClick={reduxCloseHamburger}
                >
                  Реестр агентов и брокеров
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/normative-document/statement/'
                  onClick={reduxCloseHamburger}
                >
                  Специальная оценка условий труда
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/normative-document/mobile_app/'
                  onClick={reduxCloseHamburger}
                >
                  Мобильное приложение
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/normative-document/participants/'
                  onClick={reduxCloseHamburger}
                >
                  Структура и состав акционеров
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/info/standarts/'
                  onClick={reduxCloseHamburger}
                >
                  Страховые стандарты
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/info/bank_details/'
                  onClick={reduxCloseHamburger}
                >
                  Банковские реквизиты
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/normative-document/control/'
                  onClick={reduxCloseHamburger}
                >
                  Список контролирующих организаций
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/normative-document/protection/'
                  onClick={reduxCloseHamburger}
                >
                  Способы защиты прав
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/normative-document/data-partners/'
                  onClick={reduxCloseHamburger}
                >
                  Обработка данных партнёрами
                </a>
              </NavigationMenuItem>
              <NavigationMenuItem isSubmenuItem={true} level={3}>
                <a
                  href='https://www.homeins.ru/about/normative-document/map/'
                  onClick={reduxCloseHamburger}
                >
                  Карта сайта
                </a>
              </NavigationMenuItem>
            </NavigationSubMenu>
          </NavigationMenuItem>
          <NavigationMenuItem isSubmenuItem={true}>
            <a
              href='https://www.homeins.ru/about/pravila-strahovaniya/'
              onClick={reduxCloseHamburger}
            >
              Правила страхования и тарифы
            </a>
          </NavigationMenuItem>
          <NavigationMenuItem isSubmenuItem={true}>
            <a href='https://www.homeins.ru/about/info/insurance/' onClick={reduxCloseHamburger}>
              Виды страхования
            </a>
          </NavigationMenuItem>
          <NavigationMenuItem isSubmenuItem={true}>
            <a href='https://www.homeins.ru/about/press/' onClick={reduxCloseHamburger}>
              Пресс-центр
            </a>
          </NavigationMenuItem>
          <NavigationMenuItem isSubmenuItem={true}>
            <a href='https://www.homeins.ru/contacts/' onClick={reduxCloseHamburger}>
              Контакты
            </a>
          </NavigationMenuItem>
        </NavigationSubMenu>
        <NavigationSubMenu name={translate('customer_support')} level={1}>
          <NavigationMenuItem isSubmenuItem={true}>
            <a
              href='https://www.homeins.ru/customer-support/feedback/'
              onClick={reduxCloseHamburger}
            >
              Обратная связь
            </a>
          </NavigationMenuItem>
          <NavigationMenuItem isSubmenuItem={true}>
            <a
              href='https://www.homeins.ru/customer-support/agreement-termination/'
              onClick={reduxCloseHamburger}
            >
              Расторгнуть договор
            </a>
          </NavigationMenuItem>
          <NavigationMenuItem isSubmenuItem={true}>
            <a
              href='https://www.homeins.ru/customer-support/return-money/'
              onClick={reduxCloseHamburger}
            >
              Возврат ошибочных платежей
            </a>
          </NavigationMenuItem>
        </NavigationSubMenu>
        <NavigationMenuItem level={1}>
          <Link to={`/getpolicy${location.search}`} onClick={reduxCloseHamburger}>
            {translate('get_policy_link')}
          </Link>
        </NavigationMenuItem>
        <NavigationMenuItem level={1}>
          <Link to={`/insurance/prolong/form`} onClick={reduxCloseHamburger}>
            {translate('prolong_link')}
          </Link>
        </NavigationMenuItem>
      </NavigationMenu>
    </Container>
  );
};

const Container = styled(Row)`
  display: none;
  @media (max-width: 1399px) {
    display: flex;
  }

  /* ${({ theme }) => theme.media.maxTabletHorizontal`
    display: flex;
  `} */
`;

const ContainerProlong = styled.div`
  display: none;

  @media (min-width: 1401px) {
    display: flex;
  }

  /* ${({ theme }) => theme.media.minPhoneHorizontal`
    display: flex;
  `} */
`;
