import { includes, get, isUndefined, clone, size } from 'lodash-es';

// check if field is required based on schema 'required' attribute
export const isRequiredField = (requiredFields, field): boolean => includes(requiredFields, field);

// get field validation regex from schema
export const getFieldRegex = (schema, field): string | undefined =>
  get(schema, `properties.${field}.pattern`);

export const getRequiredFields = (schema, formValues) => {
  const schemaRequired = schema?.required;
  const anyOfNot = schema?.anyOf?.find(i => i?.not)?.not;
  const notRequiredProp = get(anyOfNot, 'required[0]', null);
  const notRequiredVal = formValues?.[notRequiredProp];
  // if `anyOf` prop exists in the schema, we check if it is false value and if so
  // we add additional required fields from `anyOf.required` prop to root required fields
  if (!isUndefined(notRequiredVal) && !notRequiredVal) {
    const reqCopy = clone(schemaRequired);
    const additionalRequiredFields = schema?.anyOf?.find(i => i?.required)?.required;
    if (size(additionalRequiredFields)) {
      const newRequired = [...reqCopy, ...additionalRequiredFields];
      return newRequired;
    }
    return schemaRequired;
  }
  return schema?.required;
};
