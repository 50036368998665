import { rest } from './rest';
import { sendLog } from './api';
import { IFile } from 'types';
import { log } from 'utils';
import { promiseSuccess, logFormData } from './rest.utils';

const MOCKS_ENABLED = false;

/**
 * Create announcement
 */
export const announcementCreateNew = values => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /announcement');
    log.debug({ values });
    return promiseSuccess({
      announcement_id: 123,
      // error: 'Limit reached'
    });
  }

  return rest.post('/announcement', values);
};

/**
 * Forward: Verify IMEI
 */
export const verifyImei = imei => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /announcement/verify-imei');
    log.debug({ imei });
    // return promiseError({ error: { message: 'IMEI_NOT_VERIFIED' } });
    return promiseSuccess('OK', 200);
  }

  return rest.post('/announcement/verify-imei', { imei });
};

export const getUploadedFiles = async (announcementId: string | null): Promise<string[]> => {
  try {
    const response = await rest.get(`/announcement/files-uploaded/${announcementId}`);
    log.debug('getUploadedFiles:: response :', response);
    return response?.data?.data;
  } catch (err) {
    log.error('getUploadedFiles:: err :: ', err);
    return Promise.resolve([]);
  }
};

export const announcementUploadFiles = async (
  announcementId: string | null,
  medicalAttachs: IFile[],
  bankAttchs: IFile[]
) => {
  try {
    const files = [...medicalAttachs, ...bankAttchs] as IFile[];
    const filesTotal = files.length;
    const alreadyUploadedFiles = await getUploadedFiles(announcementId);
    log.debug('announcementUploadFiles:: alreadyUploadedFiles :', alreadyUploadedFiles);
    const medicalAttchsUploadPromises = medicalAttachs
      // upload only files not yet uploaded
      .filter((attch: IFile) => !alreadyUploadedFiles?.includes(attch.name))
      .map(attch => {
        const fileOrder = files.indexOf(attch) + 1;
        return announcementFileUpload(announcementId, filesTotal, fileOrder, attch, null);
      });
    const bankDetailUploadPromises = bankAttchs
      // upload only files not yet uploaded
      .filter((attch: IFile) => !alreadyUploadedFiles?.includes(attch?.path))
      .map(attch => {
        const fileOrder = files.indexOf(attch) + 1;
        return announcementFileUpload(announcementId, filesTotal, fileOrder, null, attch);
      });

    const uploadedFiles = await Promise.all([
      ...medicalAttchsUploadPromises,
      ...bankDetailUploadPromises,
    ]);

    log.debug('announcementUploadFiles:: response :', uploadedFiles);

    // send upload log
    sendLog('announcement', {
      function: 'announcementUploadFiles',
      data: {
        announcementId,
        message: 'announcement verify SMS code',
        data: {
          filesTotal,
        },
      },
    });

    return uploadedFiles;
  } catch (err) {
    log.error('announcementUploadFiles:: error :: ', err);
    return Promise.reject(err);
  }
};

/**
 * Verify SMS code
 */
export const announcementVerifySmsCode = (announcementId, code) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /announcement/verify');
    log.debug({ announcementId: announcementId, code });
    return promiseSuccess({
      result: true,
    });
  }

  // send files log
  sendLog('announcement', {
    function: 'announcementVerifySmsCode',
    data: {
      announcementId,
      message: 'announcement verify SMS code',
      data: {
        announcementId,
        code,
      },
    },
  });

  return rest.post(`/announcement/verify`, {
    announcement_id: announcementId,
    code,
  });
};

/**
 * Upload announcement file
 */
export const announcementFileUpload = (
  announcementId,
  filesTotal,
  fileOrder,
  medicalCertAttach?,
  bankDetailsAttch?
) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /announcement/upload');
    log.debug({ announcementId: announcementId });
    return promiseSuccess({
      result: true,
    });
  }

  const formData = new FormData();
  formData.append('announcement_id', announcementId);
  formData.append('files_total', String(filesTotal));
  formData.append('file_order', String(fileOrder));
  if (medicalCertAttach) formData.append('medical_certificate_attachments', medicalCertAttach);
  if (bankDetailsAttch) formData.append('bank_details_attachments', bankDetailsAttch);

  // send files log
  sendLog('announcement', {
    function: 'announcementFileUpload',
    data: {
      announcementId,
      message: 'announcement files',
      data: logFormData(formData),
    },
  });

  log.debug('announcementFileUpload:: file to upload :: ', formData);

  return rest.post(`/announcement/upload`, formData, {
    timeout: 7 * 60 * 1000, // wait for file uploads for 7 mins (in case of slow internet)
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
};
