export const CREATE_ANNOUNCEMENT = 'CREATE_ANNOUNCEMENT';
export const CREATE_ANNOUNCEMENT_FAIL = 'CREATE_ANNOUNCEMENT_FAIL';
export const CREATE_ANNOUNCEMENT_SUCCESS = 'CREATE_ANNOUNCEMENT_SUCCESS';
export const SET_EMPLOYEE_SUGGESTION = 'SET_EMPLOYEE_SUGGESTION';
export const SET_FULLNAME_SUGGESTION = 'SET_FULLNAME_SUGGESTION';
export const SET_IS_PERSONAL_DATA_LINK_CLICKED = 'SET_IS_PERSONAL_DATA_LINK_CLICKED';
export const VERIFY_SMS_CODE = 'VERIFY_SMS_CODE';
export const VERIFY_SMS_CODE_FAIL = 'VERIFY_SMS_CODE_FAIL';
export const VERIFY_SMS_CODE_SUCCESS = 'VERIFY_SMS_CODE_SUCCESS';
export const VERIFY_SMS_CODE_ATTEMPTS_COUNT = 'VERIFY_SMS_CODE_ATTEMPTS_COUNT';
