import { IProduct, IProductOption, FnWithArgs } from 'types';

const GA_ENABLED = false;

export const EVENTS = {
  page_view: 'page_view',
  paymentCompleted: 'paymentCompleted',
  telefon_send: 'telefon_send',
  get_policy: 'get_policy',
};

export const BUTTONS = {
  nahratSmlouvu: 'nahrat_smlouvu',
};

export const FUNNELS = {
  flat: 'flat',
  home: 'home',
  family: 'family',
  ourkidz: 'ourkidz',
  cards: 'cards',
  extend: 'extend',
  getpolicy: 'getpolicy',
  fairins: 'fairins',
};

const waitForGa = (cb: FnWithArgs) => {
  if (!GA_ENABLED) {
    cb(null);
    return;
  }

  const dataLayer = (global as any).dataLayer;
  if (dataLayer) {
    cb();
  } else {
    setTimeout(() => {
      waitForGa(cb);
    }, 100);
  }
};

export const gaPush = (
  event: string,
  button?: string,
  funnel?: string,
  path?: string,
  value?: string
) => () =>
  waitForGa(() => {
    if (!GA_ENABLED) return;
    const dataLayer = (global as any).dataLayer;
    dataLayer.push({
      event,
      ...(button ? { button } : {}),
      ...(funnel ? { funnel } : {}),
      ...(path ? { path } : {}),
      ...(value ? { value } : {}),
    });
  });

export const getPrice = (tfrId, { data }: { data: IProduct }) => {
  if (!GA_ENABLED) return null;
  let flat: IProductOption[] = [];
  if (!data || !Array.isArray(data)) return null;
  data.forEach(item => (flat = flat.concat(item.options)));
  const option = flat.find(item => item.tfrId === tfrId);
  return option && option.premium;
};

export const getPriceProps = (props): string | null | undefined =>
  getPrice(props.order.tfrId, props.products);

export const gaPushScreenView = (props: any, funnel?: string, value?: any) => {
  if (!GA_ENABLED) return;
  const {
    location: { pathname },
  } = props;
  gaPush(EVENTS.page_view, undefined, funnel, pathname, value)();
};

export const gaPushEvent = event => gaPush(event)();
