export const SCREEN_NAMES = {
  NEW_SALE: 'NEW_SALE',
  SALES_HISTORY: 'SALES_HISTORY',
  LOGIN: 'LOGIN',
  INFORMATION: 'INFORMATION',
} as const;

export const AUTH_TYPE = {
  PK: 1,
  AGENT: 2,
};

export const SALES_HISTORY = {
  table: {
    DEFAULT_PAGE: 0,
    DEFAULT_PAGE_SIZE: 20,
    DEFAULT_SORTED: [], // mo sort
  },
};
