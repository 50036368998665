import { objectToQuery } from 'utils/url.utils';
import { stringify } from 'utils/object.utils';
import { getDeviceInfo } from 'utils/device.utils';
import { ObjectGeneric, EPaymentMethodId, ILogMessage } from 'types';
import { rest } from './rest';
import { promiseSuccess, promiseError, validateAddressfixDate } from './rest.utils';
import { log } from 'utils';

// mocks
import products from 'rest/mocks/products.json';
import find from 'rest/mocks/find.json';
import findFlat from 'rest/mocks/find_flat.json';
import findFamily from 'rest/mocks/find_family.json';
import findFlat2 from 'rest/mocks/find_flat2.json';

const MOCKS_ENABLED = false;

export const getProducts = () => {
  if (MOCKS_ENABLED) return promiseSuccess(products);
  return rest.get('/product/all');
};

export const sendPromo = (code: string, product: string) => {
  if (MOCKS_ENABLED) {
    return promiseSuccess({
      status: 'OK',
      tpc_label: '-25%',
      tpl_id: 1,
      tpl_strikethrough_premium: true,
      tpl_strikethrough_sum_insured: false,
    });
  }
  return rest.get('/promo-code/validate?' + objectToQuery({ code: code, product: product }));
};

export const getSmsCode = (orderid: string) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /sms/send');
    if (orderid === 'INVALID') return promiseError({ error: { message: 'INVALID_ID' } });
    return promiseSuccess({ sms_process_id: 216 }, 201);
  }
  return rest.post('/sms/send', { orderId: orderid });
};

export const sendOrder = (values: ObjectGeneric) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /policy');
    log.debug(values);
    if (values.phcellphone === '789798798') return promiseError({ errors: ['e'] });
    return promiseSuccess({ orderid: '5' });
  }

  const params = {
    ...values,
    paymenttype: EPaymentMethodId.BANK_CARD,
  };

  return rest
    .post('/policy', params)
    .then(response => response)
    .catch(err => Promise.reject(err.response));
};

export const confirmPolicy = (order_id: string) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /policy/confirm');
    log.debug({
      orderid: order_id,
      persinsrules: true,
      persdata2: true,
      persparamcontract: true,
      perspayment: true,
    });
    return promiseSuccess({
      result: 'OK',
      data: {
        cellphone_operator_id: 2,
        cellphone_operator_name: 'МегаФон',
        mobile_payment_available: true,
      },
    });
  }
  return rest.post('/policy/confirm', {
    orderid: order_id,
    persinsrules: true,
    persdata2: true,
    persparamcontract: true,
    perspayment: true,
  });
};

export const checkSmsDelivery = (sms_process_id: number) => {
  const data: any = { sms_process_id: sms_process_id };
  if (MOCKS_ENABLED) {
    log.debug('REST: /sms?' + objectToQuery(data));
    if (sms_process_id === 1) {
      return promiseSuccess({ result: 'UNDELIVERED' });
    }
    return promiseSuccess({ result: 'DELIVERED' });
  }

  if (sms_process_id === 1) data.forceStatus = 'undelivered';
  return rest.get('/sms?' + objectToQuery(data));
};

export const verifySmsCode = (sms: string, order_id: string) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /sms/verify');
    log.debug({ orderId: order_id, code: sms });
    if (order_id === 'INVALID_ORDERID') {
      return promiseError({ error: { message: 'ORDER_NOT_FOUND' } });
    } else {
      return promiseSuccess({
        url: 'test',
        id: 1,
        sector: 'sdf',
        signature: 'sdf',
      });
    }
  }
  return rest.post('/sms/verify', { orderId: order_id, code: sms });
};

export const getPolicy = (orderid: string) => {
  if (MOCKS_ENABLED) {
    log.debug('/policy/find/?orderId=' + orderid);
    if (orderid === 'INVALID') {
      return promiseError({ error: { message: 'NOT_FOUND' } });
    } else if (orderid === 'flat') {
      return promiseSuccess(findFlat);
    } else if (orderid === 'family') {
      return promiseSuccess(findFamily);
    } else if (orderid === 'flat2') {
      return promiseSuccess(findFlat2);
    } else {
      return promiseSuccess(find);
    }
  }
  return rest.get('/policy/find/?orderId=' + orderid);
};

export const getPaymentStatus = (orderid: string) => {
  if (MOCKS_ENABLED) {
    log.debug(`/payments/mobile/status?orderid=${orderid}&timeout=0`);
    if (orderid === 'SUCCESS') {
      return promiseSuccess({
        success: true,
      });
    } else {
      return promiseSuccess({
        success: false,
      });
    }
  }
  return rest.get(`/payments/mobile/status?orderid=${orderid}&timeout=0`);
};

export const productDocumentName = tfrId => {
  if (MOCKS_ENABLED) {
    log.debug(`REST: '/product/productDocumentName/?tfrId=${tfrId}`);
    log.debug({ tfrId });
    return promiseSuccess('108_INS_RULES_FAMILY_HCFB.pdf', 200);
  }

  return rest
    .get('/product/productDocumentName/?tfrId=' + tfrId)
    .then(data => (data ? data.data : undefined))
    .catch(err => log.error('productDocumentName:: error: ', err));
};

export const validateAddress = (data: object, type: 'ph' | 'ob'): Promise<any> => {
  const newData = validateAddressfixDate(data);
  if (MOCKS_ENABLED) {
    if (
      newData[`${type}fulladdress`] === 'aaa' ||
      newData[`${type}fulladdress`] === 'г Санкт-Петербург, наб Обводного канала, д 5'
    ) {
      return promiseSuccess(
        type === 'ob'
          ? {
              type: 'validator',
              message: 'address_is_not_ok_1',
              name: `${type}fulladdress`,
            }
          : {
              type: 'validator',
              message: 'OK',
              name: `${type}fulladdress`,
            }
      ).then(response => {
        if (response.data && response.data.message !== 'OK') {
          return response;
        } else {
          return undefined;
        }
      });
    } else {
      return promiseSuccess().then(response => {
        if (response.data && response.data.message !== 'OK') {
          return response;
        } else {
          return undefined;
        }
      });
    }
  } else {
    return rest
      .post(`/address/validate/${type}`, newData)
      .then(response => {
        if (response.data && response.data.message !== 'OK') {
          response.data.message = `${type}_${response.data.message}`;
          return response;
        } else {
          return undefined;
        }
      })
      .catch(({ response }) => {
        if (response.data && response.data.message !== 'OK') {
          response.data.message = `${type}_${response.data.message}`;
          return response;
        } else {
          return undefined;
        }
      });
  }
};

export const insurancePayOrderFromMobileAccount = (orderId: string, smsCode: string) => {
  if (MOCKS_ENABLED) {
    log.debug(`REST: /payments/mobile/initiate`);
    log.debug({ orderId, smsCode });
    return promiseSuccess({
      success: true,
      message: '',
      code: 'PAYMENT_RESULT_TIMEOUT',
      contract_number: 8120011121,
    });
  }

  return rest
    .post(
      `/payments/mobile/initiate`,
      { orderId, code: smsCode },
      { timeout: 70 * 1000 } // wait for payment confirmation
    )
    .then(response => response)
    .catch(err => Promise.reject(err.response));
};

// Agent

// Get Policy

export const requestDocument = {
  validate: (phcellphone, phemail) => {
    return rest.post('/policy/request-documents/validate', {
      phcellphone,
      phemail,
    });
  },
  verifySms: (phcellphone, phemail, smsCode) => {
    return rest.post('/policy/request-documents/verify-sms', {
      phcellphone,
      phemail,
      smsgen: smsCode,
    });
  },
};

// Create Insurance Contract (MegaFon)

export const createInsuranceContract = (product, phoneNumber) => {
  return rest.post(`/mfon/web-initiate/${product}`, { tcl_id: 7, phone_number: phoneNumber });
};

// Create Insurance Contract (MegaFon) FTM

export const createInsuranceContractFtm = (product, phoneNumber) =>
  rest.post(`/mfon/web-initiate/${product}-ftm`, { tcl_id: 9, phone_number: phoneNumber });

// insurance
export const getPersonalDetailsFromToken = (sessionId: string) => {
  if (MOCKS_ENABLED) {
    log.debug(`REST: /mc/find`);
    log.debug({ sessionId });
    return promiseSuccess({
      inputphname: 'Ббиаззвбф Ббиаззвби Пднович',
      phbirthdate: '1979-04-02',
      phbirthplace: 'Тайшет',
      phcellphone: '9647359538',
      phdocgive: null,
      phdocgivecode: null,
      phdocgivedate: null,
      phdocnum: '2503498529',
      // phemail: 'testy.hcins@gmail.com',
      phemail: null,
      phmiddlename: 'Пднович',
      phname: 'Ббиаззвби',
      phsex: 1,
      phsurname: 'Ббиаззвбф',
    });
  }

  return rest
    .get('/mc/find', { params: { sessionid: sessionId } })
    .then(response => response)
    .catch(err => Promise.reject(err.response));
};

export const sendLog = (type: string, message: ILogMessage, endpoint: string = '') => {
  const date = new Date().toISOString();
  const deviceInfo = getDeviceInfo();

  if (MOCKS_ENABLED) {
    log.debug(`REST: /rest/log/send`);
    log.debug({ date, type, message, endpoint });
    return promiseSuccess({}, 200);
  }

  const msg = {
    ...message,
    deviceInfo: deviceInfo,
  };

  return rest
    .post('/log/send', { date, type, message: stringify(msg), endpoint })
    .then(response => response)
    .catch(err => Promise.reject(err.response));
};

export const apiGetSamplePdfPolicy = tfrId => {
  if (MOCKS_ENABLED) {
    log.debug(`REST: /cc/policy/sample-pdf/${tfrId}`);
    log.debug({ tfrId });
    return promiseSuccess({}, 200);
  }

  return rest.get(`/cc/policy/sample-pdf/${tfrId}`, {
    params: {
      cacheBustTimestamp: Date.now(),
    },
    responseType: 'blob',
    timeout: 0,
    headers: {
      Accept: 'application/octet-stream',
    },
  });
};
