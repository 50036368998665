import styled from 'styled-components';
import { CSSProperties } from 'react';

// export const Heading = styled.h1<Partial<CSSProperties>>`
//   color: ${({ color }) => color || 'white'};
//   font-size: 2.6em;
//   font-weight: bold;
//   margin: 60px 0 20px 0;
//   padding: 0 20px;
//   position: relative;
//   text-align: ${({ textAlign }) => textAlign || 'left'};

//   ${props => props.theme.media.phone`
//     font-size: 2em;
//     margin: 15px 0 0 0;
//   `}
// `;

export const Subheading = styled.h2<Partial<CSSProperties>>`
  color: ${({ color }) => color || 'white'};
  font-size: 1.4em;
  font-weight: bolder;
  margin: 0;
  padding: 0 20px;
  position: relative;
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;
