export default [
  {
    name: 'flat',
    destination: '/insurance/flat',
  },
  {
    name: 'ourkidz',
    destination: '/insurance/ourkidz',
  },
  {
    name: 'family',
    destination: '/insurance/family',
  },
];
