import React, { FC } from 'react';
import styled from 'styled-components';
import color, { ColorProps } from '@styled-system/color';
import space, { SpaceProps } from '@styled-system/space';
import layout, { LayoutProps } from '@styled-system/layout';
import typography, { TypographyProps } from '@styled-system/typography';

import { FnClick } from 'types';

interface IOwnProps {
  onClick?: FnClick;
  disabled?: boolean;
  bgColor?: string;
  centered?: boolean;
  width?: string;
  theme?: any;
  [attr: string]: any;
}

export type TButtonProps = IOwnProps & SpaceProps & LayoutProps & ColorProps & TypographyProps;

export const Button: FC<TButtonProps> = ({ onClick, children, ...props }) => (
  <StyledButton onClick={onClick} {...props}>
    {children}
  </StyledButton>
);

Button.defaultProps = {
  onClick: () => true,
};

const StyledButton = styled.button<IOwnProps>`
  background-color: ${({ theme: { buttons }, disabled, bgColor }) =>
    disabled
      ? buttons.default.backgroundColor.disabled
      : bgColor
      ? bgColor
      : buttons.default.backgroundColor.default};
  border-radius: 22px;
  border: none;
  color: ${({ color, theme }) => (color ? color : theme.buttons.default.fontColor.default)};
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  display: block;
  font-size: 1em;
  font-weight: 600;
  font-family: inherit;
  height: 44px;
  line-height: 44px;
  margin: ${({ centered }) => (centered ? '0 auto' : 'initial')};
  padding: 0 20px;
  position: relative;
  text-align: center;
  transition: background-color 400ms ease-in-out;
  width: ${({ width }) => (width ? width : '100%')};

  ${layout};
  ${color};
  ${space};
  ${typography};

  &:hover {
    cursor: pointer;
    background-color: ${({ bgColor, theme }) =>
      bgColor ? bgColor : theme.buttons.default.backgroundColor.hover};
  }

  &:disabled {
    cursor: not-allowed;
    background-color: ${({ theme }) => theme.buttons.default.backgroundColor.disabled};
  }
`;
