import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import agentReducer from 'components/organisms/agent/redux/agent.reducer';
import common from 'store/reducers/common.reducer';
import getPolicyReducer from 'components/organisms/get-policy/redux/get-policy.reducer';
import header from 'store/reducers/header.reducer';
import insuranceOrder from 'components/organisms/insurance/redux/order.reducer';
import { insuranceProducts } from 'components/organisms/insurance/redux/products.reducer';
import { schemaReducer } from 'store/schema/schema.reducer';
import { accessibilitySettingsReducer } from 'components/organisms/accessibility-settings/redux';
import { zabolelReducer } from 'components/organisms/zabolel/redux';

export type TRootState = ReturnType<typeof rootReducer>;

export const rootReducer = combineReducers({
  accessibilitySettings: accessibilitySettingsReducer,
  agent: agentReducer,
  common,
  form: formReducer,
  getPolicy: getPolicyReducer,
  header,
  insuranceOrder,
  insuranceProducts,
  schema: schemaReducer,
  zabolel: zabolelReducer,
});
