import * as Sentry from '@sentry/browser';
import { Event } from '@sentry/browser';
import { Integrations } from '@sentry/tracing';
import { EventHint } from '@sentry/types';
import { buildInfo } from 'utils/build-info';
import { getEnv, isLocalEnv } from 'utils/env';
import { getProp } from 'utils/object.utils';

const opts = {
  attachStacktrace: true,
  beforeSend: beforeSendToSentry, // filter sending of logs to sentry
  debug: false, // print useful debug information
  denyUrls: [
    // Chrome extensions
    /extensions\//i,
    /^chrome:\/\//i,
    // Facebook flakiness
    /graph\.facebook\.com/i,
    // Facebook blocked
    /connect\.facebook\.net\/en_US\/all\.js/i,
  ],
  dsn: process.env.REACT_APP_SENTRY_DSN,
  enableOutOfMemoryTracking: false,
  environment: getEnv(),
  ignoreErrors: [
    // Facebook borked
    'fb_xd_fragment',
    'bmi_SafeAddOnload',
    'EBCallBackMessageReceived',
    'conduitPage',
    'ValidationError:',
    'Network Error',
    'Network request failed',
    'NetworkError',
  ],
  integrations: [new Integrations.BrowserTracing()],
  release: `hci@${buildInfo.COMMITHASH}`,
  tracesSampleRate: 1.0,
};

export const initSentry = (): void => Sentry.init(opts);

export const captureException = (
  err: string | Error,
  extra: any = 'error',
  tag?: string,
  user?: any
): void =>
  Sentry.withScope(scope => {
    scope.setExtra(extra, err);
    if (tag) scope.setTag(tag, user);
    if (user) scope.setUser(user);
    if (err instanceof Error) {
      Sentry.captureException(err);
      return;
    }
    Sentry.captureMessage(err);
  });

function shouldIgnoreThrownError(
  statusCode: number,
  errMessage: string,
  validatorErrName: string
): boolean {
  // skip bad login response
  if (statusCode === 401 || (errMessage && errMessage.indexOf('NOT_FOUND') > -1)) return true;

  const isValidatorErr = statusCode === 400 && !!validatorErrName;
  if (isValidatorErr) {
    // do not report validator error on dadata autocomplete address field
    if (validatorErrName && validatorErrName.indexOf('phfulladdress') > -1) return true;
    if (validatorErrName && validatorErrName.indexOf('obfulladdress') > -1) return true;
  }
  return false;
}

function beforeSendToSentry(event: Event, hint?: EventHint): Event | null {
  if (!hint) return null;
  const statusCode = getProp(hint, 'originalException.response.status');
  const path = getProp(hint, 'originalException.request.url');
  if (path && !path.includes('/log/send')) return null;
  const errMessage =
    getProp(hint, 'originalException.response.data.error.message') ||
    getProp(hint, 'originalException.response.data.message');
  const validatorErrName = getProp(hint, 'originalException.response.data.name');
  const shouldSkipReport =
    shouldIgnoreThrownError(statusCode, errMessage, validatorErrName) || isLocalEnv;
  if (shouldSkipReport) return null;
  return event;
}
