import React from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { AccessibilitySettings } from 'components/organisms/accessibility-settings';
import { LogoOneString } from 'components/molecules/Logo';

import { redirectTo, routes } from 'routes';
import { searchToSearch } from 'utils/url.utils';
import translate from 'utils/translator';
import { Box } from '@rebass/grid';

export const Footer = () => {
  const location = useLocation();
  const isAgentRoute = RegExp(`^${routes.agent}`, 'i').test(location.pathname);

  if (isAgentRoute) return null;

  return (
    <Container>
      <Box mb={'40px'}>
        <LogoContainer>
          <LogoOneString onClick={() => redirectTo(`/${searchToSearch()}`)} />
        </LogoContainer>
      </Box>
      <ContactRow>
        <List>
          <ListItem
            className='strong'
            dangerouslySetInnerHTML={{ __html: translate('footer_address2') }}
          />
        </List>
        <List>
          <ListItem className='strong'>
            <a href={`tel:${translate('contact_phone')}`}>+ 7 495 785 27 53</a>
          </ListItem>
        </List>
      </ContactRow>
      <Section>
        <div>
          <List>
            <ListItem dangerouslySetInnerHTML={{ __html: translate('footer_address1') }} />
            <ListItem>
              <a
                href='https://www.homeins.ru/about/normative-document/participants/'
                target='_blank'
                rel='noopener noreferrer'
              >
                {translate('footer_address3')}
              </a>
            </ListItem>
          </List>
          <List>
            <ListItem>
              <a href='https://www.homeins.ru/about/normative-document/politika-pers-data/'>
                {translate('personal_data_processing_policy')}
              </a>
            </ListItem>
          </List>
        </div>
        <div>
          <SecondaryList>
            <ListItem>
              <a href='https://www.homeins.ru/customer-support/feedback/form/'>
                {translate('feedback')}
              </a>
            </ListItem>
            <ListItem>
              <a href='https://www.homeins.ru/customer-support/had-an-accident/'>
                {translate('customer_support')}
              </a>
            </ListItem>
            <ListItem>
              <a href='https://www.homeins.ru/about/normative-document/'>
                {translate('information_disclosure')}
              </a>
            </ListItem>
            <ListItem>
              <a href='https://www.homeins.ru/about/pravila-strahovaniya/'>
                {translate('insurance_rules_and_insurance_rates')}
              </a>
            </ListItem>
            <ListItem>
              <AccessibilitySettings />
            </ListItem>
          </SecondaryList>
        </div>
        <div>
          <SecondaryList>
            <ListItem>
              <a href='https://www.homeins.ru/contacts/'>{translate('footer_contacts')}</a>
            </ListItem>
            <ListItem>
              <a href='https://www.homeins.ru/about/normative-document/map/'>
                {translate('sitemap')}
              </a>
            </ListItem>
            <ListItem>
              <Link to={`/insurance/prolong/form${searchToSearch()}`}>
                {translate('footer_prolong')}
              </Link>
            </ListItem>
            <ListItem>
              <Link to={`/getpolicy`}>{translate('get_policy_link')}</Link>
            </ListItem>
            <ListItem>
              <a href='https://www.homeins.ru/about/development/'>
                {translate('sustainable_development')}
              </a>
            </ListItem>
          </SecondaryList>
        </div>
      </Section>
    </Container>
  );
};

const Container = styled.footer`
  margin-bottom: 80px;
`;

const Section = styled.div`
  display: grid;
  font-family: 'GolosRegular', sans-serif;
  margin: 0 auto;
  box-sizing: border-box;
  padding: 0 15px;
  grid-template-columns: 1fr;

  ${({ theme }) => theme.media.minPhone`
    padding: 0 20px;
  `}

  /* ${({ theme }) => theme.media.minPhoneHorizontal`
    grid-template-columns: 2fr 2fr 1fr;
    gap: 20px;
  `} */

  ${({ theme }) => theme.media.minTablet`
    grid-template-columns: 5fr 3fr 2fr;
    gap: 4rem;
  `}

  ${({ theme }) => theme.media.onlyDesktop`
    grid-template-columns: 6fr 3fr 3fr;
    max-width: 1200px;
    padding: 0;
  `}
`;

const ContactRow = styled(Section)`
  ${({ theme }) => theme.media.maxPhoneHorizontal`
    ul {
      &:first-child {
        grid-row: 3;
      }

      &:last-child {
        grid-row: 1;
      }
    }
  `}
`;

const LogoContainer = styled.div`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 0 15px;
  position: relative;

  ${({ theme }) => theme.media.minPhone`
    padding: 0 20px;
  `}

  ${({ theme }) => theme.media.onlyDesktop`
    max-width: 1200px;
    padding: 0 0;
  `}
`;

const List = styled.ul`
  margin: 0;
  color: ${({ theme }) => theme.colors.black};

  a {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.black};

    &:hover {
      color: red;
    }
  }

  margin-right: 10%;

  ${({ theme }) => theme.media.minPhoneHorizontal`
    margin-right: 15%;
  `}
`;

const SecondaryList = styled(List)`
  display: none;
  ${({ theme }) => theme.media.minPhone`
    display: block;
  `}
`;

const ListItem = styled.li`
  font-size: 0.75rem;
  line-height: 15px;
  margin-bottom: 10px;
  padding: 0;

  &.strong {
    font-family: 'GolosMedium', sans-serif;
  }
`;
