import styled from 'styled-components';
import { imgLogoSvg } from 'utils/images';

export const HeaderContainer = styled.div`
  & #header {
    position: relative;
    margin-bottom: 40px;
    font-family: 'GolosRegular', sans-serif;
  }

  & #header h1 {
    margin: 0;
  }

  & .header-nav {
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 104px;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
  }

  & .logo,
  & .logo span {
    display: block;
    font-size: 0 !important;
    margin: 0;
    position: relative;
    width: 114px;
    height: 63px;
    overflow: hidden;
    color: transparent;
    transition: none;
    z-index: 20;
  }

  & .logo span {
    position: absolute;
    left: 0 !important;
    top: 0 !important;
    margin: 0;
    background: url(${imgLogoSvg}) no-repeat 0 0;
    background-size: 100% !important;
  }

  & .header-subnav {
    position: absolute;
    width: 100%;
    left: 0;
    background: white;
  }

  & .header-subnav > nav {
    position: relative;
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
    width: 100%;
    display: flex;
    padding-left: 15px;
  }

  & .header-subnav .active {
    font-weight: bold;
  }

  & .header-subnav ul {
    margin: 0;
  }

  & .header-subnav li {
    display: inline-block;
    position: relative;
    padding: 0;
  }

  & .header-subnav li a {
    color: ${({ theme }) => theme.colors.black};
    display: block;
    height: 100px;
    line-height: 100px;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.redPrimary};
    }
  }

  & .header-subnav a:hover,
  & .header-subnav .active a,
  & .header-nav .subnav a:hover,
  & .header-nav .subnav .active a {
    color: ${({ theme }) => theme.colors.redPrimary};
  }

  // responsive
  @media only screen and (max-width: 1024px) {
    .header-home {
      background-position: calc(100% + 360px) 0 !important;
    }
  }

  @media only screen and (max-width: 768px) {
    #header.responsive nav .subnav {
      display: block;
      border-top: 1px solid ${({ theme }) => theme.colors.borderPrimary};
      border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
      margin: 15px 0 0;
      padding: 15px 0 0;
    }

    .header-subnav {
      display: none;
    }

    .header-home {
      background-position: calc(100% + 260px) 0 !important;
    }
    #header {
      margin-bottom: 0;
    }
  }

  @media only screen and (min-width: 415px) {
    .header-ourkidz:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25);
    }
    .n3__wcag__color_black .header-ourkidz:before,
    .n3__wcag__color_blue .header-ourkidz:before,
    .n3__wcag__images_off .header-ourkidz:before {
      content: none;
    }
    .header-ourkidz h1,
    .header-ourkidz h4 {
      color: white;
      position: relative;
    }
    .n3__wcag__images_off .header-ourkidz h1,
    .n3__wcag__images_off .header-ourkidz h4 {
      color: #151d2b;
    }
    .n3__wcag__images_off .header-home h1,
    .n3__wcag__images_off .header-home h4 {
      color: #151d2b;
    }
  }

  @media only screen and (max-width: 414px) {
    .logo {
      max-width: 90px;
      height: auto;
    }
  }
`;
