import React from 'react';
import styled from 'styled-components';
import { useDispatch } from 'react-redux';
import { iconClose, iconError, iconInfoPublic, iconSuccess } from 'utils/images';
import { useTypedSelector } from 'store';
import { IFlashMessage } from 'types';

import { actionRemoveFlashMessage } from 'components/molecules/FlashMessages';

export const FlashMessages: React.FC = () => {
  const dispatch = useDispatch();
  const removeFlash = id => dispatch(actionRemoveFlashMessage(id));
  const flashes = useTypedSelector<IFlashMessage[]>(state => state.common.flashes);
  if (!flashes || !flashes.length) return null;

  const handleRemove = (id: IFlashMessage['id']) => removeFlash(id);

  return (
    <>
      {flashes.map(flash => (
        <Container key={flash.id} className='notice-message-wrapper'>
          <div className={`notice-message ${flash.type}`}>
            {flash.text}{' '}
            {flash.closeable && <button onClick={() => handleRemove(flash.id)}>close</button>}
          </div>
        </Container>
      ))}
    </>
  );
};

const Container = styled.div`
  & .notice-message {
    z-index: 1100;
    padding: 5px 60px;
    cursor: pointer;
    font-size: 100%;
    line-height: normal;
    background-size: 24px !important;
    position: fixed;
    width: 100%;
    box-sizing: border-box;
  }

  & .notice-message button {
    cursor: pointer;
    opacity: 0.4;
    border: 0;
    padding: 0;
    font-size: 0;
    position: absolute;
    right: 20px;
    top: calc(50% - 10px);
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: url(${iconClose}) no-repeat 50% 50%;
    background-size: 50%;
    transition: 0.4s;
  }

  & .notice-message:hover button {
    opacity: 1;
  }

  & .notice-message.info button {
    background-color: #967132;
  }

  & .notice-message.success button {
    background-color: #5a7052;
  }

  & .notice-message.error button {
    background-color: #b32f2d;
  }

  & .notice-message.info {
    color: #967132;
    background: #f9c91294 url(${iconInfoPublic}) no-repeat 20px 50%;
  }

  & .notice-message.success {
    color: #5a7052;
    background: #e9feda url(${iconSuccess}) no-repeat 20px 50%;
  }

  & .notice-message.error {
    color: #b32f2d;
    background: #ffecec url(${iconError}) no-repeat 20px 50%;
  }

  &.notice-message-wrapper:after {
    content: 'text';
    position: relative;
    width: 100%;
    min-height: 33px;
    visibility: hidden;
    display: block;
  }
`;
