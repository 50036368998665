import * as React from 'react';
import { Fragment } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { withRouter } from 'react-router-dom';

import { FakeLink } from 'components/atoms/FakeLink';
import translate from 'utils/translator';

import { AccessibilityModal } from 'components/organisms/accessibility-settings/components/AccessibilityModal';
import {
  initializeBodyClasses,
  toggleOverlayOnBgImages,
} from 'components/organisms/accessibility-settings/utils';
import { toggleSettings, setConfig } from 'components/organisms/accessibility-settings/redux';
import { colors } from 'styles';

interface IStateDispatchProps {
  toggleSettings: () => void;
  setConfig: (config: any) => void;
}

interface IStateProps {
  accessibilitySettingsOpen: boolean;
  accessibilitySettingsConfig: any;
}

type IProps = IStateProps & IStateDispatchProps & RouteComponentProps;

class AccessibilitySettingsComponent extends React.Component<IProps> {
  componentDidMount() {
    initializeBodyClasses(); // set accessibility config classes on load
    this.hideImages();
  }

  componentDidUpdate(prevProps) {
    const routeChanged = this.props.location !== prevProps.location;
    if (routeChanged) this.onRouteChanged();
  }

  onModalClose = currentConfig => {
    this.props.setConfig(currentConfig);
    this.props.toggleSettings();
  };

  onSettingChange = currentConfig => {
    this.props.setConfig(currentConfig);
  };

  onRouteChanged = () => {
    this.hideImages();
  };

  hideImages = () => {
    const { accessibilitySettingsConfig } = this.props;
    toggleOverlayOnBgImages(accessibilitySettingsConfig.images);
  };

  render() {
    const { accessibilitySettingsOpen } = this.props;
    return (
      <Fragment>
        <FakeLink
          color={colors.black}
          lineHeight={1.7}
          onClick={this.props.toggleSettings}
          fontSize='inherit'
        >
          {translate('site_version_for_visually_impaired')}
        </FakeLink>
        <AccessibilityModal
          isOpen={accessibilitySettingsOpen}
          onHide={this.onModalClose}
          onClose={this.onModalClose}
          onSettingChange={this.onSettingChange}
        />
      </Fragment>
    );
  }
}

const mapStateToProps = state => {
  return {
    accessibilitySettingsOpen: state.accessibilitySettings.isOpen,
    accessibilitySettingsConfig: state.accessibilitySettings.config,
  };
};

const mapDispatchToProps = { toggleSettings, setConfig };

export const AccessibilitySettings = withRouter(
  connect<IStateProps, IStateDispatchProps, {}>(
    mapStateToProps,
    mapDispatchToProps
  )(AccessibilitySettingsComponent)
);
