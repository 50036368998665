import { getProp, omitEmptyValues } from 'utils';
import { AUTH_TYPE } from 'components/organisms/agent/constants';

export const prepareValuesForSubmit = (formValues = {} as any, tclId, authType, agentId) => {
  const apiFriendlyValues = {
    tcl_id: tclId,
    auth_type: authType,
    hcfbagentid:
      authType === AUTH_TYPE.AGENT
        ? agentId
        : typeof formValues.agentId === 'string'
        ? formValues.agentId
        : undefined,
    rowdate_from: formValues.insuranceDateFrom,
    rowdate_to: formValues.insuranceDateTo,
    paymentdate_from: formValues.paymentDateFrom,
    paymentdate_to: formValues.paymentDateTo,
    startdate_from: formValues.validityDateFrom,
    startdate_to: formValues.validityDateTo,
    insuranceperiod: getProp(formValues, 'insurancePeriod.value', undefined),
    orderstatusid: formValues.orderStatus && formValues.orderStatus.map(item => item.value),
    phcellphone: formValues.phcellphone,
    card_orderid: formValues.cardOrderId,
    contractnumber: formValues.contractNumber,
  };
  return omitEmptyValues(apiFriendlyValues);
};

export const transformOrderStatusToOptions = rawOptions => {
  if (!rawOptions) return [];
  const options = rawOptions.map(item => ({
    value: item.tosId,
    label: item.tosVal,
  }));
  return options;
};
