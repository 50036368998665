export const buttonStyles = {
  default: {
    backgroundColor: {
      default: '#E7372C',
      hover: '#000000',
      inverted: '#ffffff',
      disabled: '#b9b9b9',
    },
    fontColor: {
      default: '#ffffff',
      inverted: '#E7372C',
    },
    borderColor: {
      inverted: '#E7372C',
    },
  },
};
