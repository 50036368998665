import React from 'react';
import { RadioGroup as ReactRadioGroup } from 'react-radio-group';
import { WrappedFieldInputProps } from 'redux-form';
import { noop } from 'lodash-es';

import { Column } from 'components/atoms/Column';
import { Row } from 'components/atoms/Row';
import { Radio } from 'components/atoms/form/Radio';
import { ErrorMessage } from 'components/atoms/ErrorMessage';

interface IRadioGroupProps extends WrappedFieldInputProps {
  options: any[];
  error?: string | object;
  touched?: boolean;
  disabled?: boolean;
  defaultValue?: unknown;
  label?: string | React.ReactNode;
  fontColor?: string;
  fontSize?: string;
}

export const RadioGroup: React.SFC<IRadioGroupProps> = ({
  options,
  error,
  touched,
  disabled,
  defaultValue = null,
  onChange,
  value,
  label,
  fontColor,
  fontSize,
  ...radioProps
}) => {
  const onRadioChange = (value, evt) => onChange?.(evt);

  return (
    <Column flexDirection='column'>
      {label}
      <Row>
        <ReactRadioGroup
          name={radioProps.name}
          selectedValue={value}
          onChange={disabled ? noop : onRadioChange}
        >
          {options.map(option => (
            <Radio
              key={option.value}
              value={option.value}
              label={option.label}
              disabled={disabled}
              name={radioProps.name}
              fontColor={fontColor}
              fontSize={fontSize}
            />
          ))}
        </ReactRadioGroup>
      </Row>
      {touched && error && (
        <ErrorMessage data-cy={`error-msg-${radioProps.name}`}>{error}</ErrorMessage>
      )}
    </Column>
  );
};
