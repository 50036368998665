import React, { Component, Fragment } from 'react';
import SelectLib, { Async as AsyncSelectLib, components } from 'react-select';
import { WrappedFieldInputProps } from 'redux-form';

import { Column } from 'components/atoms/Column';
import { DropdownArrow } from 'components/atoms/DropdownArrow';
import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { FloatLabel } from 'components/atoms/form/FloatLabel';
import { theme as mainTheme } from 'styles/theme';
import { FnWithArgs, IValidatorResult, Layout } from 'types';

interface ISelectProps {
  placeholder?: string;
  options: { value: any; label: string }[];
  value: string;
  customStyles?: any;
  defaultValue?: any;
  error?: string | IValidatorResult;
  input?: WrappedFieldInputProps;
  layout?: Layout;
  loadOptions?: AsyncSelectLib<any>['loadOptions'];
  name?: string;
  touched?: boolean;
  disabled?: boolean;
  onChange?: FnWithArgs;
  onBlur?: FnWithArgs;
  id?: string;
  borderColor?: string;
  borderRadius?: string;
  fontSize?: string;
  dropdownArrowComponent?: React.ReactNode;
}

interface IISelectPropsState {
  selectedOption: any;
}

export class Select extends Component<ISelectProps, IISelectPropsState> {
  static defaultProps = {
    layout: 'horizontal',
    options: [{ value: '', label: '' }],
  };

  state = {
    selectedOption: this.props.defaultValue || null, // should be removed
  };

  handleChange = selectedOption => {
    const { input, onChange } = this.props;
    this.setState({ selectedOption });
    onChange?.(selectedOption);

    // redux-form
    input?.onChange?.(selectedOption);
  };

  handleBlur = e => {
    const { input, onBlur } = this.props;
    const { selectedOption } = this.state;

    if (onBlur) onBlur(selectedOption);

    // redux-form
    input?.onBlur?.(this.state.selectedOption);
  };

  render() {
    const {
      options,
      error,
      placeholder,
      touched,
      customStyles,
      layout = 'vertical',
      value,
      disabled,
      ...props
    } = this.props;
    const selectStyles = customStyles ? { ...styles, ...customStyles } : styles;
    const isAsync = !!props.loadOptions;
    const SelectComponent: React.ElementType = isAsync ? AsyncSelectLib : SelectLib;

    return (
      <Fragment>
        <Column
          width='100%'
          flexDirection={layout === 'vertical' ? 'column' : 'row'}
          data-cy={props.name}
          position='relative'
        >
          {placeholder && (
            <FloatLabel
              htmlFor={props.name || ''}
              floated={!!value}
              hasError={!!error}
              touched={touched}
            >
              {placeholder}
            </FloatLabel>
          )}
          <SelectComponent
            isSearchable={false}
            components={{
              DropdownIndicator: dropdownIndicatorProps => (
                <DropdownIndicator
                  {...dropdownIndicatorProps}
                  dropdownArrowComponent={props.dropdownArrowComponent}
                />
              ),
              IndicatorSeparator: () => null,
            }}
            placeholder=''
            {...props}
            isDisabled={disabled}
            cacheOptions={isAsync} // because of async select
            defaultOptions={isAsync} // because of async select
            styles={selectStyles}
            onChange={this.handleChange}
            onBlur={this.handleBlur}
            options={options}
            layout={layout}
            value={value}
            touched={touched}
            error={error}
            menuPortalTarget={document.body}
            theme={theme => ({
              ...theme,
              ...mainTheme,
            })}
          />
        </Column>
        {error && touched && (
          <ErrorMessage textAlign='left' data-cy={`error-msg-${props.name}`}>
            {error}
          </ErrorMessage>
        )}
      </Fragment>
    );
  }
}

function DropdownIndicator(props) {
  const ArrowComponent = props.dropdownArrowComponent || DropdownArrow;
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <ArrowComponent dropdownActive={props.selectProps.menuIsOpen} />
      </components.DropdownIndicator>
    )
  );
}

const styles = {
  option: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? '#9ea8b9' : '#000',
    backgroundColor: state.isSelected ? '#f0f4f8' : 'white',
    '&:hover': {
      cursor: 'pointer',
    },
  }),
  container: (styles, state) => ({
    ...styles,
    width: '100%',
    position: 'relative',
    border: `1px solid ${props => (props.touched && props.error ? '#E7372C' : 'none')}`,
  }),
  placeholder: (styles, state) => ({
    ...styles,
    color: '#9ea8b9',
    fontSize: `${({ theme }) => theme.fontSizes.base}`,
    /* IE9, IE10, IE11 */
    '@media screen and (min-width:0\0)': {
      fontSize: '16px',
    },
  }),
  valueContainer: (styles, state) => ({
    ...styles,
    padding: '0 8px',
    /* IE9, IE10, IE11 */
    '@media screen and (min-width:0\0)': {
      minHeight: '44px',
    },
  }),
  singleValue: (styles, state) => ({
    ...styles,
    color: state.isDisabled ? 'rgb(84, 84, 84)' : `${({ theme }) => theme.colors.textPrimary}`,
    borderRadius: `${({ theme }) => theme.radii.box}`,
  }),
  indicatorsContainer: styles => ({
    ...styles,
    padding: 0,
  }),
  menu: (styles, state) => ({
    ...styles,
    position: 'absolute',
    borderRadius: `${({ theme }) => theme.radii.box}`,
    background: 'white',
    zIndex: state.theme.zIndices.selectMenuContainer,
  }),
  menuPortal: styles => ({
    ...styles,
    zIndex: 10,
  }),
  multiValue: styles => ({
    ...styles,
    lineHeight: 2,
  }),
  control: (styles, state, props) => ({
    ...styles,
    appearance: 'none',
    background: state.hasValue || state.isFocused || state.isDisabled ? '#fff' : '#f0f4f8',
    border: `1px solid ${props =>
      state.selectProps?.touched && state.selectProps?.error ? '#E7372C' : 'none'}`,
    borderColor: state.selectProps?.borderColor
      ? state.selectProps?.borderColor
      : state.selectProps?.touched && state.selectProps?.error
      ? '#f0f4f8'
      : '#929eb0',
    borderRadius: state.selectProps?.borderRadius ? state.selectProps?.borderRadius : '3px',
    boxShadow: 'none',
    boxSizing: 'border-box',
    color: state.isDisabled ? '#9ea8b9' : '#000',
    fontFamily: 'inherit',
    fontSize: state.selectProps?.fontSize
      ? state.selectProps?.fontSize
      : `${({ theme }) => theme.fontSizes.base}`,
    lineHeight: '46px',
    outline: 'none',
    transition: '.4s',
    padding: state.selectProps?.isMulti ? '2px' : 0,
    minHeight: '50px',
    width: '100%',
    margin: state.selectProps?.layout === 'vertical' ? '15px 0 0 0' : 0,

    '&:hover': {
      cursor: 'pointer',
    },
    '&:focus': {
      backgroundColor: '#fff',
      border: '1px solid #151d2b',
    },
    "input:not([type='submit'])": {
      margin: 0,
    },
  }),
};
