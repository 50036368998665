import React from 'react';
import styled from 'styled-components';
import { theme } from 'styles';

interface IOwnProps {
  children: React.ReactNode;
  isSubmenuItem?: boolean;
  level?: 1 | 2 | 3;
  withBorder?: boolean;
}

export const NavigationMenuItem: React.FC<IOwnProps> = ({
  children,
  isSubmenuItem = false,
  level = 2,
  withBorder = true,
}) => (
  <Wrapper isSubmenuItem={isSubmenuItem} level={level} withBorder={withBorder}>
    {children}
  </Wrapper>
);

const Wrapper = styled.span<Partial<IOwnProps>>`
  display: flex;
  position: relative;
  border-bottom: ${({ theme, withBorder }) =>
    withBorder ? `1px solid  ${theme.colors.borderPrimary}` : 'none'};

  a {
    color: ${theme.colors.black};
    text-decoration: none;
    font-size: 1.125rem;
    line-height: 1.5;
    height: 80px;
    font-family: ${({ isSubmenuItem }) => (isSubmenuItem ? 'GolosRegular' : 'GolosMedium')};
    padding-left: 15px;
    display: flex;
    align-items: center;
  }

  ${({ theme }) => theme.media.maxTablet`
    &:last-of-type {
      margin-bottom: 0;
    }
  `}

  ${({ theme }) => theme.media.minPhone`
    a {
      padding-left: 20px;
    }
  `}
`;
