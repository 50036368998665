import styled from 'styled-components';
import { iconArrDownSvg } from 'utils/images';

export const DropdownArrow = styled.div.attrs({
  className: 'arrow',
})<{ dropdownActive?: boolean }>`
  content: '';
  display: inline-block;
  min-width: 11px;
  min-height: 5px;
  position: relative;
  background: url(${iconArrDownSvg}) no-repeat center;
  ${({ dropdownActive }) => dropdownActive && 'transform: rotate(-180deg)'};
`;
