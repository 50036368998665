import { initialize } from 'redux-form';
import * as CONSTANTS from './agent.constants';
import {
  getProductsForAgent,
  getSalesHistoryAgent,
  getOrderStatus,
  getAuthType,
  getInformationDocsAgent,
  restCalculatePremium,
} from 'rest/api.agent';
import { actionShowPreloader, actionHidePreloader } from 'store/actions/common.actions';
import { actionGetSchema } from 'store/schema/schema.actions';
import { AUTH_TYPE, SCREEN_NAMES } from 'components/organisms/agent/constants';
import { store } from 'store';
import { ObjectGeneric } from 'types';
import { log } from 'utils';

/**
 * Redux actions
 */

/**
 * Set Page Content Type
 *
 * @param contentType
 * @returns {{data: {contentType: string}, type: string}}
 */

export const setPageContentType = contentType => ({
  type: CONSTANTS.SET_PAGE_CONTENT_TYPE,
  data: { contentType },
});

/**
 * Set Token
 *
 * @param token
 * @returns {{data: {token: string}, type: string}}
 */

export const setToken = token => ({
  type: CONSTANTS.SET_TOKEN,
  data: { token },
});

/**
 * Set Channel Id
 *
 * @param channelId
 * @returns {{data: {channelId: number}, type: string}}
 */

export const saveChannelId = channelId => ({
  type: CONSTANTS.SET_CHANNEL_ID,
  data: { channelId },
});

/**
 * Set Agent Id
 *
 * @param agentId
 * @returns {{data: {agentId: string}, type: string}}
 */

export const setAgentId = agentId => ({
  type: CONSTANTS.SET_AGENT_ID,
  data: { agentId },
});

/**
 * Set login username
 *
 * @param {string} login
 * @returns {{data: {login: string}, type: string}}
 */

export const setLogin = login => ({
  type: CONSTANTS.SET_LOGIN,
  data: { login },
});

/**
 * Set Products
 *
 * @param products
 * @returns {{data: {products: {...}[]}, type: string}}
 */

export const setProducts = products => ({
  type: CONSTANTS.SET_PRODUCTS,
  data: { products },
});

/**
 * Set Product Type
 *
 * @param selectedProductType
 * @returns {{data: {selectedProductType: string}, type: string}}
 */

export const setProductType = selectedProductType => ({
  type: CONSTANTS.SET_PRODUCT_TYPE,
  data: { selectedProductType },
});

/**
 * Set Product Duration
 *
 * @param selectedProductDuration
 * @returns {{data: {selectedProductDuration: string}, type: string}}
 */

export const setProductDuration = selectedProductDuration => ({
  type: CONSTANTS.SET_PRODUCT_DURATION,
  data: { selectedProductDuration },
});

/**
 * Set Product Variant
 * @param selectedProductVariant
 * @returns {{data: {selectedProductVariant: {...}[]}, type: string}}
 */

export const setProductOption = selectedProductVariant => ({
  type: CONSTANTS.SET_PRODUCT_OPTION,
  data: { selectedProductVariant },
});

export const setProductVariant = selectedProductVariant => (dispatch, getState) => {
  if (!selectedProductVariant) {
    dispatch(setProductOption(selectedProductVariant));
    return;
  }

  const state = getState();
  const {
    agent: { channelId },
  } = state;
  const { tfrId } = selectedProductVariant;
  dispatch(setProductOption(selectedProductVariant));
  if (tfrId && channelId) dispatch(actionGetSchema(null, tfrId, channelId));
};

/**
 * Set Product Variant index
 * @param selectedProductVariantIndex
 * @returns {{data: {selectedProductVariantIndex: string}, type: string}}
 */

export const setProductVariantIndex = selectedProductVariantIndex => ({
  type: CONSTANTS.SET_PRODUCT_OPTION_INDEX,
  data: { selectedProductVariantIndex },
});

/**
 * Set Order Id
 *
 * @param orderId
 * @returns {{data: {orderId: string}, type: string}}
 */

export const setOrderId = orderId => ({
  type: CONSTANTS.SET_ORDER_ID,
  data: { orderId },
});

/**
 * Set Payment Id
 *
 * @param paymentId
 * @returns {{data: {id: string}, type: string}}
 */

export const setPaymentId = paymentId => ({
  type: CONSTANTS.SET_PAYMENT_ID,
  data: { paymentId },
});

/**
 * Set Payment Url
 *
 * @param paymentUrl
 * @returns {{data: {paymentUrl: string}, type: string}}
 */

export const setPaymentUrl = paymentUrl => ({
  type: CONSTANTS.SET_PAYMENT_URL,
  data: { paymentUrl },
});

/**
 * Set Contract Number
 *
 * @param contractNumber
 * @returns {{data: {contractNumber: string}, type: string}}
 */

export const setContractNumber = contractNumber => ({
  type: CONSTANTS.SET_CONTRACT_NUMBER,
  data: { contractNumber },
});

/**
 * Get Products
 *
 * @param token
 * @param channelId (tcl_id)
 * @returns {Function}
 */

export const getProducts = (token, channelId) => async dispatch => {
  try {
    const response = await getProductsForAgent(token, channelId);
    dispatch(setProducts(response.data.products));
    return true;
  } catch (error) {
    log.error('getProducts:: error: ', error.response);
    return false;
  }
};

/**
 * Set Form Values
 *
 * @param formValues
 * @returns {{data: {formValues: *}, type: string}}
 */

export const setFormValues = formValues => ({
  type: CONSTANTS.SET_FORM_VALUES,
  data: { formValues },
});

/**
 * Reset Form Values
 *
 * @param
 * @returns void
 */

export const resetFormValues = () => ({
  type: CONSTANTS.RESET_FORM_VALUES,
});

/**
 * Set Active Screen
 *
 * @param activeScreen: string
 * @returns {{data: {activeScreen: *}, type: string}}
 */

export const setActiveScreen = activeScreen => ({
  type: CONSTANTS.SET_ACTIVE_SCREEN,
  data: { activeScreen },
});

/**
 * Get Agent Auth Type
 *
 * @param agentId
 * @returns {Object}
 */

export const getAgentAuthType = (
  token: string,
  channelId: number,
  agentId: string
) => async dispatch => {
  try {
    dispatch(actionShowPreloader());
    const response = await getAuthType(token, channelId, agentId);
    const {
      data: { authType },
    } = response;
    dispatch(setAuthType(authType));
    dispatch(actionHidePreloader());
    return response.data;
  } catch (err) {
    dispatch(actionHidePreloader());
  }
};

/**
 * Get Sales History
 *
 * @param token
 * @param channelId (tcl_id)
 * @param params query params
 * @returns {Object}
 */

export const getSalesHistory = (
  token: string = '',
  channelId: number,
  params: ObjectGeneric | null = null,
  withPreloader: boolean = true
) => async dispatch => {
  try {
    withPreloader && dispatch(actionShowPreloader());
    dispatch(getSalesHistoryStart());
    const response = await getSalesHistoryAgent(token, channelId, params);
    const {
      data: { authType },
    } = response;
    dispatch(setAuthType(authType));
    withPreloader && dispatch(actionHidePreloader());
    dispatch(getSalesHistorySuccess(response.data));

    if (authType === AUTH_TYPE.AGENT) {
      dispatch(setAgentIdConfirmed(true));
    }

    if (withPreloader && authType === AUTH_TYPE.PK) {
      dispatch(setActiveScreen(SCREEN_NAMES.SALES_HISTORY));
    }
    return response.data;
  } catch (err) {
    withPreloader && dispatch(actionHidePreloader());
    dispatch(getSalesHistoryFail(err));
  }
};

/**
 * Get Sales History loading start
 *
 * @param
 * @returns void
 */

export const getSalesHistoryStart = () => ({
  type: CONSTANTS.SALES_HISTORY,
});

/**
 * Get Sales History loading success
 *
 * @param
 * @returns void
 */

export const getSalesHistorySuccess = response => ({
  type: CONSTANTS.SALES_HISTORY_SUCCESS,
  data: response,
});

/**
 * Get Sales History loading fail
 *
 * @param
 * @returns void
 */

export const getSalesHistoryFail = error => ({
  type: CONSTANTS.SALES_HISTORY_FAIL,
  data: error,
});

/**
 * Reset Sales History
 *
 * @param
 * @returns void
 */

export const resetSalesHistory = () => ({
  type: CONSTANTS.RESET_SALES_HISTORY,
});

/**
 * Get Order Statuses
 *
 * @param token
 * @param channelId (tcl_id)
 * @returns {Object}
 */

export const getOrderStatuses = (token, channelId) => async dispatch => {
  try {
    dispatch(getOrderStatusesStart());
    const response = await getOrderStatus(token, channelId);
    dispatch(getOrderStatusesSuccess(response.data));
    return response;
  } catch (err) {
    dispatch(actionHidePreloader());
    dispatch(getOrderStatusesFail(err));
  }
};

/**
 * Get Order Statuses loading start
 *
 * @param
 * @returns void
 */

export const getOrderStatusesStart = () => {
  return {
    type: CONSTANTS.ORDER_STATUSES,
  };
};

/**
 * Get Order Statuses loading success
 *
 * @param
 * @returns void
 */

export const getOrderStatusesSuccess = response => {
  return {
    type: CONSTANTS.ORDER_STATUSES_SUCCESS,
    data: response,
  };
};

/**
 * Get Order Statuses loading fail
 *
 * @param
 * @returns void
 */

export const getOrderStatusesFail = error => {
  return {
    type: CONSTANTS.ORDER_STATUSES_FAIL,
    data: error,
  };
};

/**
 * Get Information Docs
 *
 * @param token
 * @param channelId (tcl_id)
 * @returns {Object}
 */

export const getInformationDocs = (channelId, token) => async dispatch => {
  try {
    const response = await getInformationDocsAgent(channelId, token);
    if (response && response.data && response.data.length) {
      dispatch(getInformationDocsSuccess(response.data));
    }
    return response;
  } catch (err) {
    dispatch(getInformationDocsFail(err));
  }
};

/**
 * Get Information Docs loading start
 *
 * @param
 * @returns void
 */

export const getInformationDocsSuccess = response => {
  return {
    type: CONSTANTS.INFORMATION_DOCS_SUCCESS,
    data: response,
  };
};

/**
 * Get Information Docs loading fail
 *
 * @param
 * @returns void
 */

export const getInformationDocsFail = error => {
  return {
    type: CONSTANTS.INFORMATION_DOCS_FAIL,
    data: error,
  };
};

/**
 * Reset Information Docs
 *
 * @param
 * @returns void
 */

export const resetInformationDocs = () => {
  return {
    type: CONSTANTS.INFORMATION_DOCS_RESET,
  };
};

/**
 * Set Auth Type
 *
 * @param authType: string
 * @returns {{data: {authType: *}, type: string}}
 */

export const setAuthType = authType => ({
  type: CONSTANTS.SET_AUTH_TYPE,
  data: { authType },
});

/**
 * Set Agent ID input disabled
 *
 * @param disabled: boolean
 * @returns {{data: {agentIdInputDisabled: *}, type: string}}
 */

export const setAgentIdInputDisabled = agentIdInputDisabled => ({
  type: CONSTANTS.SET_AGENT_ID_INPUT_DISABLED,
  data: { agentIdInputDisabled },
});

/**
 * Set Agent ID input disabled
 *
 * @param disabled: boolean
 * @returns {{data: {agentIdInputDisabled: *}, type: string}}
 */

export const setAgentIdConfirmed = setAgentIdConfirmed => ({
  type: CONSTANTS.SET_AGENT_ID_CONFIRMED,
  data: { setAgentIdConfirmed },
});

/**
 * Logout agent
 *
 * @param dispatch: fn
 * @returns void
 */

export const logoutAgent = () => dispatch => {
  dispatch(resetAgentToDefaultValues());
  dispatch(saveChannelId(undefined));
  dispatch(setToken(undefined));
  dispatch(setLogin(undefined));
  dispatch(setPageContentType('login'));
  dispatch(setActiveScreen(SCREEN_NAMES.LOGIN));
  dispatch(setAuthType(AUTH_TYPE.AGENT));
  dispatch(setAgentId(undefined));
  dispatch(setAgentIdConfirmed(false));
  dispatch(setAgentIdInputDisabled(false));
  dispatch(setProducts(undefined));
  dispatch(resetSalesHistory());
  dispatch(resetFormValues());
  dispatch(resetInformationDocs());

  // reset all forms
  dispatch(initialize('agent-id', {}));
  dispatch(initialize('agent-login', {}));
  dispatch(initialize('agentSalesFilterForm', {}));
  dispatch(initialize('agentCalculationForm', {}));
};

/**
 * Set sales filter countdown start date
 *
 * @param startDate: Date
 * @returns void
 */

export const setSalesFilterCountdownStartDate = startDate => ({
  type: CONSTANTS.SET_FILTER_COUNTDOWN_START_DATE,
  data: { startDate },
});

/**
 * Reset sales filter countdown
 *
 * @param dispatch: fn
 * @returns void
 */

export const resetSalesFilterCountdown = () => dispatch => {
  const now = new Date();
  dispatch(setSalesFilterCountdownStartDate(now.getTime()));
};

/**
 * Set sales history table state
 *
 * @param tableState: Object
 * @returns void
 */

export const setSalesHistoryTableState = tableState => ({
  type: CONSTANTS.SET_SALES_HISTORY_TABLE_STATE,
  data: { tableState },
});

/**
 * Reset sales history table state
 *
 * @param tableState: Object
 * @returns void
 */

export const resetSalesHistoryTableState = (tableState?: ObjectGeneric) => ({
  type: CONSTANTS.RESET_SALES_HISTORY_TABLE_STATE,
});

/**
 * Set premium calculation
 *
 * @param data: Object
 * @returns void
 */

export const actionSetPremiumCalculation = data => ({
  type: CONSTANTS.SET_PREMIUM_CALCULATION,
  data: data,
});

/**
 * Reset premium calculation
 *
 * @returns void
 */

export const actionResetPremiumCalculation = () => ({
  type: CONSTANTS.SET_PREMIUM_CALCULATION,
  data: null,
});

/**
 * Restore agent redux store data
 *
 * @param dispatch: fn
 * @returns void
 */

export const resetAgentToDefaultValues = () => dispatch => {
  dispatch(setPageContentType('form'));
  dispatch(setProductType(undefined));
  dispatch(setProductDuration(undefined));
  dispatch(setProductVariant(undefined));
  dispatch(setOrderId(undefined));
  dispatch(setPaymentId(undefined));
  dispatch(setPaymentUrl(undefined));
  dispatch(setContractNumber(undefined));
  dispatch(setFormValues(undefined));
  dispatch(actionResetPremiumCalculation());
};

/**
 * Get Products
 *
 * @param token
 * @param channelId (tcl_id)
 * @returns {Function}
 */

export const actionCalculatePremium = (onSuccess?, onError?) => async dispatch => {
  try {
    dispatch(actionShowPreloader());
    const { agent, form } = store.getState();
    const { selectedProductVariant, token } = agent;
    const sumInsured = form?.agentCalculationForm?.values?.sum_insured;
    const { tfrId } = selectedProductVariant;
    if (!sumInsured) return;
    const response = await restCalculatePremium(tfrId, sumInsured, token);
    dispatch(actionSetPremiumCalculation(response.data));
    onSuccess?.();
    return response;
  } catch (error) {
    onError?.(error);
  } finally {
    dispatch(actionHidePreloader());
  }
};

/**
 * Set sales history limits
 *
 * @param token
 * @returns {{data: {pastMonthsAllowed: number, minDateThresholdDay: string}, type: string}}
 */

export const setSalesHistoryLimitis = (pastMonthsAllowed: number, minDateThresholdDay: string) => ({
  type: CONSTANTS.SET_SALES_HISTORY_LIMITS,
  data: { pastMonthsAllowed, minDateThresholdDay },
});

/**
 * Set agent's allowed payment method
 *
 * @param
 * @returns void
 */

export const setPaymentMethod = (method: string) => ({
  type: CONSTANTS.SET_PAYMENT_METHOD,
  data: method,
});
