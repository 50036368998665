import { SyntheticEvent } from 'react';

/**
 * Removes mask and converts to number
 *
 * @param regex - charaters to be deleted
 * @param special - final changes before converting to number
 * @returns {function(*)}
 */
const unmaskCommon = (regex: object, special?: (a: string) => string): any => {
  return (e: any): number | string => {
    if (e && e.target && e.target.value) {
      let stripped = e.target.value.replace(regex, '');
      stripped = special ? special(stripped) : stripped;
      if (stripped.length > 0) {
        return stripped;
      } else {
        return '';
      }
    } else {
      return '';
    }
  };
};

export const unmaskTel = (e: SyntheticEvent | string): number | string => {
  return typeof e === 'string'
    ? e.replace(/\D/g, '').slice(1)
    : unmaskCommon(/\D/g, val => val.slice(1))(e);
};

export const unmaskPassport = (e: any): number | string => unmaskCommon(/\D/g)(e);

export const unmaskNonDigitChars = (e: any): number | string => unmaskCommon(/\D/g)(e);

export const unmaskTaxnumber = (value: string): string => {
  if (typeof value !== 'string') return '';
  return value.replace(/\D/g, '');
};

export const unmaskCardExp = (e: any): number | string => unmaskCommon(/\D/g)(e);
