import * as CONSTANTS from './get-policy.constants';
import { IGetPolicyReducer } from 'types/store/reducers';

const initialState: IGetPolicyReducer = {
  attemptsCount: 0,
  processSteps: ['getSmsCode'],
  smsCodeError: null,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.SET_PROCESS_STEP:
      return {
        ...state,
        processSteps: action.data.steps,
      };

    case CONSTANTS.SET_SMS_CODE_ERROR:
      return {
        ...state,
        smsCodeError: action.data.error,
      };

    case CONSTANTS.SET_ATTEMPTS_COUNT:
      return {
        ...state,
        attemptsCount: action.data.attempt,
      };

    default:
      return state;
  }
};
