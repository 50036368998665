import React, { Component } from 'react';
import moment from 'moment';
import styled from 'styled-components';
import DatePickerLib, { ReactDatePickerProps } from 'react-datepicker';
import { WrappedFieldInputProps } from 'redux-form';
import InputMask from 'react-input-mask';

import { FloatLabel } from 'components/atoms/form/FloatLabel';
import { StyledInput } from 'components/atoms/form/Input';
import { Column } from 'components/atoms/Column';
import { ErrorMessage } from 'components/atoms/ErrorMessage';
import translate from 'utils/translator';
import { dateFormat, isValidMoment } from 'utils';
import { FnWithArgs } from 'types';

// localization
setLocalization();

interface IDatePicerProps {
  placeholder: string;
  disabled?: boolean;
  error?: string | object;
  input: WrappedFieldInputProps;
  name?: string;
  touched?: boolean;
  value?: any;
  withPortal?: boolean;
  onChange?: FnWithArgs;
  onBlur?: FnWithArgs;
  maxDate?: moment.Moment;
}

type TDatePicerProps = ReactDatePickerProps & IDatePicerProps;

interface IDatePickerState {
  datePickerValue: string;
  isDateSelected: boolean;
  focused: boolean;
}

export class DatePicker extends Component<TDatePicerProps, IDatePickerState> {
  public static defaultProps: Partial<TDatePicerProps> = {
    placeholder: '',
  };

  state = {
    datePickerValue: isValidMoment(this.props.value) ? this.props.value.format(dateFormat) : '',
    isDateSelected: false,
    focused: false,
  };

  handleDatepickerInputChange = e => {
    const pickerValue = e.target.value;
    this.setState({ datePickerValue: pickerValue });
    if (this.props.onChange) this.props.onChange(pickerValue, this.props.name);
  };

  handleOnChange = e => {
    let value;
    if (e === null || moment.isMoment(e)) {
      value = e;
    } else {
      value = e.target.value === '' ? e.target.value : moment(e.target.value, dateFormat);
    }
    const pickerValue = isValidMoment(value) && value.isValid() ? value.format(dateFormat) : '';
    this.setState({
      datePickerValue: pickerValue ? value.format(dateFormat) : '',
      isDateSelected: true,
    });
    if (this.props.onChange) this.props.onChange(pickerValue, this.props.name);
  };

  handleOnFocus = _e => this.setState({ focused: true });

  handleOnBlur = e => {
    let value;
    if (e === null || moment.isMoment(e)) {
      value = e;
    } else {
      value = e.target.value === '' ? e.target.value : moment(e.target.value, dateFormat);
    }

    const pickerValue = isValidMoment(value) && value.isValid() ? value.format(dateFormat) : '';
    this.setState({ datePickerValue: pickerValue, focused: false });
    if (this.props.onBlur) this.props.onBlur(pickerValue);
    if (this.props.input && this.props.input.onBlur) this.props.input.onBlur(pickerValue);
  };

  render() {
    const { name = '', error, touched, withPortal, disabled, maxDate, ...props } = this.props;
    const classes = [] as string[];
    const now = maxDate || moment();
    classes.push('calendar');
    let value = this.props.value;

    if (typeof value === 'string') value = moment(value, dateFormat);
    if (value && !value.isValid()) value = null;
    if (this.state.isDateSelected && value === null) this.setState({ isDateSelected: false });

    return (
      <Column className={classes.join(' ')} position='relative'>
        <FloatLabel
          htmlFor={name}
          floated={!!value || this.state.focused}
          hasError={!!error}
          touched={touched}
        >
          {translate(this.props.placeholder)}
        </FloatLabel>
        <StyledDatePickerLib
          autoComplete='off'
          {...props}
          dateFormat={dateFormat}
          disabled={disabled}
          disabledKeyboardNavigation={true}
          maxDate={this.props.maxDate ? this.props.maxDate : now}
          name={name}
          onChange={this.handleOnChange}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
          onChangeRaw={this.handleDatepickerInputChange}
          selected={value ? moment(value, dateFormat) : null}
          withPortal={withPortal}
          className='date-picker'
          error={error}
          touched={touched || this.state.isDateSelected}
          customInput={
            <InputMask mask={'99.99.9999'} value={value}>
              {props => (
                <StyledInput
                  type={'text'}
                  inputMode={'numeric'}
                  {...props}
                  disabled={disabled}
                  touched={touched || this.state.isDateSelected}
                />
              )}
            </InputMask>
          }
        />
        {(touched || this.state.isDateSelected) && error && (
          <ErrorMessage data-cy={`error-msg-${name}`}>{error}</ErrorMessage>
        )}
      </Column>
    );
  }
}

function setLocalization() {
  moment.updateLocale('en', {
    months: [
      'Январь',
      'Февраль',
      'Март',
      'Апрель',
      'Май',
      'Июнь',
      'Июль',
      'Август',
      'Сентябрь',
      'Октябрь',
      'Ноябрь',
      'Декабрь',
    ],
    weekdaysMin: ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'],
    week: {
      dow: 1,
      doy: 4,
    },
  });
}

const StyledDatePickerLib = styled(DatePickerLib)<TDatePicerProps>`
  border-color: ${({ error, touched }) => (error && touched ? 'red !important' : '#f0f4f8')};
`;
