export const buttonStyles = {
  default: {
    backgroundColor: {
      default: 'rgb(255, 103, 50)',
      hover: '#ff4b0c',
      inverted: '#ffffff',
      disabled: '#b9b9b9',
    },
    fontColor: {
      default: '#ffffff',
      inverted: 'rgb(255, 103, 50)',
    },
    borderColor: {
      inverted: 'rgb(255, 103, 50)',
    },
  },
};
