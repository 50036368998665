export const fontSizes = {
  nano: '11px', // 0.812rem
  mini: '13px', // 0.812rem
  small: '14px', // 0.875rem
  base: '16px', // 1rem
  medium: '18px', // 1.125rem
  big: '20px', // 1.25rem
  titleBase: '24px', // 1.5rem
  titleSmall: '28px', // 1.75rem
  titleMedium: '36px', // 2.25rem
  titleBig: '48px', //3rem
  error: '0.875rem', // 14px
};

export const lineHeights = {
  small: 1.1,
  heading: 1.3,
  base: 1.7,
  big: 2,
};
