import React from 'react';
import styled from 'styled-components';
import { useTypedSelector } from 'store';

import { Spinner } from 'components/atoms/Spinner';

export const Preloader: React.FC = () => {
  const show = useTypedSelector(({ common }) => common.showPreloader);
  if (!show) return null;

  return (
    <Container>
      <Spinner />
    </Container>
  );
};

const Container = styled.div`
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;
  overflow: hidden;
  background: #ffffff;
  opacity: 0.5;
  display: block;

  & #spinner {
    position: relative;
    z-index: 1000;
    top: 50% !important;
    display: block;
  }
`;
