import * as CONSTANTS from './accessibility-settings.constants';
import { defaultGetSettings } from '@n3/react-vision-panel';
import { IAccessibilitySettingsReducer } from 'types/store/reducers';

const initialState: IAccessibilitySettingsReducer = {
  isOpen: false,
  config: defaultGetSettings(),
};

export const accessibilitySettingsReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.TOGGLE_OPEN:
      return {
        ...state,
        isOpen: !state.isOpen,
      };

    case CONSTANTS.SET_CONFIG:
      return {
        ...state,
        config: action.data.config,
      };

    default:
      return state;
  }
};
