import { css } from 'styled-components';
import { imgProlongBackgroundFinalWebp, imgProlongBackgroundFinal, imgPageHeaderOurkidzWebp, imgPageHeaderOurkidz, imgPageHeaderGoodsWebp, imgPageHeaderGoods, imgPageHeaderFlatWebp, imgPageHeaderFlat, imgPageHeaderFairinsWebp, imgPageHeaderFairins, imgPageHeaderCovidWebp, imgPageHeaderCovid, iconArrDownSvg, iconCheckGreen, iconCheckWhite, imgPageHeader1Webp, imgPageHeader1, imgPageHeader3Webp, imgPageHeader3, imgPageHeaderCardsWebp, imgPageHeaderCards } from 'utils/images';

export const commonStylesLegacy = css`
  /* page-header */

  .page-header {
    min-height: 275px;
    padding: 45px 45px 120px;
    display: flex;
    flex-flow: column;
    justify-content: flex-end;
    max-width: 1200px;
  }

  .page-header h1 {
    margin: 0;
  }

  .page-header h4 {
    margin: 1em 0 0;
    font-size: 120%;
  }

  .header-home {
    background: #190c05 url(${imgPageHeader1Webp}) no-repeat right center;

    html.no-webp & {
      background: #190c05 url(${imgPageHeader1}) no-repeat right center;
    }
  }

  .header-home * {
    color: #ffffff;
  }

  .header-flat {
    background: #190c05 url(${imgPageHeaderFlatWebp}) no-repeat top right;

    html.no-webp & {
      background: #190c05 url(${imgPageHeaderFlat}) no-repeat top right;
    }
  }

  .header-fairins {
    background: #008894 url(${imgPageHeaderFairinsWebp}) no-repeat -11px top;

    h1,
    h4 {
      color: white;
    }

    html.no-webp & {
      background: #008894 url(${imgPageHeaderFairins}) no-repeat -11px top;
    }
  }

  .header-covid {
    background: #9e2f79 url(${imgPageHeaderCovidWebp}) no-repeat 0 bottom;

    h1,
    h4 {
      color: white;
    }

    h4 {
      width: 60%;
      white-space: normal;
    }

    html.no-webp & {
      background: #9e2f79 url(${imgPageHeaderCovid}) no-repeat 0 bottom;
    }
  }

  .header-goods {
    background: #6a5d44 url(${imgPageHeaderGoodsWebp}) no-repeat center 78%;

    h1,
    h4 {
      color: white;
    }

    html.no-webp & {
      background: #6a5d44 url(${imgPageHeaderGoods}) no-repeat center 78%;
    }
  }

  div[class*='page-header-no-product-found-'] {
    padding: 45px;
  }

  .page-header-no-product-found-goods,
  .page-header-section-covid {
    color: white;
  }

  .header-flat * {
    color: #151d2b;
  }

  .header-prolong {
    background: #190c05 url(${imgProlongBackgroundFinalWebp});
    background-repeat: no-repeat;
    background-position-y: -530px;
    background-position-x: center;
    background-size: cover;

    html.no-webp & {
      background: #190c05 url(${imgProlongBackgroundFinal});
    }
  }

  .header-family {
    background: #190c05 url(${imgPageHeader3Webp}) no-repeat right center;

    html.no-webp & {
      background: #190c05 url(${imgPageHeader3}) no-repeat right center;
    }
  }

  .header-family * {
    color: #151d2b;
  }

  .header-ourkidz {
    background: #190c05 url(${imgPageHeaderOurkidzWebp}) no-repeat right center;

    html.no-webp & {
      background: #190c05 url(${imgPageHeaderOurkidz}) no-repeat right center;
    }
  }

  .header-ourkidz * {
    color: #151d2b;
  }

  .header-cards {
    background: #190c05 url(${imgPageHeaderCardsWebp}) no-repeat right center;

    html.no-webp & {
      background: #190c05 url(${imgPageHeaderCards}) no-repeat right center;
    }
  }

  .header-cards * {
    color: #151d2b;
  }

  /* accessibility color themes overrides: black theme */
  .n3__wcag__color_black [class='^header-*'] {
    background: none;
  }

  /* accessibility color themes overrides: blue theme */
  .n3__wcag__color_blue [class='^header-*'] {
    background: none;
  }

  /* page-header-section */

  .page-header-section {
    position: relative;
    margin-top: -85px;
  }

  .page-header-section-wrapper {
    margin: 0 10px;
    padding: 25px;
    min-height: 170px;
    box-sizing: border-box;
    background: #ffffff;
    box-shadow: 0 1px 16px 0 rgba(146, 149, 183, 0.3);
    overflow-y: auto;

    &.no-overflow {
      overflow: initial;
    }
  }

  .page-header-section-wrapper > table {
    min-width: 100%;
    max-width: 100%;
  }

  .page-header-section td {
    color: #858585;
    vertical-align: middle;
    padding: 20px 25px;
    border: 0;
  }

  .page-header-section .td-big {
    font-size: 130%;
  }

  .page-header-section td:not(.select__container) div:not(.discount__container):not(.badge__container) {
    min-height: 45px;
    display: flex;
    flex-flow: column;
    justify-content: center;
  }

  .page-header-section .price {
    color: #151d2b;
    font-size: 190%;
    font-weight: 400;
  }

  .page-header-section td .more {
    color: #858585;
    align-self: flex-start;
    text-decoration: none;
  }
  .page-header-section td .text {
    color: #858585;
    text-decoration: none;
    display: flex;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
    white-space: nowrap;
    justify-content: space-between;
  }

  .page-header-section td .more:after {
    content: '';
    display: inline-block;
    width: 11px;
    height: 5px;
    padding: 0 0 0 15px;
    position: relative;
    top: -2px;
    background: url(${iconArrDownSvg}) no-repeat right center;
  }

  .page-header-section td .more.active:after {
    transform: rotate(-180deg);
    margin: 0 0 0 15px;
    padding: 0;
  }

  .page-header-section .gray td {
    background-color: #f6f7f9;
  }

  .page-header-section table .active {
    display: table-row-group;
  }

  .page-header-section table .active td {
    padding: 10px 25px;
  }

  .page-header-section table .active tr:first-child td {
    padding-top: 0;
  }

  .page-header-section table .active tr:last-child td {
    padding-bottom: 35px;
  }

  .page-header-section table .active .price {
    font-size: 100%;
  }

  .page-header-section-carousel {
    position: relative;
    top: -65px;
    margin: 0 !important;
  }

  .page-header-section p {
    margin: 1em 0;
  }

  .page-header-section-fairins > div {
    color: white;
  }

  /* checkout */

  .page-checkout h4 {
    margin: 0.6em 0 0.5em;
    font-weight: 300;
  }

  .page-checkout h5 {
    margin: 0.6em 0 0.3em;
  }

  .page-checkout .row {
    display: flex;
    flex-wrap: wrap;
    position: relative;
    margin: 0 0 30px -20px;
  }

  .page-checkout .row .form-button {
    margin-top: 10px;
  }

  .page-checkout .rowCompact {
    margin: 0 0 0 -20px !important;
  }

  .page-checkout.tar .row {
    justify-content: flex-end;
  }

  .page-checkout.tar .row .btn {
    margin-left: 1em;
  }

  .page-checkout .row > div {
    flex-basis: calc(100% * (1 / 4) - 20px);
    margin: 0 0 10px 20px;
  }

  .page-checkout .row > .col-50 {
    flex-basis: calc(100% * (1 / 2) - 20px);
  }

  .page-checkout .row > .col-75 {
    flex-basis: calc((100% * (1 / 2)) + (100% * (1 / 4)) - 20px);
  }

  .page-checkout .row > .col-8 {
    flex-basis: calc(100% * (1 / 12) - 20px);
  }

  .page-checkout .radio span {
    font-size: 130%;
    margin-right: 2em;
  }

  .page-checkout .row .error input,
  .page-checkout .row .error textarea {
    border: 1px solid #e7372c;
    background-color: #ffffff;
  }

  .page-checkout .row .error span,
  .page-checkout .error span,
  .page-checking .error span {
    color: #e7372c;
  }

  .page-checkout .row textarea {
    height: 50px;
    max-height: 50px;
    padding: 0 12px;
    line-height: 50px;
  }

  /* checking */

  .page-checking h4 {
    margin: 0.6em 0 0.5em;
    font-weight: 300;
  }

  .page-checking .buttons * {
    padding: 1em 2em 1.05em !important;
  }

  .page-checking button.btn {
    margin-left: 1em;
  }

  /* sms */

  .page-sms form {
    margin-top: 3em;
  }

  .page-sms form input:not([type='submit']) {
    max-width: 160px;
  }

  .page-sms .buttons * {
    padding: 1em 2em 1.05em !important;
  }

  .page-sms button.btn {
    margin-left: 1em;
  }

  .page-sms .error span {
    color: #e7372c;
  }

  .page-sms .error input {
    border: 1px solid #e7372c;
    background-color: #ffffff;
  }

  .page-sms .sms-input {
    display: block;
  }

  /* finalhome */
  .page-finalhome {
    margin-bottom: 40px;
  }

  .page-finalhome h4 {
    margin: 0.6em 0 1.5em;
  }

  /* forms */

  /* overwrite own styling on iOS */
  form input:not([type='submit']):not([id*='react-select']) {
    -webkit-appearance: none;
  }

  form input:not([type='submit']):not([id*='react-select']), /* except submit and react-select */
   form textarea,
   form select {
    font-size: 125%;
    width: 100%;
    height: 50px;
    margin: 15px 0 0;
    padding: 0 12px;
    box-sizing: border-box;
    border: 1px solid rgb(228, 235, 243);
    border-radius: 3px;
    background: #f0f4f8;
    transition: 0.4s;
  }

  .forward {
    input:not([type='submit']):not([id*='react-select']),
    textarea,
    select {
      margin: 10px 0 0;
      border: 1px solid #f0f4f8;
    }

    input[disabled] {
      background: #b9b9b9 !important;
      &:hover {
        cursor: no-drop;
      }
    }
  }

  form .checked input:not([type='submit']) {
    background: #f0f4f8 url(${iconCheckGreen}) no-repeat 95% 50%;
    background-size: 14px;
    padding-right: 34px;
  }

  form .calendar input:not([type='submit']) {
    background: #f0f4f8 url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOSIgaGVpZ2h0PSIxOSIgdmlld0JveD0iMCAwIDE5IDE5Ij4NCiAgICA8cGF0aCBmaWxsPSIjQzlEMEQ4IiBmaWxsLXJ1bGU9Im5vbnplcm8iIGQ9Ik0xMy41OSA0LjA2Yy40MiAwIC43NjEtLjM0NC43NjEtLjc2OFYuNzY4YS43NjQuNzY0IDAgMCAwLS43Ni0uNzY4Ljc2NS43NjUgMCAwIDAtLjc2MS43Njh2Mi41MjRjMCAuNDI0LjM0Ljc2Ny43Ni43Njd2LjAwMXptLTguMTggMGMuNDIgMCAuNzYtLjM0NC43Ni0uNzY4Vi43NjhBLjc2NC43NjQgMCAwIDAgNS40MSAwYS43NjUuNzY1IDAgMCAwLS43NjEuNzY4djIuNTI0YzAgLjQyNC4zNC43NjcuNzYuNzY3bC4wMDEuMDAxem0xMi41NzYtMi4xODRoLTIuNzIydjEuNDE2YzAgLjkzLS43NSAxLjY4OC0xLjY3MyAxLjY4OGExLjY4MyAxLjY4MyAwIDAgMS0xLjY3NC0xLjY4OFYxLjg3Nkg3LjA4M3YxLjQxNmMwIC45My0uNzUgMS42ODgtMS42NzQgMS42ODhhMS42ODMgMS42ODMgMCAwIDEtMS42NzMtMS42ODhWMS44NzZIMS4wMTRDLjQ1NCAxLjg3NiAwIDIuMzM0IDAgMi44OTl2NC4xOTZoMTlWMi44OTljMC0uNTY1LS40NTQtMS4wMjMtMS4wMTQtMS4wMjN6TTAgMTcuOTc2QzAgMTguNTQzLjQ1NCAxOSAxLjAxNCAxOWgxNi45NzJjLjU2IDAgMS4wMTQtLjQ1OCAxLjAxNC0xLjAyM1Y3LjkxM0gwdjEwLjA2NC0uMDAxem0xNC4wNDEtNy42MzJoMi41OTV2Mi42MThoLTIuNTk1di0yLjYxOHptMCAzLjczNWgyLjU5NXYyLjYxOGgtMi41OTV2LTIuNjE4em0tMy44OTItMy43MzVoMi41OTV2Mi42MThoLTIuNTk1di0yLjYxOHptMCAzLjczNWgyLjU5NXYyLjYxOGgtMi41OTV2LTIuNjE4em0tMy44OTMtMy43MzVoMi41OTV2Mi42MThINi4yNTZ2LTIuNjE4em0wIDMuNzM1aDIuNTk1djIuNjE4SDYuMjU2di0yLjYxOHptLTMuODkyLTMuNzM1SDQuOTZ2Mi42MThIMi4zNjR2LTIuNjE4em0wIDMuNzM1SDQuOTZ2Mi42MThIMi4zNjR2LTIuNjE4eiIvPg0KPC9zdmc+DQo=) no-repeat 95% 50%;
    background-size: 19px;
    padding-right: 39px;
  }

  form input:focus:not([type='submit']):not([id*='react-select']), /* except react-select */
   form textarea:focus {
    background-color: #ffffff;
    border: 1px solid #151d2b;
  }

  form input[type='radio'],
  form input[type='checkbox'] {
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
  }

  form textarea {
    height: 100px;
    padding: 8px 12px;
  }

  form .checkbox {
    display: block;
    cursor: pointer;
    position: relative;
    margin-top: 20px;
    padding-left: 34px;
    user-select: none;
  }

  form .checkbox input,
  form .checkbox input[type='checkbox'] {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 24px;
    cursor: pointer;
    padding: 0;
    margin: 0;
  }

  form .checkbox a {
    position: relative;
    z-index: 2;
  }

  form .checkbox .checkmark {
    position: absolute;
    left: 0;
    top: -1px;
    width: 22px;
    height: 22px;
    border: 1px solid #9b9b9b;
    border-radius: 3px;
    background-color: #ffffff;
    background-size: 14px;
    transition: 0.2s;
  }

  form .checkbox .checkmark-disabled {
    background-color: rgba(155, 155, 155, 0.61);
  }

  form .checkbox .checkmark-checked {
    background-image: url(${iconCheckWhite});
    background-position: 50% 50%;
    background-repeat: no-repeat;
  }

  form .checkbox:hover input ~ .checkmark {
    border: 1px solid #7ed321;
  }

  form .checkbox-disabled:hover input ~ .checkmark {
    border: 1px solid #9b9b9b;
  }

  form .checkbox-checked input ~ .checkmark {
    border: 1px solid #7ed321;
    background-color: #7ed321;
  }

  form .checkbox input ~ .checkmark.checkmark-disabled.checkmark-checked {
    background-image: url(${iconCheckWhite});
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-color: #9b9b9b61;
    border: 1px solid #9b9b9b;
  }

  form .checkbox input ~ .checkmark.checkmark-disabled.checkmark-notchecked {
    background-color: rgba(155, 155, 155, 0.61);
  }

  form .radiobutton {
    font-size: 125%;
    display: inline-block;
    cursor: pointer;
    position: relative;
    margin-right: 20px;
    padding-left: 28px;
    user-select: none;
  }

  form .radiobutton input {
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
    opacity: 0;
    width: 100%;
    height: 18px;
    cursor: pointer;
  }

  form .radiobutton a {
    position: relative;
    z-index: 2;
  }

  form .radiobutton .checkmark {
    position: absolute;
    left: 0;
    top: 4px;
    width: 16px;
    height: 16px;
    border: 1px solid #9b9b9b;
    border-radius: 50%;
    background: #ffffff;
    transition: 0.2s;
  }

  form .radiobutton:hover input ~ .checkmark {
    border: 1px solid #7ed321;
  }

  form .radiobutton input:checked ~ .checkmark {
    border: 4px solid #7ed321;
    width: 10px;
    height: 10px;
  }

  .btn,
  .btn:link,
  input[type='submit'] {
    font-size: 100%;
    color: #ffffff !important;
    background: #e7372c;
    font-weight: 600;
    display: inline-block;
    padding: 0.8em 2em;
    border: 0;
    border-radius: 155px;
    box-sizing: border-box;
    cursor: pointer;
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: 0.4s;
    white-space: wrap;
    align-self: center;
  }

  .btn.outline {
    color: #e7372c !important;
    background: #ffffff !important;
    border: 1px solid #e7372c;
  }

  .btn.outline:hover {
    color: #151d2b !important;
    border: 1px solid #151d2b !important;
  }

  .btn:hover,
  .btn:link:hover,
  input[type='submit']:hover {
    background: #151d2b;
  }

  .btn.b2,
  .btn.b2:link,
  input[type='submit'].b2 {
    color: #e7372c !important;
    background: #ffffff;
    padding: 0.8em 1.5em 0.85em;
    border: 1px solid #fea9b9;
    border-radius: 5px;
    text-transform: none;
  }

  .btn.b2:hover,
  .btn.b2:link:hover,
  input[type='submit'].b2:hover {
    color: #ffffff !important;
    border: 1px solid #e7372c;
    background: #e7372c;
  }

  .btn.b3,
  .btn.b3:link,
  input[type='submit'].b3 {
    border-radius: 155px;
  }

  /* other */

  input[disabled] {
    background: transparent !important;

    &:hover {
      cursor: no-drop;
    }
  }

  /* swiper */
  .hc_swipe-container {
    overflow: hidden;
  }

  .hc_swipe-child {
    position: relative;
    float: left;
  }

  .btn_promo_code {
    margin-top: 16px;
    align-self: flex-start !important;
  }

  .hc_text-center {
    text-align: center;
  }

  .price {
    white-space: nowrap;
  }

  .tab-tooltip-home {
    max-width: 200px;
  }

  .tab-tooltip-flat {
    max-width: 200px;
  }

  .tip-link {
    border-bottom: 1px dotted ##222222;
    text-decoration: none;
  }
`;
