import { Flex } from '@rebass/grid';
import { BaseSyntheticEvent, Component } from 'react';
import InputMask from 'react-input-mask';
import styled, { css } from 'styled-components';

import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { StyledInput } from 'components/atoms/form';
import { FloatLabel } from 'components/atoms/form/FloatLabel';
import { FnWithArgs, Layout } from 'types';

interface IMaskedInputProps {
  mask: string;
  value: string;
  disabled?: boolean;
  error?: string | object;
  label?: string;
  layout?: Layout;
  name?: string;
  touched?: boolean;
  wrapperClassname?: string;
  formatter?: FnWithArgs<string>;
  onBlur?: FnWithArgs; // redux form
  onFieldBlur?: FnWithArgs; // any blur functionality
  onChange?: FnWithArgs;
  onValueChange?: FnWithArgs;
  onFieldFocus?: FnWithArgs;
  onFocus?: FnWithArgs;
  unmask?: FnWithArgs;
  placeholder?: string;
}

interface IMaskedInputState {
  focused: boolean;
}

export class MaskedInput extends Component<IMaskedInputProps, IMaskedInputState> {
  static defaultProps = {
    layout: 'vertical',
  };

  state = {
    focused: false,
  };

  handleOnChange = e => {
    const { unmask, onChange, name, onValueChange } = this.props;
    const value = typeof unmask === 'function' ? unmask(e) : e.target.value;
    onChange?.(value, name);
    onValueChange?.(value);
  };

  handleOnFocus = e => {
    const { onFieldFocus, onFocus } = this.props;
    this.setState({ focused: true });
    onFocus?.(e);
    onFieldFocus?.(e);
  };

  handleOnBlur = (e: BaseSyntheticEvent | string) => {
    const { onBlur, unmask, onFieldBlur } = this.props;
    const value =
      typeof unmask === 'function' ? unmask(e) : typeof e === 'object' ? e?.target?.value : e;
    onBlur?.(value);
    onFieldBlur?.(value);
    this.setState({ focused: false });
  };

  render() {
    const {
      name = '',
      mask,
      unmask,
      label,
      value,
      disabled,
      error,
      formatter,
      wrapperClassname,
      touched,
      onBlur,
      layout,
      onFieldBlur,
      onValueChange,
      placeholder,
      ...props
    } = this.props;

    return (
      <Container
        flexDirection={layout === 'vertical' ? 'column' : 'row'}
        error={error}
        touched={touched}
      >
        {placeholder && (
          <FloatLabel
            htmlFor={name}
            floated={!!value || this.state.focused}
            hasError={!!error}
            touched={touched}
          >
            {placeholder}
          </FloatLabel>
        )}
        <InputMask
          name={name}
          mask={mask}
          value={formatter ? formatter(value) : value}
          disabled={disabled}
          {...props}
          onChange={this.handleOnChange}
          onFocus={this.handleOnFocus}
          onBlur={this.handleOnBlur}
        >
          {inputProps => (
            <StyledInput
              {...inputProps}
              type={inputProps?.inputProps?.type || 'text'}
              name={name}
              disabled={disabled}
              focused={this.state.focused || !!this.state.focused}
            />
          )}
        </InputMask>
        {touched && error && <ErrorMessage data-cy={`error-msg-${name}`}>{error}</ErrorMessage>}
      </Container>
    );
  }
}

const Container = styled(Flex)<Partial<IMaskedInputProps>>`
  position: relative;
  ${({ error, touched }) =>
    error &&
    touched &&
    css`
      input {
        border: 1px solid #e7372c !important;
      }
    `};
`;
