/**
 * Safely get object prop's value (lodash.get alternative)
 * Example: getProp({}, 'x', 'DEFAULT_VAL')
 * source: https://gist.github.com/harish2704/d0ee530e6ee75bad6fd30c98e5ad9dab#gistcomment-2054634
 */
export const getProp = (object: object, keys: string[] | string, defaultVal?: any) => {
  if (!object) return defaultVal;
  keys = Array.isArray(keys) ? keys : keys.split('.');
  object = object[keys[0]];
  if (object && keys.length > 1) {
    return getProp(object, keys.slice(1), defaultVal);
  }
  return object === undefined ? defaultVal : object;
};

/**
 * Get object values
 * https://stackoverflow.com/a/15113739/3151019
 */
export const objectValues = (obj: object): any[] => {
  var vals: any[] = [];
  for (var key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      vals.push(obj[key]);
    }
  }
  return vals;
};

/**
 * Omit false values from object
 */
export const omitEmptyValues = (obj: object): object => {
  Object.keys(obj).forEach(
    key => (obj[key] === null || obj[key] === undefined || obj[key] === '') && delete obj[key]
  );
  return obj;
};

/**
 * Safe object stringify
 */
export const stringify = (obj: object | string) => {
  if (typeof obj === 'string') return obj;
  try {
    return JSON.stringify(obj);
  } catch (err) {
    if (obj?.toString) return obj.toString?.();
    return null;
  }
};
