import React from 'react';
import styled from 'styled-components/macro';
import color, { ColorProps } from '@styled-system/color';
import space, { SpaceProps } from '@styled-system/space';
import position, { PositionProps } from '@styled-system/space';
import layout, { LayoutProps } from '@styled-system/layout';

import translate from 'utils/translator';

interface IOwnProps extends React.AnchorHTMLAttributes<HTMLAnchorElement> {
  children: string;
  disabled?: boolean;
}

type TButtonLinkProps = IOwnProps & ColorProps & SpaceProps & LayoutProps & PositionProps;

export const ButtonLink: React.FC<TButtonLinkProps> = ({ href, children, ...linkProps }) => (
  <StyledLink href={href} {...linkProps}>
    {translate(children)}
  </StyledLink>
);

const StyledLink = styled.a<IOwnProps>`
  align-items: center;
  background: ${({ theme, disabled }) =>
    disabled ? theme.colors.borderGrayPrimary : theme.colors.redPrimary};
  border-radius: 5px;
  box-sizing: border-box;
  color: ${({ theme }) => theme.colors.whiteAlt};
  display: flex;
  flex-direction: row;
  font-size: 0.6875rem;
  font-weight: 400;
  justify-content: center;
  line-height: 36px;
  padding: 0 25px;
  text-decoration: none;
  border: 2px solid
    ${({ theme, disabled }) =>
      disabled ? theme.colors.borderGrayPrimary : theme.colors.redPrimary};

  // override
  &:link {
    color: ${({ theme }) => theme.colors.whiteAlt};
  }

  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.borderGrayPrimary : theme.colors.redPrimary};
    background: ${({ theme }) => theme.colors.white};
  }

  ${({ theme }) => theme.media.minPhone`
    line-height: 44px;
    font-size: 0.875rem;
    padding: 0 30px;
  `}

  ${layout};
  ${position};
  ${color};
  ${space};
`;
