import { SCREEN_NAMES, AUTH_TYPE } from 'components/organisms/agent/constants';
import * as CONSTANTS from 'components/organisms/agent/redux/agent.constants';
import { getProp } from 'utils/object.utils';
import { transformOrderStatusToOptions } from 'components/organisms/agent/components/SalesFilter/components/SalesFilterForm';
import { SALES_HISTORY } from 'components/organisms/agent/constants';

import { IAgentReducer } from 'types';

/**
 * Initial Agent State
 */
export const initialAgentState: IAgentReducer = {
  // Page Content
  contentType: 'login',
  // contentType: 'login' | 'form' | 'final' | 'sms' | 'final-pdf',
  activeScreen: SCREEN_NAMES.LOGIN,
  // activeScreen: string

  // Agent Authorization
  token: undefined,
  // token: string
  channelId: undefined,
  // channelId: number
  authType: AUTH_TYPE.AGENT,
  // authType: PK - 1, AGENT - 2

  // Agent Identity
  agentId: undefined,
  // agentId: string
  login: undefined,
  // login: string
  agentIdConfirmed: false,
  // agentIdConfirmed: boolean

  // Products Data
  products: undefined,
  /*
  products: {
    id: number,
    name: string,
    options: {
      duration: number,
      maximumSumInsured: string,
      premium: string,
      riskSumsInsured: number[],
      tfrId: number,
      tplId: number,
    }[],
    risks: string[],
  }[]
   */

  // Selected Options
  selectedProductType: undefined,
  // selectedProductType: string
  selectedProductDuration: undefined,
  // selectedProductDuration: string
  selectedProductVariant: undefined,
  /*
  selectedProductVariant: {
    duration: number,
    maximumSumInsured: string,
    premium: string,
    riskSumsInsured: number[],
    tfrId: number,
    tplId: number,
  }[]
   */
  selectedProductVariantIndex: undefined,
  // selectedProductVariantIndex: string

  // Process Data
  orderId: undefined,
  // orderId: string
  paymentId: undefined,
  // paymentId: string
  paymentUrl: undefined,
  // paymentUrl: string
  contractNumber: undefined,
  // contractNumber: string

  // Form Values
  formValues: undefined,
  /*
  formValues: {}
   */
  agentIdInputDisabled: false,
  // agentIdInputDisabled: boolean

  // Sales history
  salesHistory: null,
  salesHistoryIsLoading: false,
  salesHistoryError: null,
  salesHistoryTableState: {
    page: SALES_HISTORY.table.DEFAULT_PAGE,
    pageSize: SALES_HISTORY.table.DEFAULT_PAGE_SIZE,
    sorted: SALES_HISTORY.table.DEFAULT_SORTED,
  },

  // Order statuses
  orderStatuses: null,
  orderStatusesIsLoading: false,
  orderStatusesError: null,

  // Information docs
  informationDocs: null,
  informationDocsIsLoading: false,
  informationDocsError: null,

  // sales filter
  salesFilterCountdownStartDate: null,

  // premium calculation
  premiumCalculation: null,

  // sales history limits
  pastMonthsAllowed: null,
  minDateThresholdDay: null,
  paymentMethod: 1,
};

/**
 * Reducer
 */

export default (state = initialAgentState, action) => {
  switch (action.type) {
    // Set Page Content Type
    case CONSTANTS.SET_PAGE_CONTENT_TYPE:
      return {
        ...state,
        contentType: action.data.contentType,
      };

    // Set Token
    case CONSTANTS.SET_TOKEN:
      return {
        ...state,
        token: action.data.token,
      };

    // Set Channel Id (tcl_id)
    case CONSTANTS.SET_CHANNEL_ID:
      return {
        ...state,
        channelId: action.data.channelId,
      };

    // Set Agent Id (hcfbagentid)
    case CONSTANTS.SET_AGENT_ID:
      return {
        ...state,
        agentId: action.data.agentId,
      };

    // Set Login
    case CONSTANTS.SET_LOGIN:
      return {
        ...state,
        login: action.data.login,
      };

    // Set Products
    case CONSTANTS.SET_PRODUCTS:
      return {
        ...state,
        products: action.data.products,
      };

    // Set Product Type
    case CONSTANTS.SET_PRODUCT_TYPE:
      return {
        ...state,
        selectedProductType: action.data.selectedProductType,
        selectedProductVariant: initialAgentState.selectedProductVariant,
        selectedProductVariantIndex: initialAgentState.selectedProductVariantIndex,
        formValues: initialAgentState.formValues,
      };

    // Set Product Duration
    case CONSTANTS.SET_PRODUCT_DURATION:
      return {
        ...state,
        selectedProductDuration: action.data.selectedProductDuration,
        selectedProductVariant: undefined,
        selectedProductVariantIndex: undefined,
      };

    // Set Product Variant
    case CONSTANTS.SET_PRODUCT_OPTION:
      return {
        ...state,
        selectedProductVariant: action.data.selectedProductVariant,
      };

    // Set Product Variant index
    case CONSTANTS.SET_PRODUCT_OPTION_INDEX:
      return {
        ...state,
        selectedProductVariantIndex: action.data.selectedProductVariantIndex,
      };

    // Set Order Id
    case CONSTANTS.SET_ORDER_ID:
      return {
        ...state,
        orderId: action.data.orderId,
      };

    // Set Payment Id
    case CONSTANTS.SET_PAYMENT_ID:
      return {
        ...state,
        paymentId: action.data.paymentId,
      };

    // Set Payment Url
    case CONSTANTS.SET_PAYMENT_URL:
      return {
        ...state,
        paymentUrl: action.data.paymentUrl,
      };

    // Set Contract Number
    case CONSTANTS.SET_CONTRACT_NUMBER:
      return {
        ...state,
        contractNumber: action.data.contractNumber,
      };

    // Set Form Values
    case CONSTANTS.SET_FORM_VALUES:
      return {
        ...state,
        formValues: action.data.formValues,
      };

    // Reset Form Values
    case CONSTANTS.RESET_FORM_VALUES:
      return {
        ...state,
        formValues: null,
      };

    // Set Active Screen
    case CONSTANTS.SET_ACTIVE_SCREEN:
      return {
        ...state,
        activeScreen: action.data.activeScreen,
      };

    // Set Auth Type (PK or AGENT)
    case CONSTANTS.SET_AUTH_TYPE: {
      return {
        ...state,
        authType: action.data.authType,
      };
    }

    // Sales history
    case CONSTANTS.SALES_HISTORY:
      return {
        ...state,
        salesHistoryIsLoading: true,
      };

    case CONSTANTS.SALES_HISTORY_SUCCESS: {
      const { ...salesHistory } = action.data;
      return {
        ...state,
        salesHistory: salesHistory,
        salesHistoryIsLoading: false,
        salesHistoryError: null,
      };
    }

    case CONSTANTS.SALES_HISTORY_FAIL: {
      const errorMsg =
        getProp(action, 'data.response.data.error.message', '') ||
        getProp(action, 'data.response.data.status', '');
      return {
        ...state,
        salesHistory: initialAgentState.salesHistory,
        salesHistoryIsLoading: false,
        salesHistoryError: errorMsg,
        authType: initialAgentState.authType,
      };
    }

    case CONSTANTS.RESET_SALES_HISTORY: {
      return {
        ...state,
        salesHistory: null,
        salesHistoryIsLoading: false,
        salesHistoryError: null,
        salesHistoryTableState: initialAgentState.salesHistoryTableState,
      };
    }

    case CONSTANTS.RESET_SALES_HISTORY_TABLE_STATE: {
      return {
        ...state,
        salesHistoryTableState: initialAgentState.salesHistoryTableState,
      };
    }

    // Order statuses
    case CONSTANTS.ORDER_STATUSES:
      return {
        ...state,
        orderStatusesIsLoading: true,
      };

    case CONSTANTS.ORDER_STATUSES_SUCCESS: {
      const selectOptions = transformOrderStatusToOptions(action.data);
      return {
        ...state,
        orderStatuses: selectOptions,
        orderStatusesIsLoading: false,
        orderStatusesError: null,
      };
    }

    case CONSTANTS.ORDER_STATUSES_FAIL: {
      const errorMsg =
        getProp(action, 'data.response.data.error.message', '') ||
        getProp(action, 'data.response.data.status', '');
      return {
        ...state,
        orderStatuses: initialAgentState.orderStatuses,
        orderStatusesIsLoading: false,
        orderStatusesError: errorMsg,
      };
    }

    // Information docs
    case CONSTANTS.INFORMATION_DOCS:
      return {
        ...state,
        informationDocsIsLoading: true,
      };

    case CONSTANTS.INFORMATION_DOCS_SUCCESS: {
      const [...informationDocs] = action.data;
      return {
        ...state,
        informationDocs,
        informationDocsIsLoading: false,
        informationDocsError: null,
      };
    }

    case CONSTANTS.INFORMATION_DOCS_FAIL: {
      const errorMsg =
        getProp(action, 'data.response.data.error.message', '') ||
        getProp(action, 'data.response.data.status', '');
      return {
        ...state,
        informationDocs: initialAgentState.informationDocs,
        informationDocsIsLoading: false,
        informationDocsError: errorMsg,
      };
    }

    case CONSTANTS.INFORMATION_DOCS_RESET:
      return {
        ...state,
        informationDocs: null,
        informationDocsIsLoading: false,
        informationDocsError: null,
      };

    // Set Agent ID input disabled
    case CONSTANTS.SET_AGENT_ID_INPUT_DISABLED:
      return {
        ...state,
        agentIdInputDisabled: action.data.agentIdInputDisabled,
      };

    // Set Agent ID confirmed
    case CONSTANTS.SET_AGENT_ID_CONFIRMED:
      return {
        ...state,
        agentIdConfirmed: action.data.setAgentIdConfirmed,
      };

    // Set sales filter countdown start date
    case CONSTANTS.SET_FILTER_COUNTDOWN_START_DATE:
      return {
        ...state,
        salesFilterCountdownStartDate: action.data.startDate,
      };

    // Set sales filter countdown start date
    case CONSTANTS.SET_SALES_HISTORY_TABLE_STATE:
      return {
        ...state,
        salesHistoryTableState: action.data.tableState,
      };

    // Set premium calculation
    case CONSTANTS.SET_PREMIUM_CALCULATION: {
      return {
        ...state,
        premiumCalculation: action.data,
      };
    }

    // Set premium calculation
    case CONSTANTS.SET_SALES_HISTORY_LIMITS: {
      return {
        ...state,
        pastMonthsAllowed: action.data.pastMonthsAllowed,
        minDateThresholdDay: action.data.minDateThresholdDay,
      };
    }

    // SSet agent's allowed payment method
    case CONSTANTS.SET_PAYMENT_METHOD: {
      return {
        ...state,
        paymentMethod: action.data,
      };
    }

    // Default
    default:
      return state;
  }
};
