import * as CONSTANTS from './products.constants';
import { getProducts } from 'rest/api';

export const actionSetProducts = products => {
  return {
    type: CONSTANTS.INSURANCE_PRODUCTS_SET,
    products: products,
  };
};

export const actionFetchProductsFailed = error => {
  return {
    type: CONSTANTS.INSURANCE_PRODUCTS_FETCH_FAILED,
    error: error,
  };
};

export const actionFetchProductsStart = () => {
  return {
    type: CONSTANTS.INSURANCE_PRODUCTS_FETCH_START,
  };
};

export const actionGetProducts = () => {
  return dispatch => {
    dispatch(actionFetchProductsStart());
    getProducts()
      .then(response => {
        dispatch(actionSetProducts(response.data.products));
        return response;
      })
      .catch(error => {
        dispatch(actionFetchProductsFailed(error));
        return error;
      });
  };
};
