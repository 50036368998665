import React, { useState } from 'react';
import styled from 'styled-components';

import { Column, IColumnProps } from 'components/atoms/Column';
import { FloatLabel } from 'components/atoms/form/FloatLabel';
import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { trim } from 'utils/string.utils';
import { baseInputStyle, baseStyle } from 'styles/form.styles';

import { FnWithArgs, Layout } from 'types';

export interface IInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  name: string;
  label?: string;
  value: string | number;
  disabled?: boolean;
  error?: string | object;
  layout?: Layout;
  maxLength?: number;
  placeholder?: string;
  touched?: boolean;
  type?: string;
  inputMode?:
    | 'text'
    | 'none'
    | 'tel'
    | 'url'
    | 'email'
    | 'numeric'
    | 'decimal'
    | 'search'
    | undefined;
  inputType?: string;
  onChange?: FnWithArgs;
  onKeyDown?: FnWithArgs;
  onBlur?: FnWithArgs;
  onFocus?: FnWithArgs;
  onDragStart?: FnWithArgs;
  onPaste?: FnWithArgs;
  onValueChange?: FnWithArgs;
  containerProps?: IColumnProps;
}

export const Input: React.SFC<IInputProps> = ({
  name,
  label,
  value,
  error,
  touched,
  placeholder,
  maxLength,
  onBlur,
  onFocus,
  onChange,
  onValueChange,
  onKeyDown,
  onDragStart,
  layout,
  type = 'text',
  disabled = false,
  containerProps = {},
  ...props
}) => {
  const [focused, setFocused] = useState(false);
  const handleOnChange = e => {
    const value = e.target.value;
    onChange?.(e);
    onValueChange?.(value);
  };

  const handleOnFocus = e => {
    setFocused(true);
    onFocus?.(e);
  };

  const handleOnBlur = e => {
    onBlur?.(trim(e.target.value));
    setFocused(false);
  };

  return (
    <Column
      width={1}
      flexDirection={layout === 'vertical' ? 'column' : 'row'}
      position='relative'
      {...containerProps}
    >
      {placeholder && (
        <FloatLabel
          htmlFor={name}
          floated={!!value || focused}
          hasError={!!error}
          touched={touched}
        >
          {placeholder}
        </FloatLabel>
      )}

      <StyledInput
        name={name}
        value={value}
        onChange={handleOnChange}
        onFocus={handleOnFocus}
        onBlur={handleOnBlur}
        onDragStart={onDragStart}
        maxLength={maxLength}
        autoComplete='off'
        type={type}
        disabled={disabled}
        touched={touched}
        error={error}
        onKeyDown={onKeyDown}
        {...props}
      />
      {touched && error && <ErrorMessage data-cy={`error-msg-${name}`}>{error}</ErrorMessage>}
    </Column>
  );
};

Input.defaultProps = {
  layout: 'vertical',
};

export const StyledInput = styled.input<Partial<IInputProps>>`
  ${baseInputStyle};
  ${baseStyle};

  :focus {
    background-color: #ffffff;
    border: 1px solid #151d2b;
  }
`;
