import styled from 'styled-components';

export const Input = styled.input``;

export const InputWrap = styled.label`
  line-height: 1.38;
  font-size: 125%;

  ${props => props.theme.media.phone`
    line-height: 1.5;
    font-size: 105%;
  `}
`;

export const InputCheckboxMark = styled.span<{ error?: boolean }>`
  ${props => props.error && `border: 1px solid ${props.theme.colors.primary} !important;`}
`;
