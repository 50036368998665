import * as CONSTANTS from './order.constants';

export const actionInsuranceOrderStart = product => {
  return {
    type: CONSTANTS.INSURANCE_ORDER_START,
    product: product,
  };
};

export const actionInsuranceOrderSetProduct = product => {
  return {
    type: CONSTANTS.INSURANCE_ORDER_SET_PRODUCT,
    product: product,
  };
};

export const actionInsuranceSetValues = values => {
  return {
    type: CONSTANTS.INSURANCE_SEV_VALUES,
    values: values,
  };
};

export const actionSetOrderId = id => {
  return {
    type: CONSTANTS.INSURANCE_ORDER_SET_ORDER_ID,
    orderId: id,
  };
};

export const actionSetProductId = id => {
  return {
    type: CONSTANTS.INSURANCE_SET_PRODUCT_ID,
    id: id,
  };
};

export const actionSetProductDuration = duration => {
  return {
    type: CONSTANTS.INSURANCE_ORDER_SET_PRODUCT_DURATION,
    duration: duration,
  };
};
