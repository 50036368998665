import { Flex } from '@rebass/grid';
import styled from 'styled-components';

export const Label = styled.label<{ htmlFor?: string; textAlign?: string }>`
  margin-top: 25px;
  display: block;
  text-align: ${({ textAlign }) => textAlign || 'left'};
`;

export const FormRow = styled(Flex)`
  position: relative;
  margin: 0 0 20px 0;
`;
