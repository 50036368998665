import { createBrowserHistory } from 'history';
import { startsWith } from 'lodash-es';

export const history = createBrowserHistory({ basename: process.env.PUBLIC_URL || '/' });

const onHistoryChange = location => {
  // reset scroll position on route change
  window.scrollTo(0, 0);
};

if ((window as any).Cypress) {
  (window as any)._appHistory = history;
}

history.listen(onHistoryChange);

export const redirectTo = (to?: string, query?: string) => {
  if (!to) to = history.location.pathname;
  if (!query) return history.push(to);
  return history.push({
    pathname: to,
    search: startsWith(query, '?') ? query : `?${query}`,
  });
};
