import React from 'react';
import styled, { css } from 'styled-components/macro';

import translate from 'utils/translator';
import { basePlaceholderStyles } from 'styles/placeholder.styles';

interface IFloatLabelProps {
  htmlFor: string;
  floated: boolean;
  children: string;
  whiteSpace?: string;
  lineHeight?: string | number;
  padding?: string;
  hasError?: boolean;
  touched?: boolean;
}

export const FloatLabel: React.FC<IFloatLabelProps> = ({ children, ...props }) => (
  <StyledFloatLabel {...props}>{translate(children)}</StyledFloatLabel>
);

const StyledFloatLabel = styled.label<IFloatLabelProps>`
  ${basePlaceholderStyles};
  background: transparent;
  color: ${({ theme: { colors }, hasError, touched }) =>
    touched && hasError ? colors.textError : 'colors.textPlaceholder'};
  line-height: ${({ lineHeight }) => lineHeight || '50px'};
  margin-left: 12px;
  padding: ${({ padding }) => padding};
  pointer-events: none;
  position: absolute;
  transition: transform 0.3s ease;
  white-space: ${({ whiteSpace }) => whiteSpace || 'pre'};
  z-index: ${({ theme }) => theme.zIndices.floatLabel};
  top: 15px;

  &:hover {
    cursor: text;
  }

  ${({ floated, theme }) =>
    floated &&
    css`
      font-size: ${theme.fontSizes.base};
      letter-spacing: 0;
      line-height: 1;
      padding: 1px 4px;
      transform: translateY(-7px);
      background: white;
      margin-left: 9px;
      z-index: ${({ theme }) => theme.zIndices.floatLabelFloated};
    `};

  ${props => props.theme.media.phone`
    margin-left: 15px;
  `};
`;
