import styled from 'styled-components';

export const TableLayout = styled.div`
  margin: 1.5em 0 3em;

  & > div {
    display: flex;
    flex-wrap: wrap;
    margin: 0.6em 0 0;
    align-items: center;

    ${props => props.theme.media.tablet`
      flex-wrap: nowrap;
    `}
  }

  & > div span:nth-child(1) {
    min-width: 180px;
    color: #858585;
  }

  & > div span:nth-child(2) {
    padding-left: 40px;
    font-size: 130%;
  }

  @media only screen and (max-width: 414px) {
    & > div {
      flex: none;
      display: block;
      margin-bottom: 1em;
    }

    & > div span:nth-child(1) {
      width: auto;
      display: block;
    }

    & > div span:nth-child(2) {
      padding: 0;
      display: block;
      font-size: 100%;
    }
  }
`;
