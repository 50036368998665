import * as CONSTANTS from './zabolel.constants';
import { announcementCreateNew, announcementVerifySmsCode } from 'rest/api.announcement';
import { actionShowPreloader, actionHidePreloader } from 'store/actions/common.actions';
import { log } from 'utils/logger';

/**
 * Create announcement loading start
 */
export const actionCreateAnnouncementStart = () => {
  return {
    type: CONSTANTS.CREATE_ANNOUNCEMENT,
  };
};

/**
 * Create announcement loading success
 */
export const actionCreateAnnouncementSuccess = response => {
  return {
    type: CONSTANTS.CREATE_ANNOUNCEMENT_SUCCESS,
    data: response,
  };
};

/**
 * Create announcement loading fail
 */
export const actionCreateAnnouncementFail = error => {
  return {
    type: CONSTANTS.CREATE_ANNOUNCEMENT_FAIL,
    data: error,
  };
};

/**
 * Create announcement
 */
export const actionCreateAnnouncement = values => async dispatch => {
  try {
    dispatch(actionShowPreloader());
    dispatch(actionCreateAnnouncementStart());
    const result = await announcementCreateNew(values);
    dispatch(actionHidePreloader());
    dispatch(actionCreateAnnouncementSuccess(result.data));
    return result;
  } catch (err) {
    dispatch(actionHidePreloader());
    dispatch(actionCreateAnnouncementFail(err));
    log.error('actionCreateAnnouncement:: err :: ', err);
  }
};

/**
 * Verify sms code
 */
export const actionVerifySmsCodeStart = () => {
  return {
    type: CONSTANTS.VERIFY_SMS_CODE,
  };
};

/**
 * Verify sms code success
 */
export const actionVerifySmsCodeSuccess = response => {
  return {
    type: CONSTANTS.VERIFY_SMS_CODE_SUCCESS,
    data: response,
  };
};

/**
 * Verify sms code fail
 */
export const actionVerifySmsCodeFail = error => {
  return {
    type: CONSTANTS.VERIFY_SMS_CODE_FAIL,
    data: error,
  };
};

/**
 * Verify sms attemps count
 */
export const actionVerifySmsAddAttemptsCount = () => {
  return {
    type: CONSTANTS.VERIFY_SMS_CODE_ATTEMPTS_COUNT,
  };
};

/**
 * Verify sms code
 */
export const actionVerifySmsCode = (announcementId, code) => async dispatch => {
  try {
    dispatch(actionShowPreloader());
    dispatch(actionVerifySmsCodeStart());
    const resultVerify = await announcementVerifySmsCode(announcementId, code);
    dispatch(actionHidePreloader());
    dispatch(actionVerifySmsAddAttemptsCount());
    dispatch(actionVerifySmsCodeSuccess(resultVerify?.data));
  } catch (err) {
    dispatch(actionHidePreloader());
    dispatch(actionVerifySmsCodeFail(err));
    log.error('actionVerifySmsCode:: err :: ', err);
    return Promise.reject(err);
  }
};

/**
 * Set selected full name suggestion
 */
export const actionSetFullNameSuggestion = suggestion => {
  return {
    type: CONSTANTS.SET_FULLNAME_SUGGESTION,
    data: suggestion,
  };
};

/**
 * Set selected employee suggestion
 */
export const actionSetEmployeeSuggestion = suggestion => {
  return {
    type: CONSTANTS.SET_EMPLOYEE_SUGGESTION,
    data: suggestion,
  };
};

/**
 * Set click state for personal data link
 */
export const actionSetIsPersonalDataLinkClicked = value => {
  return {
    type: CONSTANTS.SET_IS_PERSONAL_DATA_LINK_CLICKED,
    data: value,
  };
};
