import styled from 'styled-components';

import { Layout } from 'types';

interface ILabelProps {
  layout?: Layout;
}

export const Label = styled.label<ILabelProps>`
  margin-bottom: ${({ layout, theme }) =>
    layout === 'horizontal' ? 0 : theme.margin.form.labelBottom};
`;
