import { ObjectGeneric } from 'types';

export const loadSearch = props => ({
  get: key => parseSearch(key, props),
  keys: parseKeys(props),
});

export const dataToSearch = (dataInput, merge = false, parser?) => {
  let data;
  if (merge) {
    const searchCurrent = (global as any).location.search;
    data = { ...searchToData({ location: { search: searchCurrent } }, parser), ...dataInput };
  } else {
    data = dataInput;
  }
  if (data) {
    const items = Object.keys(data).map(key => {
      const value = data[key];
      return `${encodeURI(key)}=${encodeURI(value)}`;
    });
    return `?${items.join('&')}`;
  }
  return '';
};

export function searchToData(props?, parser?): ObjectGeneric {
  const result = {};
  parseKeys(props, (key, value) => {
    if (parser && parser[key]) {
      result[key] = parser[key](value);
    } else {
      result[key] = value;
    }
  });
  return result;
}

export const searchToSearch = () => dataToSearch(searchToData());

export const parseKeys = (props, itemCb?) => {
  const result: string[] = [];
  const search =
    (global as any).location.search || (props && props.location && props.location.search);
  if (search) {
    const regex = new RegExp(`\\?*([^=]+)=[^&]+&*`, 'g');
    const match = search.match(regex);
    const regexItem = new RegExp(`\\?*([^=]+)=([^&]+)&*`);
    if (match && Array.isArray(match)) {
      match.forEach(item => {
        const matchItem = item.match(regexItem);
        const key = matchItem[1];
        const value = matchItem[2];
        result.push(key);
        if (itemCb) itemCb(key, decodeURIComponent(value));
      });
    }
  }
  return result;
};

export const parseSearch = (key, props?) => {
  const search =
    (props && props.location && props.location.search) || (window as any).location?.search;
  if (search) {
    const regex = new RegExp(`^\\?.*${key}=([^&]+).*$`);
    const match = search.match(regex);
    if (match) {
      const value = match[1];
      return decodeURIComponent(value);
    }
  }
  return undefined;
};

export const queryToObject = (query: string) => {
  var result = {};
  query.split('&').forEach(function (part) {
    var item = part.split('=');
    result[item[0]] = decodeURIComponent(item[1]);
  });
  return result;
};

export const objectToQuery = (data: object) => {
  let ret: string[] = [];
  for (let d in data) ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
  return ret.join('&');
};

export const makePaymentUrl = response => {
  return (
    response.url +
    '?' +
    objectToQuery({
      id: response.id,
      sector: response.sector,
      signature: response.signature,
    })
  );
};

export const getLocationPropValue = (key: string) => {
  const location = (window as any).location;
  return location[key];
};

export const removeSearchParam = (keys: string | string[], history) => {
  // returns '/insurance/family'
  const pathname = (window as any).location.pathname;
  // returns '?duration=12'
  const searchParams = new URLSearchParams((window as any).location.search);
  if (typeof keys === 'string') {
    searchParams.delete(keys);
  }

  if (Array.isArray(keys)) {
    keys.forEach(key => searchParams.delete(key));
  }

  history.push({
    pathname: pathname,
    search: searchParams.toString(),
  });
};

/**
 * redirects to anotherPage (preserve search params)
 */
export const redirectTo = url =>
  ((window as any).location = `${url}${(window as any).location.search}`);

export const openInNewTab = (url: string): void => {
  // Chrome, FF
  if (/chrome/i.test(navigator.userAgent) || /firefox/i.test(navigator.userAgent)) {
    (window as any).open(url, '_blank').focus();
    return;
  }

  // Safari & Opera iOS
  window.location.href = url;
};
