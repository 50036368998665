import * as CONSTANTS from 'store/constants/common.constants';
import { FLASH_MESSAGE_REMOVE, FLASH_MESSAGE_ADD } from 'components/molecules/FlashMessages';
import { ICommonReducer } from 'types';

const initialState: ICommonReducer = {
  showCookie: false,
  showPreloader: false,
  showPreloaderSmall: false,
  formIsWorking: false,
  insTypeSwipeIsRunning: true,
  formWorkingNames: {},
  flashes: [],
  urlParams: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.STORE_PARAMS:
      return { ...state, urlParams: action.payload };
    case CONSTANTS.TOGGLE_COOKIE_POLICY:
      return { ...state, showCookie: action.isVisible };
    case CONSTANTS.FORM_FIELD_IS_WORKING:
      if (action.value) {
        return {
          ...state,
          formIsWorking: action.value,
          formWorkingNames: { ...state.formWorkingNames, [action.name]: action.name },
        };
      } else {
        const formWorkingNames = { ...state.formWorkingNames };
        delete formWorkingNames[action.name];
        return {
          ...state,
          formIsWorking: Object.keys(formWorkingNames).length > 0 ? true : false,
          formWorkingNames,
        };
      }
    case CONSTANTS.SHOW_PRELOADER_SMALL:
      return { ...state, showPreloaderSmall: true };
    case CONSTANTS.HIDE_PRELOADER_SMALL:
      return { ...state, showPreloaderSmall: false };
    case CONSTANTS.SHOW_PRELOADER:
      return { ...state, showPreloader: true };
    case CONSTANTS.HIDE_PRELOADER:
      return { ...state, showPreloader: false };
    case FLASH_MESSAGE_ADD:
      if (state.flashes.find(item => item.id === action.id)) {
        //no change when there is a same item
        return state;
      } else {
        return {
          ...state,
          flashes: [
            ...state.flashes,
            {
              text: action.text,
              type: action.flash_type,
              id: action.id,
              closeable: action.closeable,
            },
          ],
        };
      }
    case FLASH_MESSAGE_REMOVE: {
      const newArray = state.flashes.filter(flash => flash.id !== action.id);
      return { ...state, flashes: [...newArray] };
    }
    case CONSTANTS.INSURANCETYPE_SWIPE_RUNNING:
      return { ...state, insTypeSwipeIsRunning: action.isRunning };
    default:
      return state;
  }
};
