import React from 'react';
import styled, { css } from 'styled-components/macro';
import { Flex, BoxProps } from '@rebass/grid';
import { system } from 'styled-system';

import position, { PositionProps } from '@styled-system/position';
import boxShadow, { ShadowProps } from '@styled-system/shadow';
import color, { ColorProps } from '@styled-system/color';
import border, { BorderProps } from '@styled-system/border';
import background, { BackgroundProps } from '@styled-system/background';

export interface IRowProps {
  children?: React.ReactNode;
  color?: string;
  withHover?: boolean;
}

export type TRowProps = IRowProps &
  BoxProps &
  PositionProps &
  ShadowProps &
  ColorProps &
  BorderProps &
  BackgroundProps;

export const Row: React.FC<TRowProps> = props => (
  <StyledFlex minWidth='initial' {...props}>
    {props.children}
  </StyledFlex>
);

const StyledFlex = styled(Flex)<Partial<IRowProps>>`
  ${border}
  ${color};
  ${position};
  ${boxShadow};
  ${background};
  ${system({
    display: true,
    lineHeight: true,
  })};

  ${({ withHover }) =>
    withHover &&
    css`
      &:hover {
        cursor: pointer;
      }
    `};
`;
