import * as CONSTANTS from './flashMessages.constants';

let counter = 0;

export const actionNewFlashMessage = (
  text: string,
  type: string,
  id: string | null | undefined,
  closeable: boolean = true
) => {
  if (!id) {
    counter++;
    id = counter.toString();
  }
  return {
    type: CONSTANTS.FLASH_MESSAGE_ADD,
    text: text,
    flash_type: type,
    id: id,
    closeable: closeable,
  };
};

export const actionRemoveFlashMessage = (id: string) => {
  return {
    type: CONSTANTS.FLASH_MESSAGE_REMOVE,
    id: id,
  };
};

export const actionAddFlashMessage = (
  text: string,
  type: string,
  id: string | null | undefined,
  delay: number | null | undefined = null,
  closeable: boolean = true
) => {
  return dispatch => {
    const actionNew = actionNewFlashMessage(text, type, id, closeable);
    dispatch(actionNew);
    if (delay) {
      setTimeout(() => {
        dispatch(actionRemoveFlashMessage(actionNew.id));
      }, delay);
    }
  };
};
