export const noop = () => ({});

export const downloadDataToFile = (blobData: Blob, fileName: string = 'file.xlsx'): void => {
  if (typeof window.navigator.msSaveOrOpenBlob === 'function') {
    navigator.msSaveBlob?.(blobData, fileName); // IE 10
    return;
  }

  const url = window.URL.createObjectURL(new Blob([blobData]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', fileName);
  document.body.appendChild(link);
  link.click();
};
