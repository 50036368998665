import { css } from 'styled-components';

export const basePlaceholderStyles = css`
  color: #9ea8b9;
  font-size: 125%;

  ${props => props.theme.media.phone`
    font-size: ${({ theme }) => theme.fontSizes.base};
  `};
`;

export const placeholderStyles = css`
  *::-webkit-input-placeholder {
    ${basePlaceholderStyles}
  }

  *:-moz-placeholder {
    /* FF 4-18 */
    ${basePlaceholderStyles}
    opacity: 1;
  }

  *::-moz-placeholder {
    /* FF 19+ */
    ${basePlaceholderStyles}
    opacity: 1;
  }

  *:-ms-input-placeholder {
    /* IE 10+ */
    ${basePlaceholderStyles}
  }

  *::-ms-input-placeholder {
    /* Microsoft Edge */
    ${basePlaceholderStyles}
  }

  *::placeholder {
    /* modern browser */
    ${basePlaceholderStyles}
  }
`;
