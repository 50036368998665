import React from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';
import { ResponsiveImg } from 'components/atoms/_elements_';
import { Text } from 'components/atoms';
import translate from 'utils/translator';
import { imgMegafonLogo } from 'utils/images';

export const MegafonLogo = ({ withSeparator }) => (
  <Container flexDirection='row' alignItems='center' justifyContent='center'>
    {withSeparator && <Separator />}
    <Flex flexDirection='column' alignItems='flex-start' justifyContent='center'>
      <LogoContainer>
        <ResponsiveImg src={imgMegafonLogo} alt='megafon logo' />
      </LogoContainer>
      <SpecialOfferContainer>
        <Subtitle>
          <StyledText
            weight='medium'
            color='#414141'
            text={translate('special_offers_for_subscribers')}
          />
        </Subtitle>
      </SpecialOfferContainer>
    </Flex>
  </Container>
);

MegafonLogo.defaultProps = {
  withSeparator: true,
};

const Separator = styled.div`
  width: 3px;
  height: 100%;
  min-height: 40px;
  background-color: #c7c7c7;
  margin: 0 15px 0 11px;
  opacity: 0.35;

  @media screen and (min-width: 768px) {
    min-height: 61px;
  }
`;

const LogoContainer = styled(Flex)`
  width: 163px;

  @media screen and (min-width: 940px) {
    width: 180px;
  }
`;

const Container = styled(Flex)`
  line-height: 1;
`;

const Subtitle = styled.div`
  max-width: 200px;
`;

const SpecialOfferContainer = styled(Box)`
  display: none;

  @media screen and (min-width: 480px) {
    display: block;
  }
`;

const StyledText = styled(Text)`
  font-size: 8px;
  line-height: 14px;
  margin-top: 5px;

  @media screen and (min-width: 640px) {
    font-size: 9px;
  }

  @media screen and (min-width: 768px) {
    font-size: 11px;
  }
`;
