import * as loglevel from 'loglevel';

import { isLocalOrDevEnv } from 'utils/env';

export type TLogLevels = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'silent';

const setLogLevel = () =>
  isLocalOrDevEnv ? loglevel.setLevel('trace') : loglevel.setLevel('error');

setLogLevel();

export const log = loglevel;
