import React, { useEffect } from 'react';

type Args = {
  refs: (React.MutableRefObject<any> | undefined)[];
  handler: () => void;
};

export const useHandleClickOutside = (args: Args) => {
  const { refs, handler } = args;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const clickedOnRefElement = refs.reduce((state, ref) => {
        if (ref && ref.current && ref.current.contains(event.target)) {
          return true;
        }
        return state;
      }, false);

      if (!clickedOnRefElement) {
        handler();
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });
};
