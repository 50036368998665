import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps, StaticContext } from 'react-router';
import { withRouter, Link } from 'react-router-dom';

import { Image } from 'components/atoms/Image';
import { LoadableHome } from 'routes';
import { imgLogoTwoString } from 'utils/images';

import { FnClick } from 'types';

interface IOwnProps {
  clickable?: boolean;
  allowAccessibilityOverlay?: boolean;
  onClick?: FnClick;
}
// test
type ILogoProps = IOwnProps & RouteComponentProps<any, StaticContext>;

export const Logo = withRouter<ILogoProps, any>(
  ({ onClick, location, clickable, allowAccessibilityOverlay }) => {
    if (!clickable) return <LogoImage allowAccessibilityOverlay={allowAccessibilityOverlay} />;

    return (
      <StyledLink
        onClick={onClick}
        to={`/${location.search}`}
        onMouseOver={() => LoadableHome.preload()}
      >
        <LogoImage allowAccessibilityOverlay={allowAccessibilityOverlay} />
      </StyledLink>
    );
  }
);

Logo.defaultProps = {
  clickable: true,
  allowAccessibilityOverlay: true,
};

export const LogoOneString = withRouter<ILogoProps, any>(
  ({ onClick, location, clickable, allowAccessibilityOverlay }) => {
    if (!clickable)
      return <LogoImageOneString allowAccessibilityOverlay={allowAccessibilityOverlay} />;

    return (
      <StyledLinkOneString
        onClick={onClick}
        to={`/${location.search}`}
        onMouseOver={() => LoadableHome.preload()}
      >
        <LogoImageOneString allowAccessibilityOverlay={allowAccessibilityOverlay} />
      </StyledLinkOneString>
    );
  }
);

LogoOneString.defaultProps = {
  clickable: true,
  allowAccessibilityOverlay: true,
};

const LogoImage = ({ allowAccessibilityOverlay }) => (
  <Image
    src={imgLogoTwoString}
    alt='logo'
    width='120'
    height='46'
    allowAccessibilityOverlay={allowAccessibilityOverlay}
  />
);

const LogoImageOneString = ({ allowAccessibilityOverlay }) => (
  <Image
    src={imgLogoTwoString}
    alt='logo'
    width='120'
    height='46'
    allowAccessibilityOverlay={allowAccessibilityOverlay}
  />
);

const StyledLink = styled(Link)`
  color: transparent;
  display: block;
  font-size: 0 !important;
  height: 46px;
  margin: 0;
  overflow: hidden;
  position: relative;
  transition: none;
  width: 120px;
  z-index: 20;
`;

const StyledLinkOneString = styled(Link)`
  color: transparent;
  display: block;
  font-size: 0 !important;
  /* height: 17px; */
  margin: 0;
  /* overflow: hidden; */
  position: relative;
  transition: none;
  width: 240px;
  z-index: 20;
`;
