import * as CONSTANTS from './schema.constants';
import { ISchemaReducer } from 'types';

const initialState: ISchemaReducer = {
  schema: null,
  start: false,
  serverDate: null,
};

export const schemaReducer = (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.INSURANCE_SCHEMA_SET: {
      const s = action.schema;
      return {
        ...state,
        schema: s,
        start: undefined,
        error: undefined,
      };
    }
    case CONSTANTS.INSURANCE_SCHEMA_FETCH_FAILED:
      return {
        ...state,
        schema: undefined,
        start: undefined,
        error: action.error ? action.error : 'error',
      };
    case CONSTANTS.INSURANCE_SCHEMA_FETCH_START:
      return {
        ...state,
        error: undefined,
        schema: undefined,
        start: true,
      };
    case CONSTANTS.INSURANCE_SCHEMA_SET_SERVER_DATE:
      return {
        ...state,
        serverDate: action.data,
      };
    default:
      return state;
  }
};
