import translate from 'utils/translator';
import { getProp } from './object.utils';

export const isErrorCode = (errorCode: string, error: Error | object) => {
  const errorMsg =
    getProp(error, 'response.data.error.message', null) ||
    getProp(error, 'data.error.message', null);
  const hasError = errorMsg === errorCode;
  return hasError;
};

export const processError = (error: object, props: any, type: 'sendOrder' | 'confirmPolicy') => {
  switch (type) {
    case 'sendOrder':
      if (isErrorCode('NOT_FOUND', error)) {
        props.actionAddFlashMessage(translate('sendOrder_error'), 'error', 'sendOrder_error', 5000);
      } else if (isErrorCode('Address is insured already.', error)) {
        props.actionAddFlashMessage(
          translate('error_something_went_wrong'),
          'error',
          'sendOrder_error_something_went_wrong',
          5000
        );
      }
      break;
    case 'confirmPolicy':
      if (isErrorCode('policy not found', error)) {
        props.actionAddFlashMessage(
          translate('confirmPolicy_error'),
          'error',
          'confirmPolicy_error',
          10000
        );
        setTimeout(() => (window.location.href = '/'), 10000);
      }
      break;
    default:
  }
};

export const scrollToFirstError = errors => {
  const hasNoErrors = !errors || !Object.keys(errors).length;
  if (hasNoErrors) return;
  const firstError = Object.keys(errors)[0];
  const el = document.querySelector(`[name="${firstError}"]`);
  const position = (el && el.getBoundingClientRect().top + document.documentElement.scrollTop) || 0;
  const offset = 20;
  if (el) window.scrollTo({ top: position - offset, behavior: 'smooth' });
};

export const retryPromise = (fn, retriesLeft = 3, interval = 2000): Promise<any> =>
  new Promise((resolve, reject) => {
    fn()
      .then(resolve)
      .catch(error => {
        setTimeout(() => {
          if (retriesLeft === 1) {
            // maximum retries exceeded
            reject(error);
            return;
          }

          // Passing on "reject" is the important part
          retryPromise(fn, retriesLeft - 1, interval).then(resolve, reject);
        }, interval);
      });
  });
