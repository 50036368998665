import dictionary from './dictionary.json';

const LANG_DEFAULT = 'ru';
const LANG = LANG_DEFAULT;

const translator = (key: string, ...variables: any[]) => {
  const hasTranslation = dictionary[LANG] && dictionary[LANG][key];
  if (!hasTranslation) return key;

  let val = dictionary[LANG][key];
  variables?.map((variable, idx) => {
    if (typeof variable !== 'undefined' && val) {
      val = val.replace(`{${idx + 1}}`, variable);
    }
  });
  return val;
};

export default translator;
