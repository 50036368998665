import React, { Component } from 'react';
import Autosuggest, { AutosuggestPropsBase } from 'react-autosuggest';
import { Flex } from '@rebass/grid';
import styled from 'styled-components';
import { autosuggestStyles } from 'styles';
import { get, trim, toLower } from 'lodash-es';

import { FloatLabel } from 'components/atoms/form/FloatLabel';
import { StyledInput } from 'components/atoms/form/Input';
import { ErrorMessage } from 'components/atoms/ErrorMessage';
import { Text } from 'components/atoms/Text';
import { colors } from 'styles';

import { FnWithArgs, FnWithArgsPromise, ISuggestionGeneric } from 'types';

interface IOwnProps {
  name: string;
  suggestions: any[];
  value: string;
  inputProps: {
    value: string;
    onChange?: FnWithArgs;
    onBlur?: (e: React.FocusEvent<any>, params?: Autosuggest.BlurEvent<any> | undefined) => void;
    name?: string;
    disabled?: boolean;
  };
  fetchSuggestions: FnWithArgsPromise;
  touched?: boolean;
  error?: string | object;
  disabled?: boolean;
  descriptionText?: string;
  onChange?: FnWithArgs;
  onBlur?: FnWithArgs; // redux-form
  onFocus?: FnWithArgs; // redux-form
  onFieldBlur?: (inputValue, suggestion) => void; // any blur functionality
  getSuggestionValue?: (suggestion, inputValue) => string;
  onValueSelected?: FnWithArgs;
  getComponentRef?: (AutoSuggestInput) => void;
  autoComplete?: string;
  allowCustomValues?: boolean;
  placeholder: string;
}

interface IAutoSuggestInputState {
  autosuggestSelected: ISuggestionGeneric | null;
  autosuggestSuggestions: Array<any>;
  focused: boolean;
}

type IAutoSuggestInputProps = IOwnProps & AutosuggestPropsBase<any>;

export class AutoSuggestInput extends Component<IAutoSuggestInputProps, IAutoSuggestInputState> {
  state = {
    autosuggestSelected: null,
    autosuggestSuggestions: [],
    focused: false,
  };

  componentDidMount() {
    this.props.getComponentRef?.(this);
  }

  handleInputChange = (evt, { newValue }) => this.props.onChange?.(newValue);

  onSuggestionSelected = (evt, selectedSuggestion) => {
    const newValue = selectedSuggestion.suggestionValue;
    this.setState(state => ({
      ...state,
      autosuggestSelected: selectedSuggestion && selectedSuggestion.suggestion,
    }));
    this.props.onValueSelected?.(selectedSuggestion, newValue);
    this.props.onChange?.(newValue);
  };

  onSuggestionsClearRequested = () => {
    if (this.props.allowCustomValues) return;
    this.setState(state => ({ ...state, autosuggestSuggestions: [] }));
  };

  typeProtectionCache = {} as any;

  typeProtection = fn => {
    this.typeProtectionCache.tmp && clearTimeout(this.typeProtectionCache.tmp);
    this.typeProtectionCache.tmp = setTimeout(fn, 250);
  };

  setSelectedSuggestion = sug =>
    this.setState({ autosuggestSelected: sug, autosuggestSuggestions: [sug] });

  onSuggestionsFetchRequested = ({ value }) => {
    const { fetchSuggestions } = this.props;
    this.typeProtection(() => {
      fetchSuggestions({ value, props: this.props }).then(data => {
        data && this.setState(state => ({ ...state, autosuggestSuggestions: data }));
      });
    });
  };

  getSelectedAutosuggest = (autosuggestValue: string, autosuggestSuggestions, currentSelected) => {
    const { allowCustomValues } = this.props;
    if (allowCustomValues && Array.isArray(autosuggestSuggestions)) {
      return (
        autosuggestSuggestions.find(data => toLower(data.value) === toLower(autosuggestValue)) ||
        null
      );
    }
    return currentSelected;
  };

  handleFocusAutosuggest = (
    evt: React.FocusEvent<any>,
    _params?: Autosuggest.BlurEvent<any> | undefined
  ) => {
    this.props.onFocus?.(evt);
    this.setState({ focused: true });
  };

  handleBlurAutosuggest = (
    evt: React.FocusEvent<any>,
    _params?: Autosuggest.BlurEvent<any> | undefined
  ) => {
    const inputValue = get(evt, 'target.value');
    evt.persist();
    this.setState({ focused: false });

    const value = trim(inputValue); // trim the value!
    this.setState(state => {
      const newAutosuggestSelected = this.getSelectedAutosuggest(
        value,
        state.autosuggestSuggestions,
        state.autosuggestSelected
      );

      if (this.props.allowCustomValues) {
        if (this.props.getSuggestionValue) {
          const newVal = this.props.getSuggestionValue(
            this.props.allowCustomValues ? null : newAutosuggestSelected,
            value
          );
          this.props.onValueSelected?.(
            { suggestion: newAutosuggestSelected, suggestionValue: newVal },
            newVal
          );
          this.props.onBlur?.(newVal);
          this.props.onFieldBlur?.(inputValue, newAutosuggestSelected);
        }
      } else {
        const newValue =
          value === '' ? '' : this.props.getSuggestionValue(state.autosuggestSelected, value);
        this.props.onValueSelected?.(
          newValue ? { suggestion: state.autosuggestSelected, suggestionValue: newValue } : null,
          newValue
        );
        this.props.onChange?.(newValue);
        return {
          ...state,
          autosuggestSelected: newValue ? state.autosuggestSelected : null,
        };
      }

      return {
        ...state,
        autosuggestSelected: newAutosuggestSelected,
      };
    });
  };

  renderSuggestionsContainer = ({ containerProps, children, query }) => {
    const { descriptionText } = this.props;
    return (
      <div {...containerProps}>
        {children && descriptionText && (
          <Flex padding='10px'>
            <Text color={colors.gray} size='mini'>
              {descriptionText}
            </Text>
          </Flex>
        )}
        {children}
      </div>
    );
  };

  render() {
    const classes = [];
    const {
      touched,
      error,
      disabled,
      autoComplete = 'off',
      inputProps: { name },
    } = this.props;
    const autosuggestProps = { ...this.props };
    autosuggestProps.id = name;
    autosuggestProps.inputProps = autosuggestProps.inputProps || {};
    autosuggestProps.inputProps.onChange = this.handleInputChange;
    autosuggestProps.inputProps.value = this.props.value || '';
    autosuggestProps.inputProps.autoComplete = autoComplete;
    autosuggestProps.inputProps.name = name;
    autosuggestProps.inputProps.onFocus = this.handleFocusAutosuggest;
    autosuggestProps.inputProps.onBlur = this.handleBlurAutosuggest;
    autosuggestProps.inputProps.disabled = disabled;
    autosuggestProps.onSuggestionsClearRequested = this.onSuggestionsClearRequested;
    autosuggestProps.suggestions = this.state.autosuggestSuggestions;
    autosuggestProps.onSuggestionsFetchRequested = this.onSuggestionsFetchRequested;
    autosuggestProps.onSuggestionSelected = this.onSuggestionSelected;
    autosuggestProps.renderSuggestionsContainer = this.renderSuggestionsContainer;
    autosuggestProps.renderInputComponent = inputProps => (
      // @ts-ignore
      <StyledInput {...inputProps} error={error} touched={touched} disabled={disabled} />
    );

    return (
      <Container
        className={classes.join(' ')}
        flexDirection='column'
        touched={touched}
        error={error}
      >
        <FloatLabel
          htmlFor={this.props.name}
          floated={!!this.props.value || this.state.focused}
          hasError={!!error}
          touched={touched}
        >
          {this.props.placeholder}
        </FloatLabel>
        <Autosuggest {...autosuggestProps} />
        {touched && error && <ErrorMessage data-cy={`error-msg-${name}`}>{error}</ErrorMessage>}
      </Container>
    );
  }
}

const Container = styled<any>(Flex)`
  display: flex;
  flex-direction: column;
  position: relative;
  ${autosuggestStyles};
`;
