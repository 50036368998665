import React from 'react';
import styled from 'styled-components/macro';
import { useLocation, NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { actionSetProductDuration } from 'components/organisms/insurance/redux/order.actions';
import { closeHamburger } from 'store/actions/header.actions';
import { useTypedSelector } from 'store';
import translate from 'utils/translator';

export const Subnav = () => (
  <SubnavContainer>
    <nav>
      <ul>
        <SubnavItems />
      </ul>
    </nav>
  </SubnavContainer>
);

export const SubnavItems: React.FC = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const subItems = useTypedSelector(state => state.header.subnav);
  const reduxCloseHamburger = () => dispatch(closeHamburger());
  const reduxSetProductDuration = duration => dispatch(actionSetProductDuration(duration));

  const onSubnavItemClick = () => {
    reduxCloseHamburger();
    reduxSetProductDuration(12); // reset product duration
  };

  return (
    <>
      {subItems.map(item => {
        if (item.destination) {
          return (
            <StyledLi key={item.name}>
              <NavLink to={`${item.destination}${location.search}`} onClick={onSubnavItemClick}>
                {translate(item.name)}
              </NavLink>
            </StyledLi>
          );
        } else {
          return (
            <StyledLi key={item.name}>
              <a href={item.url} onClick={onSubnavItemClick}>
                {translate(item.name)}
              </a>
            </StyledLi>
          );
        }
      })}
    </>
  );
};

const StyledLi = styled.li`
  font-size: 0.875rem;

  a {
    font-size: 0.875rem;
  }
`;

const SubnavContainer = styled.div`
  position: absolute;
  width: 100%;
  left: 0;
  background: white;
  margin: 0 auto;
  max-width: 1440px;
  box-sizing: border-box;
  text-align: center;
  border-bottom: 1px solid ${({ theme }) => theme.colors.borderGrayPrimary};
  z-index: 2;

  ul {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0 auto;

    li {
      padding: 0;

      a {
        margin: 0 60px 0 0;
      }

      &:last-of-type {
        a {
          margin: 0;
        }
      }
    }
  }
`;
