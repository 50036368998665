import { CarouselControls } from 'components/atoms/CarouselControls';
import { Point } from './components/Point';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactSwipe from 'swipejs/react';

type Props = {
  children: React.ReactNode;
  slideDelay?: number;
  startSlide?: number;
  swipeRunning?: boolean;
};

export const Swipe = ({
  children,
  slideDelay = 4000,
  startSlide = 0,
  swipeRunning = true,
}: Props) => {
  const [currentSlide, setCurrentSlide] = useState<number>(startSlide);
  const swipeRef = useRef<any>();

  useEffect(() => {
    swipeRef.current && swipeRef.current.slide(startSlide, 400);
  }, [startSlide]);

  const changeSlide = useCallback((index: number) => {
    setCurrentSlide(index);
    swipeRef.current && swipeRef.current.slide(index, 400);
  }, []);

  useEffect(() => {
    swipeRef.current && swipeRef.current.setup();
    if (swipeRunning) {
      const timer = setInterval(() => {
        const index = (currentSlide + 1) % React.Children.count(children);
        changeSlide(index);
      }, slideDelay);
      return () => clearInterval(timer);
    }
  }, [changeSlide, children, currentSlide, slideDelay, swipeRunning]);

  return (
    <div className='layout-wrapper hc_swipe-container' style={{ width: '100%' }}>
      <ReactSwipe
        startSlide={currentSlide}
        continuous={false}
        auto={0}
        speed={400}
        draggable={true}
        transitionEnd={changeSlide}
        ref={swipeRef}
      >
        {React.Children.map(children, (child, index) => {
          return (
            <div className='hc_swipe-child' key={`index-${index}`}>
              {child}
            </div>
          );
        })}
      </ReactSwipe>
      <CarouselControls className='carousel-controls tac'>
        {React.Children.map(children, (_, index) => (
          <Point key={index} current={currentSlide} index={index} handleClick={changeSlide} />
        ))}
      </CarouselControls>
    </div>
  );
};
