export const colors = {
  backgroundPrimary: '#ffffff',
  backgroundSecondary: '#f0f4f8',
  backgroundModalOverlay: 'rgba(0,0,0,.6)',
  black: '#171717',
  blackReal: '#000000',
  blueAccessibility: '#9dd1ff',
  borderActive: '#E7372C',
  borderGray: 'rgb(186, 186, 186)',
  borderGrayPrimary: '#d7d7d7',
  brownLight: 'rgb(237, 237, 237)',
  excelGreen: 'rgb(29, 111, 66)',
  excelGreenHover: 'rgba(29, 111, 66, .8)',
  gray: '#858585',
  grayLight: '#b9b9b9 ',
  grayLightAlt: '#929eb0',
  grayReal: '#666',
  green: '#00b956',
  primary: '#E7372C',
  red: '#E7372C',
  redPrimary: '#e7372c',
  link: '#E7372C',
  orange: '#ff6732',
  secondary: '#9ea8b9',
  textHover: '#151d2b',
  textHeading: '#151d2b',
  white: '#ffffff',
  whiteAlt: '#fefefe',

  // border
  borderPrimary: '#dedede',
  borderSecondary: 'rgb(228, 235, 243)',
  borderDark: '#929eb0',
  borderError: '#e62441',
  borderUntouched: 'rgb(228, 235, 243)',
  borderTouched: '#929eb0',
  borderDisabled: '#929eb0',

  // bg
  bgPrimary: '#ffffff',
  bgSecondary: '#f0f4f8',
  bgInput: '#ffffff',
  bgInputUntouched: '#f0f4f8', // rgb(228, 235, 243) #d2ffd2 test
  bgModalOverlay: 'rgba(21, 29, 43, 0.9)', // #151d2b 0.9 opacity
  bgTableHeader: '#f0f4f8',

  // text
  textPrimary: '#171d2a',
  textError: '#E7372C',
  textInfo: '#929eb0',
  textPlaceholder: '#9ea8b9',
  textDisabled: '#929eb0',
};
