import Loadable from 'react-loadable';
import { retryPromise } from 'utils';

/**
 * Lazy loaded routes exports
 */

export const LoadableHome = Loadable({
  loader: () => retryPromise(() => import('components/organisms/home')),
  loading: () => null,
});

export const LoadableInsuranceType = Loadable({
  loader: () =>
    retryPromise(() => import('components/organisms/insurance/components/InsuranceType')),
  loading: () => null,
});

export const LoadableInsuranceCheckout = Loadable({
  loader: () =>
    retryPromise(() => import('components/organisms/insurance/components/InsuranceCheckout')),
  loading: () => null,
});

export const LoadableAgentPage = Loadable({
  loader: () => retryPromise(() => import('components/organisms/agent')),
  loading: () => null,
});

export const LoadableFinal = Loadable({
  loader: () => retryPromise(() => import('components/organisms/insurance/components/Final')),
  loading: () => null,
});

export const LoadableForward = Loadable({
  loader: () => retryPromise(() => import('components/organisms/forward')),
  loading: () => null,
});

export const LoadableGetPolicy = Loadable({
  loader: () => retryPromise(() => import('components/organisms/get-policy')),
  loading: () => null,
});

export const LoadablePageNotFound = Loadable<{ cssClass: string }, any>({
  loader: () => retryPromise(() => import('components/pages/PageNotFound')),
  loading: () => null,
});

export const LoadableProlong = Loadable({
  loader: () => retryPromise(() => import('components/organisms/prolong')),
  loading: () => null,
});

export const LoadableSms = Loadable({
  loader: () => retryPromise(() => import('components/organisms/insurance/components/Sms')),
  loading: () => null,
});

export const LoadableVersion = Loadable({
  loader: () => retryPromise(() => import('components/pages/Version')),
  loading: () => null,
});

export const LoadableZabolelPage = Loadable({
  loader: () => retryPromise(() => import('components/organisms/zabolel')),
  loading: () => null,
});
