import styled from 'styled-components';

interface IBadgeProps {
  bgColor?: string;
}

export const Badge = styled.span<IBadgeProps>`
  background-color: ${({ theme, bgColor }) =>
    bgColor ? theme.colors[bgColor] : theme.colors.green};
  border-radius: 2px;
  color: ${({ theme }) => theme.colors.white};
  display: inline-block;
  font-weight: bold;
  padding: 0 5px;
  text-transform: uppercase;
  position: relative;

  &:after {
    color: ${({ theme, bgColor }) => (bgColor ? theme.colors[bgColor] : theme.colors.green)};
    content: '▼';
    display: inline-block;
    position: absolute;
    right: 4px;
    bottom: -55%;
    transform: rotate(19deg);

    ${props => props.theme.media.phone`
      right: 15px;
    `}
  }
`;
