import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'styled-components/macro';

import {
  LoadableAgentPage,
  LoadableFinal,
  LoadableForward,
  LoadableGetPolicy,
  LoadableInsuranceCheckout,
  LoadableInsuranceType,
  LoadableHome,
  LoadablePageNotFound,
  LoadableProlong,
  LoadableSms,
  LoadableVersion,
  LoadableZabolelPage,
} from './loadable.routes';
import { checkProductExists } from 'components/organisms/insurance/products';
import { themeForward } from 'styles';

export const AppRouter: React.FC = () => (
  <Switch>
    {/* Home page */}
    <Route path='/' exact component={LoadableHome} />
    {/* Products */}
    <Route
      path='/insurance/:type'
      exact
      component={props =>
        checkProductExists(props.match.params.type) ? (
          <LoadableInsuranceType key={props.match.params.type} {...props} />
        ) : (
          <LoadablePageNotFound cssClass='page-header header-prolong cover layout-wrapper' />
        )
      }
    />
    {/* Products checkout */}
    <Route
      path={[
        '/insurance/cards/checkout',
        '/insurance/covid/checkout',
        '/insurance/fairins/checkout',
        '/insurance/family/checkout',
        '/insurance/flat/checkout',
        '/insurance/goods/checkout',
        '/insurance/goods/checkout',
        '/insurance/home/checkout',
        '/insurance/ourkidz/checkout',
      ]}
      exact
      component={LoadableInsuranceCheckout}
    />
    {/* SMS */}
    <Route path='/insurance/:product/sms' exact component={LoadableSms} />
    {/* Prolong */}
    <Route path='/insurance/prolong/form' exact component={LoadableProlong} />
    {/* Final Pages */}
    <Route
      path={['/insurance/:product/final/:status/:orderid', '/insurance/final/:status/:orderid']}
      exact={true}
      component={LoadableFinal}
    />
    {/* App version */}
    <Route path='/version' exact component={LoadableVersion} />
    {/* Agent */}
    <Route path='/agent' exact={true} component={LoadableAgentPage} />
    {/* Get Policy */}
    <Route path='/getpolicy' exact={true} component={LoadableGetPolicy} />
    {/* MegaFon */}
    <Route path='/zabolel' exact={true} component={LoadableZabolelPage} />
    {/* Forward */}
    <Route
      path='/forward'
      render={() => (
        <ThemeProvider theme={themeForward}>
          <LoadableForward />
        </ThemeProvider>
      )}
    />
    {/* Fallback not found page */}
    <Route
      component={props => (
        <LoadablePageNotFound cssClass='page-header header-prolong cover layout-wrapper' />
      )}
    />
  </Switch>
);
