import * as CONSTANTS from 'store/constants/header.constants';

export const closeHamburger = () => {
  return {
    type: CONSTANTS.HEADER_HAMBURGER_CLOSE,
  };
};

export const createActionHamburgerMenu = () => {
  return {
    type: CONSTANTS.HEADER_HAMBURGER_MENU,
  };
};

export const actionSubnavSetItems = items => {
  return {
    type: CONSTANTS.HEADER_SUBNAV_SET_ITEMS,
    items: items,
  };
};

export const actionSubnavSetKeepOpen = keepSubMenuOpen => {
  return {
    type: CONSTANTS.HEADER_SUBNAV_SET_KEEP_OPEN,
    data: { keepSubMenuOpen },
  };
};
