import React, { Fragment } from 'react';
import styled from 'styled-components';
import { Flex, Box } from '@rebass/grid';

import { Tooltip } from 'components/atoms/Tooltip';
import { FnWithArgs } from 'types';
import translate from 'utils/translator';
import { IconRemove } from 'utils/images';

interface IUploadedFilesProps {
  files: { name: string }[];
  dropzoneName: string;
  onRemoveFile: FnWithArgs;
  textColor?: string;
  fontSize?: string;
  removeDisabled?: boolean;
}

export const UploadedFiles: React.SFC<IUploadedFilesProps> = ({
  files,
  onRemoveFile,
  dropzoneName,
  textColor,
  fontSize,
  removeDisabled = false,
}) => {
  if (files && !files.length) return null;

  return (
    <Container
      alignItems='center'
      flexDirection='column'
      data-cy={`uplodaded-files-${dropzoneName}`}
      textColor={textColor}
      fontSize={fontSize}
    >
      {files.map((file, idx) => (
        <Flex justifyContent='space-around' key={idx} width={1}>
          <Filename>&bull; {file.name}</Filename>
          <LinkRemove
            data-tip
            data-for='remove-file-info'
            onClick={() => onRemoveFile(idx)}
            removeDisabled={removeDisabled}
          >
            <Flex alignItems='center' height='100%'>
              <IconRemove />
            </Flex>
          </LinkRemove>
          <Fragment>
            <Tooltip place='right' type='light' id='remove-file-info'>
              <span>{translate('remove_file')}</span>
            </Tooltip>
          </Fragment>
        </Flex>
      ))}
    </Container>
  );
};

const LinkRemove = styled.a<Partial<IUploadedFilesProps>>`
  color: ${({ theme }) => theme.colors.link};
  cursor: ${({ removeDisabled }) => (removeDisabled ? 'not-allowed' : 'pointer')};
`;

const Container = styled(Flex)<Partial<IUploadedFilesProps>>`
  border-radius: ${({ theme }) => theme.radii.box};
  border: 1px solid #f0f4f8;
  box-sizing: border-box;
  font-size: ${({ theme, fontSize }) => (fontSize && theme.fontSizes[fontSize]) || '125%'};
  margin: 10px 0 0;
  padding: 12px;
  width: 100%;
  word-break: break-word;
  color: ${({ theme, textColor }) =>
    (textColor && theme.colors[textColor]) || theme.colors.primary};
`;

const Filename = styled(Box)`
  width: 100%;
`;
