import { rest } from './rest';
import { TAxiosResponse, IAgentReducer } from 'types';
import { promiseSuccess } from './rest.utils';
import { log } from 'utils';
import * as agentMocks from 'rest/mocks/agent';

const MOCKS_ENABLED = false;

export const loginAgent = ({ username, password }) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /cc/login');
    log.debug({ username });
    return promiseSuccess(agentMocks.login, 200);
  }

  return rest.post('/cc/login', {
    userName: username,
    password: password,
  });
};

export const getProductsForAgent = (token, channelId) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /cc/products');
    log.debug({ token, channelId });
    return promiseSuccess(agentMocks.products);
  }

  return rest.get('/cc/products', {
    params: { tcl_id: channelId },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const sendOrderAsAgent = (values, token) =>
  rest.post('/cc/policy', values, { headers: { Authorization: `Bearer ${token}` } });

export const sendSmsAsAgent = (uniSourceId, token, channelId?) =>
  rest.get(`/cc/sendsms/${uniSourceId}`, {
    params: { tcl_id: channelId },
    headers: { Authorization: `Bearer ${token}` },
  });

export const registerPaymentAsAgent = (uniSourceId, token) =>
  rest.get(`/cc/payment/${uniSourceId}`, { headers: { Authorization: `Bearer ${token}` } });

export const generatePdfAgent = (uniSourceId, token) =>
  rest.get(`/cc/policy/pdf/${uniSourceId}`, {
    params: {
      cacheBustTimestamp: Date.now(),
    },
    responseType: 'blob',
    timeout: 0,
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/octet-stream',
    },
  });

export const verifySmsAsAgent = (orderId, token, code) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /cc/verifysms');
    log.debug({ token, orderId, code });
    return promiseSuccess(agentMocks.verifyReportPass);
  }

  return rest.post(
    '/cc/verifysms',
    { orderId, code },
    { headers: { Authorization: `Bearer ${token}` } }
  );
};

export const getOrderStatus = (token, channelId) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /policy/order-status/list');
    log.debug({ token, channelId });
    return promiseSuccess(agentMocks.orderStatus);
  }

  return rest.get(`/policy/order-status/list`, {
    params: { tcl_id: channelId },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getSalesHistoryAgent = (
  token: string,
  channelId: number,
  { pageSize, page, ...filterParams }: any
) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /agent/sales-hist/list');
    log.debug({ token, channelId });
    return promiseSuccess(agentMocks.salesHistory);
  }

  const paramsWithPagination = {
    ...filterParams,
    cacheBustTimestamp: Date.now(),
    page: page + 1 || 1,
    page_size: pageSize || 20,
    timeout: 3 * 60 * 1000, // 3min timeout
  };

  return rest.get(`/agent/sales-hist/list`, {
    params: { tcl_id: channelId, ...paramsWithPagination },
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
};

export const exportSalesHistoryAgent = (token: string, channelId: number, filterParams: object) =>
  rest.get(`/agent/sales-hist/xls`, {
    params: {
      tcl_id: channelId,
      cacheBustTimestamp: Date.now(),
      timeout: 3 * 60 * 1000, // 3min timeout
      ...filterParams,
    },
    responseType: 'blob',
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: 'application/octet-stream',
    },
  });

export const getAuthType = (token, channelId, agentId) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /agent/verify-report-pass');
    log.debug({ token, channelId });
    return promiseSuccess({
      authType: 1,
    });
  }
  return rest.get(`/agent/verify-report-pass`, {
    params: { tcl_id: channelId, id: agentId },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const getInformationDocsAgent = (channelId, token) => {
  if (MOCKS_ENABLED) {
    log.debug('REST: /agent/documents');
    log.debug({ channelId });
    return promiseSuccess(agentMocks.informationDocs);
  }

  return rest.get('/agent/documents', {
    params: { tcl_id: channelId },
    headers: { Authorization: `Bearer ${token}` },
  });
};

export const restCalculatePremium = async (
  tfrId: number,
  maxSumInsured: number,
  token: string
): Promise<TAxiosResponse<IAgentReducer['premiumCalculation']>> => {
  if (MOCKS_ENABLED) {
    log.debug(`REST: /cc/products/variant-calc/{tfrId}/{maxSumInsured}`);
    log.debug({ tfrId, maxSumInsured, token });
    return promiseSuccess(agentMocks.premiumCalculation) as Promise<
      TAxiosResponse<IAgentReducer['premiumCalculation']>
    >;
  }

  return rest.get(`/cc/products/variant-calc/${tfrId}/${maxSumInsured}`, {
    headers: { Authorization: `Bearer ${token}` },
  });
};
