import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components/macro';

import { HeaderContainer } from './components/HeaderContainer';
import { HeaderLogo } from './components/HeaderLogo';
import { NavigationDesktop } from './components/NavigationDesktop';
import { NavigationMobile } from './components/NavigationMobile';
import { SecondaryNav } from './components/SecondaryNav';

export const Header: React.FC = () => {
  const location = useLocation();

  return (
    <HeaderContainer>
      <HeaderHtml>
        <HeaderLogo mr={0} />
        <NavigationDesktop />
        <NavigationMobile />
        <SecondaryNav search={location.search} />
      </HeaderHtml>
    </HeaderContainer>
  );
};

const HeaderHtml = styled.header`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  font-family: 'GolosRegular', sans-serif;
  height: 104px;
  justify-content: space-between;
  margin: 0 auto;
  margin-bottom: 18px;
  padding: 20px 15px;
  position: relative;

  ${({ theme }) => theme.media.minPhone`
    padding: 20px;
  `}

  ${({ theme }) => theme.media.minPhoneHorizontal`
    border-bottom: 1px solid ${({ theme }) => theme.colors.borderPrimary};
    margin-bottom: 40px;
  `}

  ${({ theme }) => theme.media.onlyDesktop`
    max-width: 1200px;
    padding: 20px 0;
  `}
`;
