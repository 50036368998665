import styled from 'styled-components/macro';

export const ContentContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 40px;
  box-sizing: border-box;
`;

export const Section = styled.section`
  position: relative;
  width: 100%;
  padding: 0 0 30px 0;
  box-sizing: border-box;
`;

export const SelectionWrapper = styled.section`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
`;

export const ResponsiveImg = styled.img`
  width: 100%;
  height: auto;
`;

export const HeadingContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  max-width: 1440px;
  height: 368px;

  ${props => props.theme.media.tablet`
    height: 315px;
  `}

  ${props => props.theme.media.phone`
    height: 193px;
  `}
`;

export const Page = styled.main`
  display: block;
  position: relative;

  * {
    box-sizing: border-box;
  }
`;

export const SectionSeparator = styled.div`
  background-color: #e8e8e8;
  display: block;
  height: 1px;
  margin: 20px 0;
  position: relative;
  width: 100%;
`;
