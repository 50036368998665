import { objectValues } from 'utils/object.utils';
import { css } from 'styled-components';

export const sizes = {
  laptop: 1440,
  tablet: 1000,
  phone: 480,
};

// laptop: 1440,
// tabletHorizontal: 1000,
// tablet: 720,
// phone: 375,
// phoneHorizontal: 480,

const onlyIE = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (-ms-high-contrast: none) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyFF = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media screen and (min--moz-device-pixel-ratio: 0) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyEdge = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @supports (-ms-ime-align: auto) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyIeEdgeFF = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media screen and (-ms-high-contrast: none), (min--moz-device-pixel-ratio: 0) {
    ${css(literals, ...interpolations)}
  }

  @supports (-ms-ime-align: auto) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyIpadPro = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (min-width: 1024px) and (max-height: 1366px) and (-webkit-min-device-pixel-ratio: 1.5) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyIE10 = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyIos10AndGreater = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @supports (-webkit-overflow-scrolling: touch) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyIphone5 = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (max-width: 320px) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyPhone = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (max-width: 480px) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyPhoneHorizontal = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (min-width: 480px) and (max-width: 720px) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyTablet = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (min-width: 720px) and (max-width: 1000px) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyTabletHorizontal = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (min-width: 1000px) and (max-width: 1400px) {
    ${css(literals, ...interpolations)}
  }
`;

const maxPhone = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (max-width: 480px) {
    ${css(literals, ...interpolations)}
  }
`;

const minPhone = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (min-width: 480px) {
    ${css(literals, ...interpolations)}
  }
`;

const maxPhoneHorizontal = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (max-width: 720px) {
    ${css(literals, ...interpolations)}
  }
`;

const minPhoneHorizontal = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (min-width: 720px) {
    ${css(literals, ...interpolations)}
  }
`;

const maxTablet = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (max-width: 1000px) {
    ${css(literals, ...interpolations)}
  }
`;

const minTablet = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (min-width: 1000px) {
    ${css(literals, ...interpolations)}
  }
`;

const maxTabletHorizontal = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (max-width: 1400px) {
    ${css(literals, ...interpolations)}
  }
`;

const onlyDesktop = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
  @media only screen and (min-width: 1400px) {
    ${css(literals, ...interpolations)}
  }
`;

const mediaDevices = Object.keys(sizes).reduce((acc, label) => {
  acc[label] = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
    @media only screen and (max-width: ${sizes[label] / 16}em) {
      ${css(literals, ...interpolations)}
    }
  `;

  acc[`${label}IE`] = (literals: TemplateStringsArray, ...interpolations: any[]) => css`
    @media only screen and (max-width: ${sizes[label] / 16}em) and (-ms-high-contrast: none) {
      ${css(literals, ...interpolations)}
    }
  `;

  return acc;
}, {});

export const media = {
  ...mediaDevices,
  onlyEdge,
  onlyFF,
  onlyIE,
  onlyIE10,
  onlyIeEdgeFF,
  onlyIpadPro,
  onlyIphone5,
  onlyIos10AndGreater,
  onlyPhone,
  onlyPhoneHorizontal,
  onlyTablet,
  onlyTabletHorizontal,
  maxPhone,
  minPhone,
  maxPhoneHorizontal,
  minPhoneHorizontal,
  maxTablet,
  minTablet,
  maxTabletHorizontal,
  onlyDesktop,
};

// @rebass/grid breakpoints
// width={[500px, 1000px, 2000px]} => min-widths for 426px, 992px and 1439px breakpoints
// https://github.com/rebassjs/grid#theming
export const breakpoints = objectValues(sizes)
  .map(value => `${value}px`)
  .reverse();
