import moment, { unitOfTime, Moment } from 'moment-timezone';
import { isString } from 'lodash-es';
import { IGenericDate, IMomentDate } from 'types';

export const dateFormat = 'DD.MM.YYYY';
export const dateTimeFormat = 'DD.MM.YYYY HH:mm';
export const dateFullTimeFormat = 'DD.MM.YYYY HH:mm:ss';
export const dateFormatServer = 'YYYY-MM-DD';

export const now = (): IMomentDate => moment();

export const isValidMoment = (value: IGenericDate): boolean =>
  moment.isMoment(value) && value.isValid();

export const isBeforeNow = (value: IGenericDate): boolean =>
  moment.isMoment(value) && value.endOf('day').unix() <= moment().endOf('day').unix();

export const isAfterToday = (value: IGenericDate): boolean => {
  const today = moment();
  return moment.isMoment(value) && today.isAfter(moment(value));
};

export const isAfterMoscowDate = (value: Moment | null, thresholdDate = moment()): boolean => {
  if (!value || value.toString() === 'Invalid date') return false;
  return moment(value.format(dateFormat), dateFormat).isAfter(
    moment(thresholdDate.format(dateFormat), dateFormat)
  );
};

export const isBeforeTarget = (value: IGenericDate, target: IGenericDate | Moment): boolean =>
  moment.isMoment(target) &&
  moment.isMoment(value) &&
  value.endOf('day').unix() <= target.endOf('day').unix();

export const isOutOfDaysRange = (
  value: IGenericDate,
  target: IGenericDate,
  daysRange: number
): boolean | undefined => {
  const val = moment(target, dateFormat);
  const tar = moment(value, dateFormat);
  const diff =
    moment.isMoment(val) && moment.isMoment(tar) && moment(val).diff(moment(tar), 'days');
  return diff ? diff > daysRange : undefined;
};

export const isAfterTarget = (
  value: IGenericDate,
  target: IGenericDate,
  granularity: unitOfTime.StartOf
): boolean => {
  const targetDate = moment(target);
  const checkedDate = moment(value);
  const isMoment = moment.isMoment(value);
  const isAfterTarget = checkedDate.isAfter(targetDate, granularity);
  return isMoment && isAfterTarget;
};

export const is20thCentury = (value: IGenericDate): boolean =>
  moment.isMoment(value) && value.year() >= 1900;

/**
 * Returns true if date (passed thru value param) is older than X years minus 1 day ago
 * @param value - moment.js object
 * @return {boolean}
 */
export const isOlderThanX = (
  value: IGenericDate,
  years: number,
  inputFormat = dateFormat
): boolean => {
  const xYearsAgoAndaDay = moment().subtract(years, 'years').add(1, 'days');
  const date = moment.isMoment(value) ? value : moment(value, inputFormat);
  const isOlder = date.isBefore(xYearsAgoAndaDay);
  return isOlder;
};

export const isBetweenNowAndTarget = (value: IGenericDate, target: IGenericDate): boolean =>
  !isBeforeTarget(value, target) && isBeforeNow(value);

export const isAdult = (value: IGenericDate): boolean => {
  const adultBorn = moment().add(-18, 'year');
  return adultBorn.diff(value, 'minutes') > 0;
};

export const formatStringDate = (
  date: string,
  inputFormat: string = dateFormatServer,
  outputFormat: string = dateFormat
) => {
  if (!isString(date)) return;
  return moment(date, inputFormat).format(outputFormat);
};

export const isServerDateFormat = (date: string): boolean =>
  /^(\d{4})-(\d{2})-(\d{2})$/g.test(date);

export const toMomentDate = (date: string, inputFormat: string = dateFormat) =>
  moment(date, inputFormat);

export const toISO = (date?: Date | string) => {
  if (!date) return '';
  if (typeof date === 'string') return new Date(date).toISOString();
  return new Date(date).toISOString();
};

export const toMoscowDate = (dateUtc: string): moment.Moment | null => {
  if (!dateUtc) return null;
  const moscowTZDate = moment.tz(dateUtc, 'Europe/Moscow');
  return moscowTZDate.utc();
};

export const getTimezoneName = () => moment.tz.guess(true);
