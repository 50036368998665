import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { system } from 'styled-system';

import color, { ColorProps } from '@styled-system/color';
import space, { SpaceProps } from '@styled-system/space';
import layout, { LayoutProps } from '@styled-system/layout';
import typography, { TypographyProps } from '@styled-system/typography';

import { ITextSize, IAccessibilitySettings } from 'types';
import { colors } from 'styles';

interface IOwnProps extends React.HTMLAttributes<HTMLSpanElement> {
  text?: string;
  weight?: string;
  textTransform?: string;
  name?: string;
  color?: string;
  margin?: string;
  size?: ITextSize;
  children?: React.ReactNode;
  theme?: any;
  [x: string]: any;
}

interface IStateDispatchProps {}

interface IStateProps {
  accessibilitySettings: IAccessibilitySettings;
}

export type TTextProps = IOwnProps &
  IStateProps &
  SpaceProps &
  LayoutProps &
  ColorProps &
  TypographyProps;

const StyledText = styled('div')<Partial<TTextProps>>(
  // @ts-ignore
  ({ color, theme }) => ({ color: color ? theme.colors[color] || color : theme.colors.primary }),
  ({ display }) => ({ display: display ? display : 'inline-block' }),
  ({ lineHeight }) => ({ lineHeight: lineHeight ? lineHeight : 'inherit' }),
  ({ margin }) => ({ margin: margin || 'initial' }),
  ({ textAlign }) => ({ textAlign: textAlign || 'left' }),
  ({ textTransform }) => (textTransform ? { textTransform } : null),
  ({ size }) =>
    // prettier-ignore
    size === 'nano' ? { fontSize: '70.3125%' } : null || // 9px
    size === 'micro' ? { fontSize: '85.9375%' } : null || // 11px
    size === 'mini' ? { fontSize: '101.5625%' } : null || // 13px
    size === 'small' ? { fontSize: '109.375%' } : null || // 14px
    size === 'base' ? { fontSize: '125%' } : null || // 16px
    size === 'medium' ? { fontSize: '140.625%' } : null || // 18px
    size === 'big' ? { fontSize: '156.25%' } : null || // 20px
    size === 'extra-big' ? { fontSize: '250%' /*32px*/ } : { fontSize: size ? size : '125%' /*32px*/ },
  ({ weight }) =>
    // prettier-ignore
    weight === 'light' ? { fontWeight: 300 } : null ||
    weight === 'regular' ? { fontWeight: 400 } : null ||
    weight === 'medium' ? { fontWeight: 600 } : null ||
    weight === 'bold' ? { fontWeight: 700 } : null,
  // support for word-break, white-space, text-decoration css prop
  system({
    wordBreak: true,
    textDecoration: true,
    whiteSpace: true,
  }),
  color,
  space,
  layout,
  typography
);

const mapStateToProps = state => ({
  accessibilitySettings: state.accessibilitySettings && state.accessibilitySettings.config,
});

const Text = connect<IStateProps, IStateDispatchProps, IOwnProps>(mapStateToProps)(
  ({
    children,
    text,
    size = 'base',
    weight = 'regular',
    textTransform = 'initial',
    color = colors.textPrimary,
    lineHeight = 1.7,
    ...props
  }: IOwnProps) =>
    text ? (
      <StyledText
        size={size}
        weight={weight}
        textTransform={textTransform}
        color={color}
        lineHeight={lineHeight}
        {...props}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    ) : (
      <StyledText
        size={size}
        weight={weight}
        textTransform={textTransform}
        color={color}
        lineHeight={lineHeight}
        {...props}
      >
        {children}
      </StyledText>
    )
);

export { Text };
