export const submenuAboutUs = [
  {
    name: 'Членство в союзах',
    url: 'https://www.homeins.ru/about/membership/',
  },
  {
    name: 'Раскрытие информации',
    url: 'https://www.homeins.ru/about/normative-document/constituent/',
  },
  {
    name: 'Правила страхования и тарифы',
    url: 'https://www.homeins.ru/about/pravila-strahovaniya/',
  },
  {
    name: 'Виды страхования',
    url: 'https://www.homeins.ru/about/insurance/',
  },
  {
    name: 'Устойчивое развитие',
    url: 'https://www.homeins.ru/about/development/',
  },
  {
    name: 'Контакты',
    url: 'https://www.homeins.ru/about/contacts/',
  },
];

export const submenuCustomerSupport = [
  {
    name: 'Обратная связь',
    url: 'https://www.homeins.ru/customer-support/feedback/form/',
  },
  {
    name: 'Расторгнуть договор',
    url: 'https://www.homeins.ru/customer-support/agreement-termination/',
  },
  {
    name: 'Возврат ошибочных платежей',
    url: 'https://www.homeins.ru/customer-support/return-money/',
  },
];
