import configureStore from 'redux-mock-store';

import products from 'rest/mocks/products.json';
import schema from 'rest/mocks/schema-checkout.json';

const mockStoreFn = configureStore();

export const mockStore = (overrides = {}) =>
  mockStoreFn({
    accessibilitySettings: {
      isOpen: false,
      config: {
        kerning: 'small',
        fontsize: 'small',
        images: 'on',
        color: 'white',
      },
    },
    agent: {
      contentType: 'login',
      activeScreen: 'LOGIN',
      authType: 2,
      agentIdConfirmed: false,
      agentIdInputDisabled: false,
      salesHistory: null,
      salesHistoryIsLoading: false,
      salesHistoryError: null,
      salesHistoryTableState: {
        page: 0,
        pageSize: 20,
        sorted: [],
      },
      orderStatuses: null,
      orderStatusesIsLoading: false,
      orderStatusesError: null,
      informationDocs: null,
      informationDocsIsLoading: false,
      informationDocsError: null,
      salesFilterCountdownStartDate: null,
      premiumCalculation: null,
      pastMonthsAllowed: null,
      minDateThresholdDay: null,
      paymentMethod: 1,
    },
    common: {
      showCookie: false,
      showPreloader: false,
      showPreloaderSmall: false,
      formIsWorking: false,
      insTypeSwipeIsRunning: true,
      formWorkingNames: {},
      flashes: [],
      urlParams: {},
    },
    form: {},
    getPolicy: {
      attemptsCount: 0,
      processSteps: ['getSmsCode'],
      smsCodeError: null,
    },
    header: {
      hamburgerMenuOpen: false,
      subnav: [
        {
          name: 'flat',
          destination: '/insurance/flat',
        },
        {
          name: 'ourkidz',
          destination: '/insurance/ourkidz',
        },
        {
          name: 'family',
          destination: '/insurance/family',
        },
      ],
      keepSubMenuOpen: false,
    },
    insuranceOrder: {
      discount: 0,
      promo_code: null,
      values: {},
      discountAmount: '',
      productDuration: 12,
      product: 'flat',
      tfrId: 10001261,
      strikePremium: '1000',
      strikeSum: '2000',
    },
    insuranceProducts: {
      data: products.products,
    },
    schema: {
      schema: schema,
      serverDate: '2022-04-20T07:06:53.000Z',
    },
    zabolel: {
      createAnnouncement: null,
      createAnnouncementIsLoading: false,
      createAnnouncementError: null,
      verifySmsCode: null,
      verifySmsCodeIsLoading: false,
      verifySmsCodeError: null,
      verifySmsCodeAttempsCount: 0,
      suggestionFullName: null,
      suggestionEmployee: null,
      isPersonalDataLinkClicked: false,
    },
    ...overrides,
  });
