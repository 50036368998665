import styled from 'styled-components';
import { imgProlongBackground, imgProlongBackgroundWebp } from 'utils/images';

interface ITopImageProps {
  src?: string;
  srcWebp?: string;
  height?: string;
}

export const TopImage = styled.div<ITopImageProps>`
  background-image: url(${props => (props.srcWebp ? props.srcWebp : imgProlongBackgroundWebp)});
  background-position-x: center;
  background-position-y: -500px;
  background-repeat: no-repeat;
  background-size: cover;
  height: ${({ height, theme }) => (height ? height : '275px')};
  margin: 0 auto;
  max-width: 100%;
  width: 1200px;
  ${props => props.theme.media.laptop`
     background-position-y: -350px;
  `}
  ${props => props.theme.media.tablet`
     background-position-y: bottom;
  `}
  ${props => props.theme.media.phone`
    background-position-y: bottom;
    height: 153px;
  `}
  html.no-webp & {
    background-image: url(${props => (props.src ? props.src : imgProlongBackground)});
  }
`;
