import { isString, sortBy, maxBy } from 'lodash-es';
import moment, { Moment } from 'moment';

import { searchToData, parseSearch, removeSearchParam } from 'utils/url.utils';
import { getProp, objectToQuery, dateFormat } from 'utils';
import { IProduct, TNullable } from 'types';

export const getDataToSend = (values, tfrId) => {
  const fake = {
    phfiascode: '000000',
    phregion: '0',
    phdistrict: '0',
    phcity: '0',
    phpoint: '0',
    phstreet: '0',
    phentrance: '0',
    phcorpus: '0',
    phpostindex: '000000',
    obfiascode: '000000',
    obregion: '0',
    obdistrict: '0',
    obcity: '0',
    obpoint: '0',
    obstreet: '0',
    obentrance: '0',
    obcorpus: '0',
    obpostindex: '000000',
  };
  const { phdocgivedate, phbirthdate, abirthdate } = values;
  const data = { ...fake, ...values };
  delete data.phrepeatemail;
  data.phdocgivedate = formatRequestDate(phdocgivedate);
  data.phbirthdate = formatRequestDate(phbirthdate);
  data.abirthdate = formatRequestDate(abirthdate);
  data.framecontractnumber = tfrId && tfrId.toString();
  data.phsex = data.phsex === undefined ? undefined : +data.phsex;
  data.asex = data.asex === undefined ? undefined : +data.asex; //Fixed according to email from michal@stankay.net on 26. 3. 2018 11:19 - changed data type - must be true or false
  data.clientisinsured =
    data.clientisinsured === undefined ? true : data.clientisinsured ? true : false;
  const { hcfbagentid } = searchToData();
  if (hcfbagentid) data.hcfbagentid = hcfbagentid;
  if (data.phentrance === null) {
    delete data.phentrance;
  }
  if (data.obentrance === null) {
    delete data.obentrance;
  }
  if (data.petage) data.petage = data.petage.value;
  if (data.pettype) data.pettype = data.pettype.value;
  return data;
};

/**
 * Search for params in URL and if there is a match, add it as key, value pair to values object
 *
 * @param {Object} values
 * @param {Array<string>}params
 * @return {Object}
 */
export const addAdditionalSearchParams = (values, params) => {
  const searchData = searchToData();
  let outputData = values;

  params.forEach(param => {
    outputData = Object.prototype.hasOwnProperty.call(searchData, param)
      ? { ...outputData, [param]: searchData[param] }
      : { ...outputData };
  });

  return outputData;
};

const formatRequestDate = (date: string | Moment) => {
  if (!date) return undefined;
  if (isString(date)) return moment(date, dateFormat).format('YYYY-MM-DD');
  return date.format().substr(0, 10);
};

export const handleBankCardPayment = (response, responseErr, readyToLeave, onError) => {
  if (!response && !responseErr) return;

  // response success
  const respUrl = getProp(response, 'data.url');
  if (response && respUrl) {
    readyToLeave(() => {
      const query = objectToQuery({
        id: response.data.id,
        sector: response.data.sector,
        signature: response.data.signature,
      });
      const url = `${respUrl}?${query}`;
      (window as any).location.href = url;
    });
    return;
  }

  onError?.();
};

export const getProductDurationFromTfrId = (tfrId, products, orderProduct): number | null => {
  if (!products) return null;
  const product = products.find(product => product?.name === orderProduct);
  const productVariant = product?.options?.find(o => o.tfrId === tfrId);
  if (!productVariant) return 1;
  return productVariant?.duration;
};

export const initProductDuration = (
  product,
  products,
  productDuration,
  history,
  actionSetProductDuration
) => {
  if (!productDuration) productDuration = 12;
  const urlDurationParam = parseSearch('duration');
  const selectedProduct = products?.data?.find(item => item && item.name === product);
  const productsOpts = selectedProduct?.options;
  const productDurations = productsOpts?.map(o => o.duration);
  const durationOption = productsOpts?.find(o => o?.duration === Number(urlDurationParam));
  const has12MonthDurationOption = productDurations?.includes(12);
  const defaultDuration = productDuration && has12MonthDurationOption ? productDuration : 1;
  if (urlDurationParam) removeSearchParam('duration', history);
  const duration = durationOption ? durationOption.duration : defaultDuration;
  const optionExists = productDurations.includes(duration);
  if (optionExists) {
    actionSetProductDuration(duration);
    return;
  }

  // set lowest duration found
  if (productDurations.includes(1)) {
    actionSetProductDuration(1);
    return;
  }

  if (productDurations.includes(6)) {
    actionSetProductDuration(6);
    return;
  }

  if (productDurations.includes(12)) {
    actionSetProductDuration(12);
    return;
  }
};

const getOptionsByProduct = (product, products) => {
  const foundProduct = products.find(item => item?.name === product);
  return foundProduct?.options;
};

const getOptionsByTplId = (options, tplId) => {
  if (!options || !tplId) return [];
  const filteredOptions = options.filter(o => o.tplId === tplId);
  return filteredOptions;
};

export const getOptionDurationsByTplId = (selectedProduct, products, tplId) => {
  if (!selectedProduct || !products || !tplId) return [];
  const optionByProduct = getOptionsByProduct(selectedProduct, products);
  const filteredOptions = getOptionsByTplId(optionByProduct, tplId);
  const durations = filteredOptions.map(o => o.duration);
  return sortBy(durations);
};

export const initTfrId = (
  selectedProduct,
  products,
  actionSetProductId,
  actionHidePreloader,
  urlDurationParam
) => {
  // get product options based on selected product
  const productDuration = Number(urlDurationParam) || 12;
  const productData = products.find(product => product?.name === selectedProduct);
  const productOptions = productData?.options;

  // get options without promo options and with default duration 12 (or from URL)
  let filteredOptions = productOptions
    .filter(i => i.duration === productDuration)
    .filter(i => !i.tfrIdOrig);
  if (!filteredOptions.length)
    filteredOptions = productOptions
      .filter(i => i.duration === (productDuration === 12 ? 1 : 12))
      .filter(i => !i.tfrIdOrig);
  if (!filteredOptions.length && productOptions.length === 1) filteredOptions = productOptions;
  // get option with highest price and get tfrId
  const maxPriceOption = maxBy<any>(filteredOptions, 'premium');
  const selectedTfrId = maxPriceOption?.tfrId;
  actionHidePreloader();
  actionSetProductId(selectedTfrId);
  return selectedTfrId;
};

export const getProductVariants = (
  product?: TNullable<IProduct>,
  productDuration?: TNullable<number>,
  tplId?: TNullable<number>,
  tfrId?: TNullable<number>
) => {
  const options = product?.options ? product.options : [];
  const filteredVariants = options.filter(o => o.duration === productDuration && o.tplId === tplId);
  const filteredOriginalVariants = filteredVariants.map(filteredVariant =>
    options.find(o => o.tfrId === filteredVariant.tfrIdOrig)
  );
  const selectedVariant = filteredVariants?.length
    ? options.find(o => o.tfrId === tfrId)
    : undefined;
  const variantOriginal = selectedVariant
    ? options.find(o => o.tfrId === selectedVariant.tfrIdOrig)
    : undefined;
  return {
    options,
    filteredVariants,
    filteredOriginalVariants,
    selectedVariant,
    variantOriginal,
  };
};
