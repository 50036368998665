import React from 'react';

import { Column, IColumnProps } from 'components/atoms/Column';
import { ButtonLink } from 'components/atoms/ButtonLink';
import translate from 'utils/translator';
import { redirectTo } from 'routes';

export const LinkProlong: React.FC<IColumnProps> = columnProps => (
  <Column mr='60px' {...columnProps}>
    <ButtonLink onClick={() => redirectTo('/insurance/prolong/form')}>
      {translate('prolong_link')}
    </ButtonLink>
  </Column>
);
