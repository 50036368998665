export const capitalizeFirstLetter = value =>
  typeof value === 'string' && value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();

export const isEmpty = (value: string | unknown): boolean =>
  value === undefined || value === null || value === '';

export const reverseString = (value: string) => {
  let splitString = value.split('');
  let reverseArray = splitString.reverse();
  const joinArray = reverseArray.join('');
  return joinArray;
};

export const firstUpper = (value: string) =>
  value && typeof value === 'string' && value.charAt(0).toUpperCase() + value.slice(1);

export const trim = (value: string): string =>
  value && typeof value === 'string' ? value.trim() : value;

export const toLowerCase = (value?: string) =>
  value && typeof value === 'string' ? value.toLowerCase() : '';
