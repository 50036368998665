import React from 'react';
import styled from 'styled-components/macro';

import translate from 'utils/translator';
import { iconUser, iconUserHover } from 'utils/images';

import { Row } from 'components/atoms/Row';

export const LinkClientPortal: React.FC = () => (
  <Row alignItems='center'>
    <Link href='/clientportal'>
      <Icon />
      {translate('login')}
    </Link>
  </Row>
);

const Icon = styled.span`
  background: url(${iconUser}) no-repeat 0 0;
  background-size: 100% !important;
  width: 32px;
  height: 32px;
  display: inline-block;
  margin-right: 12px;
`;

const Link = styled.a`
  align-items: center;
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  font-size: 0.875rem;
  text-decoration: none;
  transition: none;

  &:link {
    color: ${({ theme }) => theme.colors.black};
  }

  &:hover {
    color: ${({ theme }) => theme.colors.redPrimary};
    ${Icon} {
      background: url(${iconUserHover}) no-repeat 0 0;
    }
  }
`;
