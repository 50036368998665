export interface IFormConf {
  block2: boolean;
  block3: boolean;
  block4: boolean;
  block5?: boolean;
  block6?: boolean;
  block7?: boolean;
  block8?: boolean;
  block9?: boolean;
}

export interface IRisk {
  name: string;
  sumInsured: string;
}

export interface IRiskCategory {
  category: string;
  items: IRisk[];
}

export enum ECalculationType {
  NORMAL = 1,
  VARIANT_3 = 3,
  VARIANT_4 = 4,
}

export interface IProductOption {
  changeRec: number;
  duration: number;
  formConf?: IFormConf;
  maximumSumInsured: string;
  premium: string;
  recurringPayment: boolean;
  riskCategories?: IRiskCategory[];
  riskSumsInsured: number[];
  tfrId: number;
  tfrIdOrig?: number;
  tplId: number;
  tfrValidDays: number;
  tfrCalcMode: ECalculationType;
}

export interface IProduct {
  id: number;
  name: string;
  risks: string[];
  options: IProductOption[];
  start?: string; // ? used in ourKidz checkout
  formConf?: IFormConf;
}
