import { css } from 'styled-components';
import { colors } from 'styles/colors.styles';

/*
 * Main accessibility config
 */
export const config = {
  fontSize: {
    average: '120%',
    large: '135%',
  },
  letterSpacing: {
    average: '1px',
    large: '2px',
  },
  colorThemes: {
    black: {
      bgColor: colors.blackReal,
      borderColor: colors.white,
      fontColor: colors.white,
    },
    blue: {
      bgColor: colors.blueAccessibility,
      borderColor: colors.textPrimary,
      fontColor: colors.textPrimary,
    },
  },
};

/*
 * accessibility classes:
 * .n3__wcag__fontsize_(small/average/large)
 * .n3__wcag__kerning_(small/average/large)
 * .n3__wcag__images_(on/off)
 * .n3__wcag__color_(white/black/blue)
 */
export const accessibilityStyles = css`
  /*
   * font-size global settings
   */
  .n3__wcag__fontsize_average #root {
    font-size: ${config.fontSize.average} !important;
  }

  .n3__wcag__fontsize_large #root {
    font-size: ${config.fontSize.large} !important;
  }

  /*
   * kerning (letter-spacing) global settings
   */
  .n3__wcag__kerning_average #root {
    letter-spacing: ${config.letterSpacing.average} !important;

    *::placeholder {
      letter-spacing: ${config.letterSpacing.average} !important;
    }
  }

  .n3__wcag__kerning_large #root {
    letter-spacing: 2px !important;

    *::placeholder {
      letter-spacing: 2px !important;
    }
  }

  /*
   * images on/off global settings
   */
  .n3__wcag__images_off #root {
    .hide-bg-img {
      position: relative;
      background: none;
      border: 1px dotted ${colors.gray};
    }
  }

  /*
   * color themes
   */
  .n3__wcag__color_black,
  .n3__wcag__color_blue {
    #root {
      // checkmarks
      form .checkbox-checked input ~ .checkmark {
        background-color: #7ed321 !important;
      }
    }
  }

  // black color theme
  .n3__wcag__color_black {
    #root {
      * {
        background-color: ${config.colorThemes.black.bgColor} !important;
        border-color: ${config.colorThemes.black.borderColor} !important;
        color: ${config.colorThemes.black.fontColor} !important;
      }

      .button-link,
      .btn,
      button,
      input[type='submit'] {
        border: 1px solid ${config.colorThemes.black.borderColor} !important;
        color: ${config.colorThemes.black.fontColor} !important;

        &:hover {
          text-decoration: underline;
        }
      }

      // hero carousel control icons
      .carousel-controls {
        a {
          border: 1px solid ${config.colorThemes.black.borderColor};
          &.active {
            background: ${config.colorThemes.black.bgColor} !important;
          }
        }
      }
    }
  }

  // blue color theme
  .n3__wcag__color_blue {
    #root {
      * {
        background-color: ${config.colorThemes.blue.bgColor} !important;
        border-color: ${config.colorThemes.blue.borderColor} !important;
        color: ${config.colorThemes.blue.fontColor} !important;
      }

      .button-link,
      .btn,
      button,
      input[type='submit'] {
        border: 1px solid ${config.colorThemes.blue.borderColor} !important;
        color: ${config.colorThemes.blue.fontColor} !important;

        &:hover {
          text-decoration: underline;
        }
      }

      // hero carousel control icons
      .carousel-controls {
        a {
          border: 1px solid ${config.colorThemes.blue.borderColor};
          &.active {
            background: ${config.colorThemes.blue.bgColor} !important;
          }
        }
      }
    }
  }
`;
