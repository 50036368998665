import styled from 'styled-components';

export const TableRowItem = styled.div`
  width: 180px;

  @media screen and (min-width: 800px) {
    width: 225px;
  }

  @media screen and (min-width: 1400px) {
    width: 250px;
  }
`;
