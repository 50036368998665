import React from 'react';
import styled from 'styled-components';

import { FONTS_FALLBACK_FONT_FAMILY } from 'styles/fonts.styles';

/**
 * Cross-browser solution for ₽ currency symbol
 */
export const RubleSymbol: React.FC = props => <CurrencySymbol {...props}>&#8381;</CurrencySymbol>;

const CurrencySymbol = styled.span`
  font-family: ${FONTS_FALLBACK_FONT_FAMILY};
`;
