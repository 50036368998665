import styled from 'styled-components';

export const CookieWrap = styled.div`
  &.cookieWrapHide {
    display: none;
  }

  &.cookieWrap {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 100;
    background-color: rgb(242, 243, 245);
    padding: 12px 0;
  }

  & .cookieWrapContent {
    margin: 0 auto;
    width: 1350px;
    max-width: 80%;
    display: flex;
    flex-flow: row;
    align-items: center;
    padding: 0 5%;
  }

  & .cookieButton {
    display: inline-block;
    box-sizing: border-box;
    text-transform: uppercase;
    cursor: pointer;
    color: rgb(255, 255, 255);
    font-size: 93.75%; // 12px
    line-height: 12px;
    height: 32px;
    transition: all 0.5s;
    background: rgb(230, 28, 65);
    border-radius: 32px;
    padding: 10px 20px;
    outline: none;
    border-width: 0;
    border-style: initial;
    border-color: transparent;
    border-image: initial;
  }

  & .cookieWrapButton {
    padding: 0 12px;
  }
`;
