import React from 'react';
import styled, { css } from 'styled-components';
import { StandardLonghandProperties } from 'csstype';
import typography, { TypographyProps } from '@styled-system/typography';
import color, { ColorProps } from '@styled-system/color';
import space, { SpaceProps } from '@styled-system/space';
import layout, { LayoutProps } from '@styled-system/layout';

interface IOwnProps {
  level?: number;
  centered?: boolean;
  padding?: string | 0;
  color?: string;
  margin?: string | 0;
  fontWeight?: number | string;
  textAlign?: StandardLonghandProperties['textAlign'];
}

export type THeadingProps = IOwnProps &
  SpaceProps &
  TypographyProps &
  ColorProps &
  LayoutProps &
  React.HTMLAttributes<HTMLDivElement>;

export const Heading: React.FC<THeadingProps> = ({
  centered,
  level = 1,
  padding,
  margin,
  fontWeight,
  textAlign,
  ...props
}) => {
  let Output;
  switch (level) {
    case 1:
      Output = (
        <H1
          centered={centered}
          padding={padding}
          margin={margin}
          fontWeight={fontWeight}
          textAlign={textAlign}
          {...props}
        />
      );
      break;
    case 2:
      Output = (
        <H2
          centered={centered}
          padding={padding}
          margin={margin}
          fontWeight={fontWeight}
          textAlign={textAlign}
          {...props}
        />
      );
      break;
    case 3:
      Output = (
        <H3
          centered={centered}
          padding={padding}
          margin={margin}
          fontWeight={fontWeight}
          textAlign={textAlign}
          {...props}
        />
      );
      break;
    case 4:
      Output = (
        <H4
          centered={centered}
          padding={padding}
          margin={margin}
          fontWeight={fontWeight}
          textAlign={textAlign}
          {...props}
        />
      );
      break;
    case 5:
      Output = (
        <H5
          centered={centered}
          padding={padding}
          margin={margin}
          fontWeight={fontWeight}
          textAlign={textAlign}
          {...props}
        />
      );
      break;
    default:
      Output = (
        <H1
          centered={centered}
          padding={padding}
          margin={margin}
          fontWeight={fontWeight}
          textAlign={textAlign}
          {...props}
        />
      );
      break;
  }

  return Output;
};

Heading.defaultProps = {
  centered: false,
};

const commonStyles = css<Partial<THeadingProps>>`
  text-align: ${({ centered, textAlign }) => (centered ? 'center' : textAlign || 'left')};
  color: ${({ color, theme }) => (color ? theme.colors[color] : theme.colors.textHeading)};
  line-height: normal;
  letter-spacing: normal;
  padding: ${({ padding }) => (padding ? padding : 0)};
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 300)};
  word-break: break-word;
  ${typography};
  ${layout};
  ${color};
  ${space};
`;

const H1 = styled.h1<Partial<THeadingProps>>`
  font-size: 250%;
  margin: ${({ margin }) => (margin ? margin : 0)};
  ${commonStyles};
  line-height: 1.44;

  @media only screen and (max-width: 768px) {
    font-size: 210%;
  }

  @media only screen and (max-width: 414px) {
    font-size: 145%;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  }
`;

const H2 = styled.h2<Partial<THeadingProps>>`
  font-size: 250%;
  margin: ${({ margin }) => (margin ? margin : '0 0 1em')};
  ${commonStyles};

  @media only screen and (max-width: 1024px) {
    font-size: 210%;
  }

  @media only screen and (max-width: 414px) {
    font-size: 145%;
    font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  }
`;

const H3 = styled.h3<Partial<THeadingProps>>`
  font-size: 220%;
  margin: ${({ margin }) => (margin ? margin : '0 0 1em')};
  ${commonStyles};

  @media only screen and (max-width: 768px) {
    font-size: 180%;
  }
`;

const H4 = styled.h4<Partial<THeadingProps>>`
  ${commonStyles};
  font-size: 150%;
  font-weight: ${({ fontWeight }) => (fontWeight ? fontWeight : 400)};
  margin: ${({ margin }) => (margin ? margin : '2em 0')};

  @media only screen and (max-width: 768px) {
    font-size: 130%;
  }
`;

const H5 = styled.h5<Partial<THeadingProps>>`
  font-size: 130%;
  font-weight: 600;
  margin: ${({ margin }) => (margin ? margin : '0 0 1em')};
  ${commonStyles}
`;
