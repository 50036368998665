import { css, createGlobalStyle } from 'styled-components';

import { forwardPlaceholderStyles } from './forward.placeholder.styles';
import { fontsStylesProximaNova } from 'styles/fonts.styles';

export const FORWARD_FONTS_FONT_FAMILY = `'Proxima Nova Rg', Arial, '-apple-system', 'Helvetica Neue', Helvetica, sans-serif`;

export const forwardStyles = css<any>`
  html {
    height: 100%;
    overflow: auto;

    body {
      font: 100%/1.7 ${FORWARD_FONTS_FONT_FAMILY};
      font-weight: 400;
      background: rgb(24 9 82);
      height: 100%;
    }

    form input:not([type='submit']):not([id*='react-select']),
    form textarea,
    form select {
      font-size: 100%;
      border-radius: 0;
    }

    form .checkbox {
      margin-top: 0;
    }

    a {
      color: ${({ theme }) => theme.colors.orange};
      &:hover {
        color: ${({ theme }) => theme.colors.orange};
        text-decoration: underline;
      }
    }

    .react-autosuggest__suggestions-container {
      background: white;
    }

    .react-autosuggest__suggestions-container--open {
      border: none !important;
      overflow: hidden !important;
    }

    .react-datepicker {
      font-family: 'Proxima Nova Rg', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }

    .react-datepicker__day--selected,
    .react-datepicker__day--keyboard-selected,
    .react-datepicker__day--in-selecting-range,
    .react-datepicker__day--in-range {
      background: ${({ theme }) => theme.colors.orange};

      &:hover {
        background: rgb(24 9 82);
      }
    }

    input,
    select,
    textarea {
      font-family: 'Proxima Nova Rg', 'Helvetica Neue', Helvetica, Arial, sans-serif;
    }
  }
`;

export const ForwardStyles = createGlobalStyle`
  ${forwardPlaceholderStyles};
  ${fontsStylesProximaNova};
  ${forwardStyles};
`;
