import React from 'react';
import styled from 'styled-components/macro';

import { Row, TRowProps } from 'components/atoms/Row';

export interface IColumnProps extends TRowProps {}

export const Column: React.FC<IColumnProps> = props => (
  <StyledRow minWidth='initial' flexDirection='column' {...props}>
    {props.children}
  </StyledRow>
);

const StyledRow = styled(Row)``;
