import React from 'react';
import styled from 'styled-components';
import translate from 'utils/translator';
import { Flex, Box } from '@rebass/grid';

interface IErrorMessageProps {
  children?: React.ReactNode;
  message?: string;
  [x: string]: any;
}

export const ErrorMessage: React.SFC<IErrorMessageProps> = ({ children, ...props }) => {
  if (Array.isArray(children)) {
    return (
      <Flex flexDirection='row' mx='10px'>
        {children.map(
          (err, idx) =>
            !!err && (
              <Box key={idx} mr='5px'>
                {renderMessage(err, props)}
              </Box>
            )
        )}
      </Flex>
    );
  }
  return <Flex mx='10px'>{renderMessage(children, props)}</Flex>;
};

function renderMessage(error, props) {
  if (typeof error === 'object' && error.message)
    return (
      <StyledErrorMessage width={1} {...props}>
        {translate(error.message)}
      </StyledErrorMessage>
    );

  return (
    <StyledErrorMessage width={1} {...props}>
      {translate(error)}
    </StyledErrorMessage>
  );
}

export const StyledErrorMessage = styled(Flex)`
  background-color: transparent;
  color: ${({ theme }) => theme.colors.red};
  font-family: ${({ theme }) => theme.FONTS_FONT_FAMILY};
  font-size: ${({ theme }) => theme.fontSizes.error};
  line-height: 1.2;
  text-align: left;
  margin-top: 3px;
`;
