import { colors } from 'styles/colors.styles';

export const forwardColors = {
  ...colors,
  backgroundPrimary: 'rgb(24, 9, 82)',
  blackReal: '#000000',
  textHover: '#151d2b',
  textHeading: '#ffffff',
  textPrimary: '#000000',
  primary: 'rgb(24, 9, 82)',
  red: '#E7372C',
  orange: 'rgb(255, 103, 50)',
  link: 'rgb(255, 103, 50)',
  borderActive: 'rgb(24, 9, 82)',
};
