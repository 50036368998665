import { css } from 'styled-components';

// const PUBLIC_URL = process.env.PUBLIC_URL;

export const responsiveLegacyStyles = css`
  @media only screen and (max-width: 1024px) {
    html {
      -webkit-text-size-adjust: none;
    }

    img {
      max-width: 100%;
      height: auto;
    }

    picture source {
      max-width: 100%;
      height: auto;
    }

    input,
    textarea,
    select {
      box-sizing: border-box;
    }

    input[type='submit'] {
      width: auto;
      -webkit-appearance: none;
    }

    h2 {
      font-size: 210%;
    }

    .header-home {
      background-position: calc(100% + 360px) 0 !important;
    }

    .page-header {
      position: relative;
    }

    .page-header * {
      z-index: 1;
      color: #ffffff !important;
    }

    .page-header:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.55);
    }
  }

  @media only screen and (max-width: 768px) {
    h1 {
      font-size: 210%;
    }

    h3 {
      font-size: 180%;
    }

    h4 {
      font-size: 130%;
    }

    .page-header {
      min-height: 250px;
      padding: 45px 30px 110px;
    }

    .page-header h4 {
      font-size: 100%;
    }

    .page-header-section-wrapper {
      margin: 0 10px;
      padding: 25px 0;
    }

    .page-checkout .page-header-section-wrapper,
    .page-sms .page-header-section-wrapper,
    .page-checking .page-header-section-wrapper,
    .page-finalhome .page-header-section-wrapper {
      margin: 0 15px;
      padding: 25px 15px;
    }

    .page-header-section td {
      padding: 20px;
    }

    .header-home {
      background-position: calc(100% + 260px) 0 !important;
    }

    .page-header-section .td-big {
      font-size: 100%;
    }

    .page-header-section td .more:after {
      padding: 0 0 0 10px;
      position: relative;
      top: -1px;
    }

    .page-header-section td .more.active:after {
      margin: 0 0 0 5px;
    }

    .page-header-section .price {
      font-size: 150%;
    }

    .page-checkout .radio span {
      font-size: 100%;
    }

    .page-checkout .row {
      margin: 0 0 30px -15px;
    }

    .page-checkout .row > div {
      flex-basis: calc(100% * (1 / 3) - 15px);
      margin: 0 0 10px 15px;
    }

    .page-checkout .row > .col-50 {
      flex-basis: calc(100% * (2 / 3) - 15px);
    }

    .page-checkout .row > .col-75 {
      flex-basis: calc(100% - 15px);
    }

    .page-checkout .row > .col-8 {
      flex-basis: calc(100% * (1 / 6) - 15px);
    }

    form textarea,
    form select {
      font-size: 100%;
    }

    form .radiobutton {
      font-size: 100%;
    }

    form .radiobutton .checkmark {
      top: 1px;
    }
  }

  @media only screen and (min-width: 415px) {
    .hc_react-swipe-small {
      display: none;
    }

    .hc_no-react-swipe-big {
      display: none;
    }

    .hc_react-swipe-big {
      overflow: hidden;
      border-radius: 10px;
    }

    .hc_react-swipe-big div {
      float: left;
      position: relative;
    }

    .header-ourkidz:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(0, 0, 0, 0.25);
    }

    .n3__wcag__color_black .header-ourkidz:before,
    .n3__wcag__color_blue .header-ourkidz:before,
    .n3__wcag__images_off .header-ourkidz:before {
      content: none;
    }

    .header-ourkidz h1,
    .header-ourkidz h4 {
      color: white;
      position: relative;
    }

    .n3__wcag__images_off .header-ourkidz h1,
    .n3__wcag__images_off .header-ourkidz h4 {
      color: #151d2b;
    }

    .n3__wcag__images_off .header-home h1,
    .n3__wcag__images_off .header-home h4 {
      color: #151d2b;
    }
  }

  @media only screen and (max-width: 414px) {
    .big {
      font-size: 150%;
    }

    h1,
    h2 {
      font-size: 145%;
      font-weight: 400;
    }

    .hc_react-swipe-small div {
      float: left;
      position: relative;
    }

    .hc_no-react-swipe-small {
      display: none;
    }

    .hc_react-swipe-big {
      display: none;
    }

    .btn,
    .btn:link,
    input[type='submit']:not([name='checkout-submit']) {
      font-size: 80%;
      padding: 0.8em 1.5em 0.85em;
    }

    .page-header {
      min-height: 0;
      padding: 20px 20px 110px;
    }

    .page-header-section-wrapper {
      overflow: auto;
    }

    .page-checkout .page-header-section-wrapper {
      margin: 0 20px;
      padding: 25px 20px;
    }

    .page-header-section td,
    .page-header-section .active td {
      padding: 10px 15px;
    }

    .hc_swipe-child .page-header-section-wrapper td {
      padding: 10px;
    }

    .page-header-section .price {
      font-size: 130%;
    }

    .page-checkout .row {
      margin: 0 0 30px -20px;
    }

    .page-checkout .row > div {
      flex-basis: calc(100% - 20px);
      margin: 0 0 10px 20px;
    }

    .page-checkout .row > .col-50 {
      flex-basis: calc(100% - 20px);
    }

    .page-checkout .row > .col-75 {
      flex-basis: calc(100% - 20px);
    }

    .page-checkout .row > .col-8 {
      flex-basis: calc(100% * (1 / 3) - 20px);
    }

    .page-checkout .row textarea {
      height: 100px;
      max-height: none;
      padding: 8px 12px;
      line-height: normal;
    }

    .page-checking .buttons {
      text-align: center;
    }

    .page-checking .buttons * {
      width: 100%;
    }

    .page-checking button.btn {
      margin: 0.5em auto 0;
      display: block;
    }

    .page-sms form {
      margin-top: 1em;
    }

    .page-sms .buttons {
      text-align: center;
    }

    .page-sms .buttons * {
      width: 100%;
    }

    .page-sms button.btn {
      margin: 0.5em auto 0;
      display: block;
    }

    form textarea,
    form select {
      font-size: 90%;
      height: 45px;
    }

    form input:not([type='submit']) {
      height: 45px;
    }
  }

  @media only screen and (max-width: 767px) {
    .hc_hide-on-mobile {
      display: none;
    }
  }

  @media only screen and (max-width: 340px) {
    .hc_swipe-child .page-header-section-wrapper td {
      padding: 10px 6px;
    }
  }

  @media only screen and (min-width: 768px) {
    #prolong_link,
    .hc_hide-tablet-desktop {
      display: none;
    }
  }

  @media (min-width: 600px) {
    .tab-tooltip-home {
      max-width: 400px;
    }

    .tab-tooltip-flat {
      max-width: 400px;
    }
  }
`;
