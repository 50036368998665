import React from 'react';
import styled from 'styled-components';
import { IconMenu, IconMenuActive } from 'utils/images';

interface IHamburgerMenuButtonProps {
  onClick: () => void;
  isOpen: boolean;
}

export const HamburgerMenuButton = React.forwardRef(
  ({ onClick, isOpen }: IHamburgerMenuButtonProps, ref) => {
    return (
      <Button onClick={onClick} isOpen={isOpen} ref={ref as any}>
        {!isOpen && <IconMenu />}
        {isOpen && <IconMenuActive />}
      </Button>
    );
  }
);

/**
 * Styled components
 */

const Button = styled.button<Partial<IHamburgerMenuButtonProps>>`
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  border: none;
  cursor: pointer;
  background: none;
  overflow: hidden;
  width: 42px;
  height: 32px;
`;
