import { Flex, FlexProps } from '@rebass/grid';
import React from 'react';
import styled from 'styled-components';

interface IContainerProps {
  children: React.ReactNode;
  border?: boolean;
  bgColor?: string;
}

export const Container: React.SFC<IContainerProps & FlexProps> = ({
  children,
  border,
  bgColor,
  ...props
}) => (
  <StyledFlex width={1} border={border} bgColor={bgColor} {...props}>
    {children}
  </StyledFlex>
);

Container.defaultProps = {
  border: false,
};

const StyledFlex = styled(Flex)<Partial<IContainerProps>>`
  border-bottom: ${({ border }) => (border ? '1px solid #ddd' : 'none')};
  background-color: ${({ bgColor }) => (bgColor ? bgColor : 'transparent')};
  margin: 0 auto;
  max-width: 1280px;
  /* padding: 0 40px; */
  position: relative;
`;
