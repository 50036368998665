import React from 'react';
import { Dispatch } from 'redux';
import styled from 'styled-components';
import { Flex, FlexProps } from '@rebass/grid';

import { Column, IColumnProps } from 'components/atoms/Column';
import { useTypedSelector } from 'store/utils';

import { IAccessibilitySettings } from 'types';

interface IOwnProps extends IColumnProps {
  alt?: string;
  allowAccessibilityOverlay?: boolean;
  center?: boolean;
  accessibilitySettings?: any;
  src?: string;
  srcSet?: string;
  className?: string;
  dispatch?: Dispatch<any>;
  containerProps?: IColumnProps;
  sourceMedia?: string;
  [x: string]: any;
}

interface IStateProps {
  accessibilitySettings?: IAccessibilitySettings;
}

type TImageProps = IOwnProps & IStateProps;

export const Image: React.FC<TImageProps> = ({
  alt,
  allowAccessibilityOverlay,
  center,
  src,
  srcSet,
  sourceMedia = '',
  className,
  dispatch,
  containerProps,
  children,
  ...imageProps
}) => {
  const accessibilitySettings = useTypedSelector(store => store.accessibilitySettings);
  const {
    config: { images },
  } = accessibilitySettings;
  return (
    <ImgContainer position='relative' center={center} {...containerProps}>
      {allowAccessibilityOverlay && images && images === 'off' && (
        <HiddenImageOverlay alignItems='center' justifyContent='center' />
      )}
      <picture>
        {children}
        {srcSet && <source srcSet={srcSet} className={className} media={sourceMedia} />}
        <img src={src} alt={alt} className={className} {...imageProps} />
      </picture>
    </ImgContainer>
  );
};

Image.defaultProps = {
  alt: 'image',
  allowAccessibilityOverlay: true,
  center: false,
};

const HiddenImageOverlay = styled(Flex)<FlexProps>`
  width: 100%;
  height: 100%;
  position: absolute !important;
  float: initial !important;
  background: white;
  border: 1px dotted ${({ theme }) => theme.colors.gray};
`;

const ImgContainer = styled(Column)<Partial<IOwnProps> & FlexProps>`
  overflow: hidden;

  img {
    vertical-align: middle;
  }

  ${({ center }) => center && 'text-align: center'};
`;
