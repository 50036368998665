import preval from 'preval.macro';

export const buildInfo = preval`
  const GitRevisionPlugin = require('git-revision-webpack-plugin')
  const gitRevisionPlugin = new GitRevisionPlugin();

  const info = {
    BRANCH: JSON.stringify(gitRevisionPlugin.branch()),
    BUILD_TIME: new Date().toISOString(),
    COMMITHASH: JSON.stringify(gitRevisionPlugin.commithash()),
    ENV: process.env.NODE_ENV,
    GIT_PATH_BUILDER: process.env.GIT_PATH_BUILDER,
    PUBLIC_URL: process.env.PUBLIC_URL,
    SERVER: process.env.SERVER,
    TARGET_SERVER: process.env.TARGET_SERVER,
    VERSION: JSON.stringify(gitRevisionPlugin.version()),
  };

  module.exports = info;
`;
