import React from 'react';
import styled from 'styled-components';
import { useTypedSelector } from 'store';

import { Spinner } from 'components/atoms/Spinner';

export const PreloaderSmall: React.FC = () => {
  const show = useTypedSelector(({ common }) => common.showPreloaderSmall || common.formIsWorking);
  if (!show) return null;

  return (
    <Container>
      <Spinner id='spinner-small' />
    </Container>
  );
};

const Container = styled.div`
  & #spinner-small {
    position: fixed;
    z-index: 1000;
    left: calc(100% - 50px);
    top: calc(100% - 50px) !important;
    display: block;
  }
`;
