import { getProp } from './object.utils';

export const ENV = {
  prod: 'prod',
  preProd: 'preProd',
  test: 'test',
  dev: 'dev',
  local: 'local',
};

export const BASE_URL = {
  prod: 'https://www.homeins.ru',
  preProd: 'https://os-3454.homecredit.ru',
  test: 'https://os-3455.homecredit.ru',
  dev: 'http://10.20.1.140',
  local: 'http://localhost:3000',
};

export const getEnv = (): string => {
  if (!window.location) return '';

  const location = getProp(window.location, 'host', '');
  const isProd =
    location === 'homeins.ru' || location === 'www.homeins.ru' || location.indexOf('os-3453') > -1;
  const isPreProd = location.indexOf('os-3454') > -1;
  const isTest = location.indexOf('os-3455') > -1;
  const isDev = location.indexOf('10.20.1.140') > -1;
  const isLocal = location.indexOf('localhost') > -1 || location.indexOf('127.0.0.1') > -1;

  if (isProd) return ENV.prod;
  if (isPreProd) return ENV.preProd;
  if (isTest) return ENV.test;
  if (isDev) return ENV.dev;
  if (isLocal) return ENV.local;
  return '';
};

export const getBaseUrl = () => {
  const env = getEnv();
  const baseUrl = BASE_URL[env] || window.location.origin;
  return baseUrl;
};

export const isLocalEnv = getEnv() === ENV.local;
export const isLocalOrDevEnv = getEnv() === ENV.local || getEnv() === ENV.dev;
export const isProdEnv = getEnv() === ENV.prod;
