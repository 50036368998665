import React from 'react';
import { useDispatch } from 'react-redux';
import { useMount } from 'react-use';

import { CookieWrap } from './components/CookieWrap';
import { Button } from 'components/atoms/Button';
import { toggleCookiePolicyAction } from 'store/actions/common.actions';
import { useTypedSelector } from 'store';
import { isStorageAvailable, saveToStorage, getFromStorage } from 'utils/storage';

export const CookiePolicy: React.FC = () => {
  const isVisible = useTypedSelector(state => state.common.showCookie);
  const dispatch = useDispatch();
  const toggleCookie = (isVisible: boolean) => dispatch(toggleCookiePolicyAction(isVisible));

  useMount(() => {
    // if hide in 15 seconds is needed check commit: a78a19ac91
    initFromStorage();
  });

  const initFromStorage = () => {
    if (!isStorageAvailable()) return;
    const cookieIsVisible = getFromStorage('cookieIsVisible');
    if (cookieIsVisible === 'false') toggle(false);
    else toggle(true);
  };

  const toggle = (visible: boolean) => {
    saveToStorage('cookieIsVisible', visible);
    toggleCookie(visible);
  };

  return (
    <CookieWrap className={['cookieWrap', !isVisible ? 'cookieWrapHide' : ''].join(' ')}>
      <div className='cookieWrapContent'>
        <div className='cookieWrapText'>
          <div className='cookieText'>
            ООО «Хоум Кредит Страхование» с целью персонализации сервисов и повышения удобства
            пользования сайтом использует файлы «cookie». Продолжая просматривать сайт, вы
            соглашаетесь с данными условиями. В случае несогласия, вы можете отключить использование
            «cookie» в настройках браузера.&nbsp;
            <a className='cookieLink' target='_blank' href='/files/PERS_DATA_USERS.pdf'>
              Подробнее
            </a>
          </div>
        </div>
        <div className='cookieWrapButton'>
          <Button className='cookieButton' onClick={() => toggle(false)}>
            OK
          </Button>
        </div>
      </div>
    </CookieWrap>
  );
};
