import * as CONSTANTS from 'store/constants/header.constants';
import items from 'components/organisms/insurance/submenu';

const initialState = {
  hamburgerMenuOpen: false,
  subnav: items,
  keepSubMenuOpen: false,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case CONSTANTS.HEADER_HAMBURGER_CLOSE:
      return {
        ...state,
        hamburgerMenuOpen: false,
      };
    case CONSTANTS.HEADER_HAMBURGER_MENU:
      return {
        ...state,
        hamburgerMenuOpen: !state.hamburgerMenuOpen,
      };
    case CONSTANTS.HEADER_SUBNAV_SET_ITEMS:
      return {
        ...state,
        subnav: action.items,
      };
    case CONSTANTS.HEADER_SUBNAV_SET_KEEP_OPEN:
      return {
        ...state,
        ...action.data,
      };
    default:
      return state;
  }
};
